import {
    GET_AUTO_COLLEGES_SUCCESS,
    GET_HOME_SUCCESS,
    DISPLAY_MESSAGE,
    DISPLAY_ERROR,
    HIDE_MESSAGE,
    SET_AUTH_USER,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    SUB_LOAD_TRUE,
    SUB_LOAD_FALSE,
    MAIN_LOAD_TRUE,
    MAIN_LOAD_FALSE,
    CHECKOUT_SUCCESS,
    SUCCESS_STATUS,
    GET_CART_SUCCESS,
    UPDATE_CART_COUNT,
    GET_RESULT_SUCCESS
} from "../actions/Home";

const initialState = {
    main_loader: false,
    sub_loader: false,
    is_auth: false,
    is_login: false,
    auth_user: {},
    autocomplete_colleges: [],
    popular_colleges: [],
    favourite_colleges: [],
    cart: [],
    cart_counter: 0,
    message: "",
    error: "",
    status: "",
    order_id: "",
    result: [],
    img_url: "https://api.prod.goocampus.in/"
};

const Home_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_AUTO_COLLEGES_SUCCESS:
            return Object.assign({}, state, {
                autocomplete_colleges: payload.autocomplete_colleges
            });

        case GET_HOME_SUCCESS:
            return Object.assign({}, state, {
                popular_colleges: payload.popular_colleges,
                favourite_colleges: payload.favourite_colleges
            });

        case MAIN_LOAD_TRUE:
            return Object.assign({}, state, { main_loader: true });

        case MAIN_LOAD_FALSE:
            return Object.assign({}, state, { main_loader: false });

        case SUB_LOAD_TRUE:
            return Object.assign({}, state, { sub_loader: true });

        case SUB_LOAD_FALSE:
            return Object.assign({}, state, { sub_loader: false });

        case DISPLAY_MESSAGE:
            return Object.assign({}, state, { message: payload.message });

        case DISPLAY_ERROR:
            return Object.assign({}, state, { error: payload.error });

        case HIDE_MESSAGE:
            return Object.assign({}, state, { error: "", message: "" });

        case SET_AUTH_USER:
            return Object.assign({}, state, {
                auth_user: payload.auth_user,
                is_auth: true
            });

        case LOGIN_SUCCESS:
            return Object.assign({}, state, { is_login: true });

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                auth_user: {},
                is_auth: false,
                is_login: false
            });

        case CHECKOUT_SUCCESS:
            return Object.assign({}, state, { order_id: payload.order_id });

        case SUCCESS_STATUS:
            return Object.assign({}, state, { status: payload.status });

        case GET_CART_SUCCESS:
            return Object.assign({}, state, { cart: payload.cart });

        case UPDATE_CART_COUNT:
            return Object.assign({}, state, { cart_counter: payload.count });

        case GET_RESULT_SUCCESS:
            return Object.assign({}, state, { result: payload.result });

        default:
            return state;
    }
};

export default Home_reducer;
