import React from 'react';
import {Link} from 'react-router-dom';

const Predictor =()=>
{
    return(
    <div className='container p-0 text-center' style={{marginTop:"10%",marginBottom :"5%"}}>
        <div class="row">
            <div className='col-md-6 text-center text-light'  >
               <Link to="/rank_predictor"> <h2 className='text-uppercase bg-blue text-white border-radius' style={{paddingTop:"15%",paddingBottom:"15%"}} >Rank Predictor</h2></Link>
            </div>
            <div className='col-md-6 text-center text-light'  >

            <h2 className='text-uppercase bg-blue  border-radius' style={{paddingTop:"15%",paddingBottom:"15%"}} >College Predictor</h2>
            </div>
        </div>
    </div>
    );
}

export default Predictor;