import React from "react";
import "./CollegeDetails.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import ImageGallery from "react-image-gallery";
import { connect } from "react-redux";
import CourseIcon from "../../assets/course_icon.png";
import { getCollegeDetails } from "../../actions/Colleges";
import { addRemoveFavourite } from "../../actions/Home";
import Callback from "../Callback";
import { setCallbackValues } from "../../actions/Callbacks";
import CurrencyFormat from "../../utils/CurrencyFormat";

class CollegeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            college: this.props.match.params.college,
            maxYears: [1, 2, 3, 4, 5]
        };
        this.handlePageScroll = this.handlePageScroll.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    componentWillMount() {
        this.props.onGetCollegeDetails(this.state.college);
    }

    handlePageScroll(desti) {
        $("#college_details_navbar .nav-link").removeClass("active");
        $("." + desti + "_link").addClass("active");

        $("html,body").animate(
            {
                scrollTop: $("#" + desti + "_div").offset().top + -120
            },
            600
        );
    }

    handleFavouriteCollege(college_id) {
        this.props.onHandleFavouriteCollege(college_id);
        $("#fav_icon_" + college_id)
            .toggleClass("fa-heart")
            .toggleClass("fa-heart-o");
    }

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    renderYearFees = (duration, fee) => {
        const { maxYears } = this.state;
        const durationYears = Math.ceil(duration / 12);

        if (
            durationYears === 5 &&
            (((fee.year1 === fee.year2) === fee.year3) === fee.year4) ===
                fee.year5
        ) {
            return (
                <td colSpan={5}>
                    {CurrencyFormat(fee.year1, fee.currency)} per year
                </td>
            );
        } else if (
            durationYears === 4 &&
            ((fee.year1 === fee.year2) === fee.year3) === fee.year4
        ) {
            return (
                <td colSpan={4}>
                    {CurrencyFormat(fee.year1, fee.currency)} per year
                </td>
            );
        } else if (durationYears === 3 && fee.year1 === fee.year2) {
            return (
                <td colSpan={3}>
                    {CurrencyFormat(fee.year1, fee.currency)} per year
                </td>
            );
        } else if (durationYears === 2 && fee.year1 === fee.year2) {
            return (
                <td colSpan={2}>
                    {CurrencyFormat(fee.year1, fee.currency)} per year
                </td>
            );
        } else {
            return maxYears.map(year =>
                year <= durationYears ? (
                    <td key={year}>
                        {fee["year" + year] > 0
                            ? CurrencyFormat(fee["year" + year], fee.currency)
                            : " - "}
                    </td>
                ) : null
            );
        }
    };

    render() {
        const { college, img_url, is_auth, auth_user } = this.props;
        const { maxYears } = this.state;

        return (
            <div className="row home_container pt-5">
                {college ? (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
                        {/* Breadcrumbs and results counter container */}
                        <div className="row sort_container">
                            <div className="col">
                                <nav area-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>{" "}
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/college_filters">
                                                Colleges
                                            </Link>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            area-current="page">
                                            {college.col_name}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div className="row college_container">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 banner_div">
                                <img
                                    src={img_url + college.col_image}
                                    className="col_banner"
                                    alt="Banner"
                                />
                                <div className="bg_overlay"></div>

                                <div className="row college_details">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <img
                                            src={img_url + college.col_logo}
                                            className="col_logo"
                                            alt="Logo"
                                        />
                                    </div>
                                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 text-left">
                                        <h2>
                                            {" "}
                                            {college.col_name
                                                ? college.col_name
                                                : ""}{" "}
                                        </h2>
                                        <h6>
                                            {" "}
                                            {college.college_city
                                                ? college.college_city
                                                      .city_name + ","
                                                : ""}{" "}
                                            {college.college_state
                                                ? college.college_state
                                                      .state_name
                                                : ""}
                                        </h6>
                                        {college.col_accrs ? (
                                            <h6>
                                                {" "}
                                                Accreditations : NBA, NAAC, NAAC
                                                A++, NAAC A
                                            </h6>
                                        ) : (
                                            ""
                                        )}
                                        {college.col_established ? (
                                            <h6>
                                                {" "}
                                                Established :{" "}
                                                {college.col_established}{" "}
                                            </h6>
                                        ) : (
                                            ""
                                        )}

                                        {/* Favourite college */}
                                        {is_auth && auth_user ? (
                                            <i
                                                className={
                                                    college.favourite
                                                        ? "fav_icon fa fa-heart"
                                                        : " fav_icon fa fa-heart-o"
                                                }
                                                id={"fav_icon_" + college.id}
                                                onClick={() =>
                                                    this.handleFavouriteCollege(
                                                        college.id
                                                    )
                                                }></i>
                                        ) : (
                                            <i className="fav_icon fa fa-heart-o show_login"></i>
                                        )}

                                        <div className="mt-3">
                                            {college.is_popular === "1" ? (
                                                <span className="popular_div ml-0">
                                                    {" "}
                                                    Popular
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* College details */}
                        <div className="row py-3 px-2">
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                                <div className="college_details_card">
                                    <nav
                                        className="navbar navbar-expand-md"
                                        id="college_navbar">
                                        <button
                                            className="navbar_btn navbar-toggler"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#college_details_navbar"
                                            aria-controls="college_details_navbar"
                                            aria-expanded="false"
                                            aria-label="{{ __('Toggle navigation') }}">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/menu_button.png"
                                                }
                                                alt="Menu"
                                                height="30"
                                            />
                                        </button>

                                        <div
                                            className="collapse navbar-collapse"
                                            id="college_details_navbar">
                                            <ul className="navbar-nav m-auto sticky">
                                                <li>
                                                    {" "}
                                                    <span
                                                        className="nav-link about_link active"
                                                        onClick={() =>
                                                            this.handlePageScroll(
                                                                "about"
                                                            )
                                                        }>
                                                        {" "}
                                                        About{" "}
                                                    </span>{" "}
                                                </li>
                                                {/* <li> <span className="nav-link description_link" onClick={() => this.handlePageScroll('description')}> Description </span> </li> */}
                                                <li>
                                                    {" "}
                                                    <span
                                                        className="nav-link courses_link"
                                                        onClick={() =>
                                                            this.handlePageScroll(
                                                                "courses"
                                                            )
                                                        }>
                                                        {" "}
                                                        Courses{" "}
                                                    </span>{" "}
                                                </li>
                                                <li>
                                                    {" "}
                                                    <span
                                                        className="nav-link facilities_link"
                                                        onClick={() =>
                                                            this.handlePageScroll(
                                                                "facilities"
                                                            )
                                                        }>
                                                        {" "}
                                                        Facilities{" "}
                                                    </span>{" "}
                                                </li>
                                                {college.gallery &&
                                                college.gallery.length > 0 ? (
                                                    <li>
                                                        {" "}
                                                        <span
                                                            className="nav-link gallery_link"
                                                            onClick={() =>
                                                                this.handlePageScroll(
                                                                    "gallery"
                                                                )
                                                            }>
                                                            {" "}
                                                            Gallery{" "}
                                                        </span>{" "}
                                                    </li>
                                                ) : (
                                                    ""
                                                )}
                                                <li>
                                                    {" "}
                                                    <span
                                                        className="nav-link contact_link"
                                                        onClick={() =>
                                                            this.handlePageScroll(
                                                                "contact"
                                                            )
                                                        }>
                                                        {" "}
                                                        Contact{" "}
                                                    </span>{" "}
                                                </li>
                                                {/* <li> <span className="nav-link location_link" onClick={() => this.handlePageScroll('location')}> Location </span> </li> */}
                                            </ul>
                                        </div>
                                    </nav>

                                    {/* College details card */}
                                    <div id="about_div" className="details_div">
                                        <h5> ABOUT </h5>
                                        <hr className="hori_line" />
                                        <p>{college.about_col}</p>
                                    </div>

                                    {/* Colleges courses and fees display */}
                                    <div
                                        id="courses_div"
                                        className="details_div">
                                        <h5> COURSES </h5>
                                        <hr className="hori_line" />

                                        {/* Display courses if available */}
                                        {college.courses &&
                                        college.courses.length > 0 ? (
                                            <div
                                                id={
                                                    "college_course_container_" +
                                                    college.id
                                                }>
                                                {college.courses.map(course => (
                                                    <div
                                                        key={course.id}
                                                        className="row px-2 py-4 course_details">
                                                        <div className="col-md-1 col-1 text-right">
                                                            <img
                                                                src={CourseIcon}
                                                                alt="Course"
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                        <div className="col-md-9 col-11 text-left">
                                                            <h6>
                                                                {" "}
                                                                {
                                                                    course.course_name
                                                                }
                                                            </h6>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    course.course_mode
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    course.course_duration
                                                                }{" "}
                                                                months (
                                                                {course.course_duration /
                                                                    12}{" "}
                                                                years){" "}
                                                            </p>
                                                            {/* <p> {course.course_eligibility}.</p> */}
                                                        </div>

                                                        <div className="col">
                                                            {is_auth ? (
                                                                <button
                                                                    className="abroad_btn"
                                                                    data-toggle="collapse"
                                                                    data-target={
                                                                        "#course_fees_collapse_" +
                                                                        course.id
                                                                    }>
                                                                    {" "}
                                                                    View more
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    className="abroad_btn"
                                                                    onClick={
                                                                        this
                                                                            .handleLoginClick
                                                                    }>
                                                                    {" "}
                                                                    View more
                                                                </button>
                                                            )}
                                                        </div>

                                                        {/* Display course fees if available and if user logged in */}
                                                        <div className="col-md-12 col-12 mt-3">
                                                            {is_auth &&
                                                            auth_user ? (
                                                                <div
                                                                    className="collapse"
                                                                    id={
                                                                        "course_fees_collapse_" +
                                                                        course.id
                                                                    }
                                                                    data-parent={
                                                                        "#college_course_container_" +
                                                                        college.id
                                                                    }>
                                                                    <h6 className="text-left">
                                                                        ELIGIBILITY:{" "}
                                                                    </h6>
                                                                    <p className="medium_text text-left text-blue my-2">
                                                                        {
                                                                            course.course_eligibility
                                                                        }
                                                                    </p>

                                                                    <h6 className="text-left mt-4">
                                                                        FEES
                                                                        STRUCTURE:{" "}
                                                                    </h6>
                                                                    <table className="table table-responsive table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th width="8%">
                                                                                    {" "}
                                                                                    Year
                                                                                </th>
                                                                                <th width="12%">
                                                                                    {" "}
                                                                                    Category
                                                                                </th>
                                                                                {maxYears.map(
                                                                                    year =>
                                                                                        year <=
                                                                                        Math.ceil(
                                                                                            course.course_duration /
                                                                                                12
                                                                                        ) ? (
                                                                                            <th
                                                                                                width={
                                                                                                    80 /
                                                                                                        Math.ceil(
                                                                                                            course.course_duration /
                                                                                                                12
                                                                                                        ) +
                                                                                                    "%"
                                                                                                }>
                                                                                                {" "}
                                                                                                Year{" "}
                                                                                                {
                                                                                                    year
                                                                                                }
                                                                                            </th>
                                                                                        ) : null
                                                                                )}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {course.fees &&
                                                                            course
                                                                                .fees
                                                                                .length >
                                                                                0 ? (
                                                                                course.fees.map(
                                                                                    fee => (
                                                                                        <tr
                                                                                            key={
                                                                                                fee.id
                                                                                            }>
                                                                                            <td>
                                                                                                {
                                                                                                    fee.year
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    fee.category
                                                                                                }
                                                                                            </td>

                                                                                            {/* Render fees */}
                                                                                            {this.renderYearFees(
                                                                                                course.course_duration,
                                                                                                fee
                                                                                            )}
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="7">
                                                                                        {" "}
                                                                                        Fees
                                                                                        currently
                                                                                        not
                                                                                        available,
                                                                                        We
                                                                                        will
                                                                                        update
                                                                                        the
                                                                                        fees
                                                                                        soon.
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <h6 className="mt-4">
                                                {" "}
                                                No courses available.
                                            </h6>
                                        )}
                                    </div>

                                    <div
                                        id="facilities_div"
                                        className="details_div">
                                        <h5> FACILITIES </h5>
                                        <hr className="hori_line" />
                                        {college.college_facilities &&
                                        college.college_facilities.length >
                                            0 ? (
                                            <div className="row">
                                                {college.college_facilities.map(
                                                    faci => (
                                                        <div
                                                            key={faci.id}
                                                            className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 text-center p-3">
                                                            <img
                                                                src={
                                                                    img_url +
                                                                    faci.faci_icon
                                                                }
                                                                alt={
                                                                    faci.faci_name
                                                                }
                                                            />
                                                            <h6>
                                                                {" "}
                                                                {
                                                                    faci.faci_name
                                                                }{" "}
                                                                {faci.facility_value &&
                                                                faci.facility_value >
                                                                    0
                                                                    ? " - " +
                                                                      faci.facility_value
                                                                    : ""}{" "}
                                                            </h6>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <h6 className="mt-4">
                                                {" "}
                                                No facilities available.
                                            </h6>
                                        )}
                                    </div>

                                    {college.gallery &&
                                    college.gallery.length > 0 ? (
                                        <div
                                            id="gallery_div"
                                            className="details_div">
                                            <h5> GALLERY </h5>
                                            <hr className="hori_line" />
                                            <div className="row">
                                                <div className="col">
                                                    {/* College gallery */}
                                                    <ImageGallery
                                                        items={college.gallery}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div
                                        id="contact_div"
                                        className="details_div">
                                        <h5> CONTACT </h5>
                                        <hr className="hori_line" />

                                        {college.col_address ? (
                                            <p>
                                                <i className="fa fa-map-marker"></i>{" "}
                                                : {college.col_address}{" "}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {college.col_email ? (
                                            <p>
                                                <i className="fa fa-envelope"></i>{" "}
                                                : {college.col_email}{" "}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {college.col_phone ? (
                                            <p>
                                                <i className="fa fa-phone"></i>{" "}
                                                : {college.col_phone}{" "}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        <p>
                                            {college.col_website ? (
                                                <a
                                                    href={college.col_website}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    {" "}
                                                    <span className="popular_div ml-0">
                                                        {" "}
                                                        Visit Website
                                                    </span>
                                                </a>
                                            ) : (
                                                ""
                                            )}{" "}
                                        </p>
                                    </div>

                                    {/* <div id="location_div" className="details_div">
                    <h5> LOCATION </h5>
                    <hr className="hori_line" />
                    <iframe src="" height="400" />
                  </div> */}
                                </div>
                            </div>

                            {/* Request callback container */}
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                <div
                                    className="college_details_card"
                                    style={{ position: "static", top: "65px" }}>
                                    {/* Callback component with default values */}
                                    <Callback
                                        onLoad={this.props.onSetCallbackValues(
                                            college.col_state,
                                            college.id,
                                            "",
                                            ""
                                        )}
                                    />

                                    <div className="details_div mt-3">
                                        <h5> VISIT US </h5>
                                        <hr className="hori_line" />
                                        <h6 className="text-blue text-left text-uppercase">
                                            <b> GooCampus Edu Solutions </b>
                                        </h6>
                                        <p className="small_text text-left text-blue">
                                            # 138/6, Ground Floor, 10th Main
                                            Road, <br />
                                            6th A Cross, Sadashiv Nagar, <br />
                                            RMV Extension, Behind SBI Bank,{" "}
                                            <br />
                                            Bengaluru, Karnataka - 560080 <br />
                                            Landmark : Next to Chevron Orchards{" "}
                                        </p>

                                        <p className="small_text text-blue">
                                            {" "}
                                            Monday - Saturday <br />9 am - 6 pm
                                        </p>
                                    </div>

                                    <div className="details_div">
                                        <h5> CONTACT US </h5>
                                        <hr className="hori_line" />
                                        <p className="text-blue">
                                            <i className="fa fa-phone"></i> :
                                            080 41743956{" "}
                                        </p>
                                        <p className="text-blue">
                                            <i className="fa fa-envelope"></i> :
                                            info@goocampus.in{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* get in touch container */}
                        {is_auth ? (
                            ""
                        ) : (
                            <div className="row interested_container">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                                    <h2 className="main_header">
                                        <b> Interested? </b>
                                    </h2>
                                    <h5 className="text-center mt-3">
                                        {" "}
                                        Sign in to get in touch with us to know
                                        better about the colleges, study and
                                        work in abroad.
                                    </h5>
                                    <div className="mt-3">
                                        <button className="mt-2 abroad_btn py-2 show_login">
                                            {" "}
                                            <i className="fa fa-sign-in mr-2"></i>{" "}
                                            SIGN IN / SIGN UP
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        college: state.college.college_details,
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        img_url: state.home.img_url
    };
};

const mapActionsToProps = {
    onGetCollegeDetails: getCollegeDetails,
    onHandleFavouriteCollege: addRemoveFavourite,
    onSetCallbackValues: setCallbackValues
};

export default connect(mapStatesToProps, mapActionsToProps)(CollegeDetails);
