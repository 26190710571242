import React from "react";
import "./Courses.css";

const BDSCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>Bachelor of Dental Surgery (BDS) </b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            B.D.S. or the Bachelor of Dental Surgery is an
                            undergraduate dentistry course pursued by those wish
                            to carve a medical career in dentistry. The B.D.S.
                            course lasts for about 4-5 years depending on the
                            completion of the internship and is usually divided
                            into multiple semesters. B.D.S. is one of the most
                            popular medical science course after M.B.B.S.
                        </p>
                        <p className="para">
                            Although the course can be pursued either full-time
                            or part-time, most candidates choose to take this up
                            as a full-time graduate course in a dentistry
                            college.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <ul className="courses_list">
                            <li>
                                The course requires candidates to have completed
                                their 10+2 or equivalent examination with
                                Physics, Chemistry, Biology and English with at
                                least 50% marks.
                            </li>
                            <li>
                                Admission to dentistry courses is through the
                                nationwide NEET-UG examination, conducted by the
                                National Testing Agency.
                            </li>
                            <li>
                                The allocation seats and colleges is based on
                                the candidate’s merit.
                            </li>
                            <li>
                                There are state-run medical institutions for
                                which a candidate should be able to prove
                                domicile status in the state. There are also
                                centrally-run institutes. All these institutes
                                fall under the purview of the Dental Council of
                                India.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Subjects</h4>
                        <ul className="courses_list">
                            <li>Human Physiology</li>
                            <li>Materials used in Dentistry</li>
                            <li>
                                Human Oral Anatomy, Physiology, Histology and
                                Tooth Morphology
                            </li>
                            <li>Oral Pathology and Oral Microbiology</li>
                            <li>Orthodontics</li>
                            <li>Oral and Maxillofacial Surgery</li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Syllabus</h4>
                        <table className="table table-bordered table-responsive table-striped">
                            <tbody>
                                <tr>
                                    <td rowspan="5">BDS-I</td>
                                    <td>
                                        General Human Anatomy including
                                        Embryology and Histology
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        General Human Anatomy including
                                        Embryology and Histology
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dental Anatomy, embryology and Oral
                                        Histology
                                    </td>
                                </tr>
                                <tr>
                                    <td>Dental Materials</td>
                                </tr>
                                <tr>
                                    <td>
                                        Preclinical Prosthodontics and Crown and
                                        Bridge
                                    </td>
                                </tr>

                                <tr>
                                    <td rowspan="6">BDS-II</td>
                                    <td>General Pathology and Microbiology</td>
                                </tr>
                                <tr>
                                    <td>
                                        General and Dental Pharmacology and
                                        Therapeutics
                                    </td>
                                </tr>
                                <tr>
                                    <td>Dental Materials</td>
                                </tr>
                                <tr>
                                    <td>Preclinical Conservative Dentistry</td>
                                </tr>
                                <tr>
                                    <td>
                                        Preclinical Prosthodontics and Crown &
                                        Bridge
                                    </td>
                                </tr>
                                <tr>
                                    <td>Oral Pathology & Oral Microbiology</td>
                                </tr>

                                <tr>
                                    <td rowspan="10">BDS-III</td>
                                    <td>General Medicine</td>
                                </tr>
                                <tr>
                                    <td>General Surgery</td>
                                </tr>
                                <tr>
                                    <td>Oral Pathology & Microbiology</td>
                                </tr>
                                <tr>
                                    <td>
                                        Conservative Dentistry and Endodontics
                                    </td>
                                </tr>
                                <tr>
                                    <td>Oral and Maxillofacial Surgery</td>
                                </tr>
                                <tr>
                                    <td>Oral Medicine and Radiology</td>
                                </tr>
                                <tr>
                                    <td>
                                        Orthodontics & Dentofacial Orthopaedics
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pediatric & Preventive Dentistry</td>
                                </tr>
                                <tr>
                                    <td>Periodontology</td>
                                </tr>
                                <tr>
                                    <td>Prosthodontics and Crown & Bridge</td>
                                </tr>
                                <tr>
                                    <td rowspan="8">BDS-IV</td>
                                    <td>
                                        Orthodontics and Dentofacial
                                        Orthopaedics
                                    </td>
                                </tr>
                                <tr>
                                    <td>Oral Medicine and Radiology</td>
                                </tr>
                                <tr>
                                    <td>Pediatric and Preventive Dentistry</td>
                                </tr>
                                <tr>
                                    <td>Periodontology</td>
                                </tr>
                                <tr>
                                    <td>Oral and Maxillofacial Surgery</td>
                                </tr>
                                <tr>
                                    <td>Prosthodontics and crown & bridge</td>
                                </tr>
                                <tr>
                                    <td>
                                        Conservative Dentistry and Endodontics
                                    </td>
                                </tr>
                                <tr>
                                    <td>Public health dentistry</td>
                                </tr>
                                <tr>
                                    <td rowspan="6">BDS-V</td>
                                    <td>Oral & Maxillofacial Surgery</td>
                                </tr>
                                <tr>
                                    <td>Prosthodontics and crown & bridge</td>
                                </tr>
                                <tr>
                                    <td>
                                        Conservative Dentistry and Endodontics
                                    </td>
                                </tr>
                                <tr>
                                    <td>Public health dentistry</td>
                                </tr>
                                <tr>
                                    <td>
                                        Internship - Every candidate will be
                                        required, after passing the final BDS
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        examination, to undergo one year paid
                                        rotating internship in a dental college.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course suitability</h4>
                        <ul className="courses_list">
                            <li>
                                Candidates who are passionate about making a
                                difference in society are the most ideal people
                                to pursue this course. If you can bear terrible
                                sights, smells, and have a firm mindset, then
                                B.D.S. is for you.
                            </li>
                            <li>
                                During the diagnosis which is done by physical
                                examination, trainees will be exposed to
                                obnoxious smells from both, the patient’s mouth
                                as well as the medicines used during the
                                procedure.{" "}
                            </li>
                            <li>
                                If you are choosing this line of study, you must
                                be mentally prepared. Having a calm mind &
                                patience is very important during crucial
                                surgeries.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Employment areas</h4>
                        <ul className="courses_list">
                            <li>Dental Clinics</li>
                            <li>Dental Equipment Manufacturers</li>
                            <li>Dental Products Manufacturers</li>
                            <li>Educational Institutes</li>
                            <li>Hospitals</li>
                            <li>Pharmaceutical Companies</li>
                            <li>Private Practice </li>
                            <li>Research Institutes </li>
                        </ul>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Job types</h4>
                        <ul className="courses_list">
                            <li>Dental Hygienist</li>
                            <li>Consultant</li>
                            <li>Dental Assistant</li>
                            <li>Public Health Specialist</li>
                            <li>Oral Pathologist</li>
                            <li>Professor</li>
                            <li>Dental Surgeon</li>
                            <li>Medical Representative/Sales Representative</li>
                            <li>Dental Lab Technician/Ceramist</li>
                            <li>Private Practitioner</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BDSCourse;
