import React from "react";
import "./Courses.css";

const MBBSCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>
                                Bachelor of Medicine and Bachelor of Surgery
                                (MBBS)
                            </b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            M.B.B.S. or Bachelor of Medicine and Bachelor of
                            Surgery is an undergraduate medical degree course
                            dealing with aspects of Medicine and Surgery. The
                            word M.B.B.S. is derived from 'Medicinae
                            Baccalaureus' (Bachelor of Medicine in Latin) and
                            B.S. - 'Bachelor of Surgery' (in English). The
                            course generally lasts for a period of 5 ½ years,
                            which is divided into upto 9 semesters of 6 months
                            each including an year of compulsory rotating
                            internship.
                        </p>
                        <p className="para">
                            Candidates are provided with an in-depth
                            understanding and knowledge of the entire human
                            body, through both theoretical and practical
                            classes. Practical experience can includes the
                            dissection of bodies to observe the various types of
                            surgeries performed by the modern medical
                            professionals.
                        </p>
                        <p className="para">
                            This rigorous course is best suited for those who
                            naturally have a scientific bent of mind and those
                            with to serve mankind with the best of intentions.
                            It is one of the most coveted and most popular
                            medical course in the country.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <p className="para">
                            Candidates aspiring to study M.B.B.S must have
                            completed their Class 12 examination with a minimum
                            aggregate of 50%(General Merit) and 40% (Reserved
                            category) in the subjects of Physics, Chemistry, &
                            Biology from the respective Education board before
                            admission.
                        </p>
                        <p className="para">
                            Candidates who have completed their Class 12 from a
                            Correspondence/Open School will be not be eligible
                            for M.B.B.S
                        </p>
                        <p className="para">
                            He/she must have completed an age of 17 years at the
                            time of admission or will complete that age on or
                            before 31st December in the year of his/her
                            admission to the 1st year MBBS Course.
                        </p>
                        <p className="para">
                            As of December 2018, the upper age limit for M.B.B.S
                            is 25 years with a relaxation of 5 years for the
                            candidates belonging to SC/ST/OBC category and
                            persons entitled for reservation under the Rights of
                            Persons with Disabilities Act, 2016.
                        </p>
                        <p className="para">
                            Candidates who have passed B.Sc examination from an
                            Indian University, subject to the condition that
                            they must have passed the examination with not less
                            than two of the following subjects: Physics,
                            Chemistry, Biology (Botany, Zoology), in addition to
                            this the candidate must have passed the earlier
                            qualifying examination with the following subjects:
                            Physics, Chemistry, Biology and English.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Subjects</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li className="para">Anaesthesiology</li>
                                    <li className="para">
                                        Bachelor of Medicine and Bachelor of
                                        Surgery Specialisations
                                    </li>
                                    <li className="para">Biochemistry</li>
                                    <li className="para">Dermatology</li>
                                    <li className="para">
                                        Dermatology and Sexually Transmitted
                                        Diseases
                                    </li>
                                    <li className="para">
                                        E.N.T. (Ear, Nose and Throat)
                                    </li>
                                    <li className="para">
                                        Forensic Medicine including Toxicology
                                    </li>
                                    <li className="para">General Medicine</li>
                                    <li className="para">General Surgery</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li className="para">Human Anatomy</li>
                                    <li className="para">
                                        Human Physiology Including Bio-Physics
                                    </li>
                                    <li className="para">
                                        Introduction to Humanities & Community
                                        Medicine
                                    </li>
                                    <li className="para">
                                        Obstetrics and Gynaecology
                                    </li>
                                    <li className="para">Ophthalmology</li>
                                    <li className="para">Orthopaedics</li>
                                    <li className="para">Paediatrics</li>
                                    <li className="para">Psychiatry</li>
                                    <li className="para">Radiotherapy</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Tentative training period (as per MCI regulations)
                        </h4>
                        <ul className="courses_list">
                            <li className="para">
                                Every candidate has to mandatorily undergo a
                                study period that extends to over 4 ½ years,
                                which is divided into 9 semesters of roughly 6
                                months each. Each semester will consist of
                                approximately 120 teaching days of 8 college
                                working hours. This study period will be
                                followed by a 1-year mandatory rotating
                                internship.{" "}
                            </li>
                            <li className="para">
                                The study period of 4 ½ years are divided as
                                follows :
                                <ul className="courses_list" type="roman">
                                    <li className="para">
                                        Phase I: This phase consists of
                                        pre-clinical subjects, followed by
                                        roughly 60 hours of study time for
                                        Community Medicine. The rest of the time
                                        shall be approximately divided between
                                        Anatomy and Physiology subjects along
                                        with Biochemistry.{" "}
                                    </li>
                                    <li className="para">
                                        Phase II: This phase consists of
                                        paraclinical/clinical subjects which are
                                        normally taught simultaneously. The
                                        subjects in this phase are Pathology,
                                        Microbiology, Forensic Medicine
                                        (including Toxicology and some portions
                                        of Community Medicine) and Pharmacology.
                                        As per MCI, equal time must be alloted
                                        to Pathology, Forensic Medicine and
                                        Community Medicine, Pharmacology and
                                        Microbiology.
                                    </li>
                                    <li className="para">
                                        Phase III: This phase is a continuation
                                        of the study of clinical subjects after
                                        completing Phase-1. The clinical
                                        subjects taught during Phase II and
                                        Phase III are Medicine (and allied
                                        specialities), Surgery (and allied
                                        specialities), Community Medicine,
                                        Gynaecology and Obstetrics. The rest of
                                        the time should be spent on lectures,
                                        demonstrations, group discussions and
                                        various informational seminars.
                                    </li>
                                    <li className="para">
                                        Medicine (and allied specialities)
                                        include :
                                        <ul className="courses_list">
                                            <li className="para">
                                                General medicine.
                                            </li>
                                            <li className="para">
                                                Pediatrics.
                                            </li>
                                            <li className="para">
                                                Tuberculosis and chest.
                                            </li>
                                            <li className="para">Skin.</li>
                                            <li className="para">
                                                Sexually transmitted diseases.
                                            </li>
                                            <li className="para">
                                                Psychiatry.
                                            </li>
                                            <li className="para">
                                                Radio-diagnosis.
                                            </li>
                                            <li className="para">
                                                Infectious diseases.
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="para">
                                        Surgery (and allied specialities)
                                        include :
                                        <ul className="courses_list">
                                            <li className="para">
                                                General surgery.
                                            </li>
                                            <li className="para">
                                                Orthopedics surgery (including
                                                Physiotherapy & rehabilitation).
                                            </li>
                                            <li className="para">
                                                Ophthalmology.
                                            </li>
                                            <li className="para">
                                                Otorhinolaryngology.
                                            </li>
                                            <li className="para">
                                                Anesthesia.
                                            </li>
                                            <li className="para">Dentistry.</li>
                                            <li className="para">
                                                Radiotherapy.
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="para">
                                        Surgery (and allied specialities)
                                        include :
                                        <ul className="courses_list">
                                            <li className="para">
                                                Family medicine.
                                            </li>
                                            <li className="para">
                                                Family welfare planning.
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="para">
                                        Semester-I and Semester-II
                                        (approximately 120 days each) shall
                                        focus in Phase-I subjects (pre-clinical)
                                        and introduce the students to a wider
                                        understanding of the various
                                        perspectives of the medical education
                                        system, which ultimately leads to
                                        healthcare delivery. Students have to
                                        pass in all Phase-I subjects before
                                        joining Phase-II. The students are
                                        allowed 4 chances to clear Phase-I
                                        subjects which is to be completed in 3
                                        years from the enrolment date.
                                    </li>
                                    <li className="para">
                                        Once students have cleared all their
                                        pre-clinical subjects, they are moved to
                                        para-clinical subjects, along with
                                        clinical posting. In Phase-III both
                                        pre-clinical and para-clinical subjects
                                        will be integrated with clinical
                                        subjects when ever relevant.
                                    </li>
                                    <li className="para">
                                        Didactic lectures should ideally not
                                        exceed 1/3rd of the time schedule. 2/3rd
                                        schedule should include clinical,
                                        practical and/or group discussions. This
                                        learning process must also include live
                                        experiences and focus on
                                        problem-oriented approach including case
                                        studies and community healthcare
                                        assignments.
                                    </li>
                                    <li className="para">
                                        All Universities must organise admission
                                        timings and admission process in such a
                                        way that course teaching would start
                                        from August 1 of each year.
                                    </li>
                                    <li className="para">
                                        Supplementary examination may be
                                        conducted with a time period of 6
                                        months, the students who pass this are
                                        permitted to join the main batch. Those
                                        students who who do not pass this will
                                        have to appear once again in the next
                                        year’s supplementary examination.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Internship</h4>
                        <p className="para">
                            An Internship is a period of training where a
                            graduate is supposed to learn and acquire skills by
                            practicing medicine and healthcare under supervision
                            so that he/she has the ability to work
                            independently. The allocation of a time period for
                            each discipline is approximate and the candidate
                            will be guided accordingly based on the actual
                            experience gained. If a student is serving an
                            internship in a rural district or in a emergency
                            room of a taluk hospital, he/she may acquire skills
                            in Surgery, Orthopedics, Medicine, Obstetrics and
                            Gynaecology and Paediatrics during a single night on
                            duty. To maximize the intern’s opportunities to
                            practice skills in patient care, the responsible
                            authorities will adjust the intern’s experience,
                            given the approximation of time allocation{" "}
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Internship Time Duration (as per MCI website)
                        </h4>
                        <table className="table table-bordered table-responsive table-striped">
                            <tbody>
                                <tr>
                                    <td>Community Medicine:</td>
                                    <td>2 months</td>
                                </tr>
                                <tr>
                                    <td>
                                        Medicine(including 15 days of
                                        Psychiatry):{" "}
                                    </td>
                                    <td>2 months</td>
                                </tr>
                                <tr>
                                    <td>
                                        Surgery(including 15 days Anaesthesia):{" "}
                                    </td>
                                    <td>2 months</td>
                                </tr>
                                <tr>
                                    <td>
                                        Obst./Gynae.(including Family Welfare
                                        Planning):{" "}
                                    </td>
                                    <td>2 months</td>
                                </tr>
                                <tr>
                                    <td>Paediatrics: </td>
                                    <td>1 month</td>
                                </tr>
                                <tr>
                                    <td>Orthopaedics(including PMR):</td>
                                    <td>1 month</td>
                                </tr>
                                <tr>
                                    <td>ENT:</td>
                                    <td>15 days</td>
                                </tr>
                                <tr>
                                    <td>Ophthalmology: </td>
                                    <td>15 days</td>
                                </tr>
                                <tr>
                                    <td>Casualty:</td>
                                    <td>15 days</td>
                                </tr>
                                <tr>
                                    <td>Elective Postings (1 x 15 days):</td>
                                    <td>15 days</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Elective Postings</h4>
                        <span className="para">
                            Elective posting will include two of the following
                            for 15 days in each subject.
                        </span>
                        <ul className="courses_list">
                            <li className="para">
                                Dermatology and Sexually Transmitted Diseases.
                            </li>
                            <li className="para">
                                Tuberculosis and Respiratory Diseases.
                            </li>
                            <li className="para">Radio-Diagnosis.</li>
                            <li className="para">Forensic Medicine.</li>
                            <li className="para">Blood Bank.</li>
                            <li className="para">Psychiatry.</li>
                        </ul>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Employment opportunities</h4>
                        <ul className="courses_list">
                            <li className="para">Biomedical Companies</li>
                            <li className="para">Health Centres</li>
                            <li className="para">Hospitals</li>
                            <li className="para">Laboratories</li>
                            <li className="para">Medical Colleges</li>
                            <li className="para">Medical Foundation/Trust</li>
                            <li className="para">Non-Profit Organizations</li>
                            <li className="para">Nursing Homes</li>
                            <li className="para">
                                Pharmaceutical and Biotechnology Companies
                            </li>
                            <li className="para">Polyclinics</li>
                            <li className="para">Private Practice</li>
                            <li className="para">Research Institutes </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Job types</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li className="para">
                                        Anaesthetist or Anaesthesiologist
                                    </li>
                                    <li className="para">Bacteriologist</li>
                                    <li className="para">Cardiologist</li>
                                    <li className="para">
                                        Chief Medical Officer (CMO)
                                    </li>
                                    <li className="para">Chiropodist</li>
                                    <li className="para">
                                        Clinical Laboratory Scientist
                                    </li>
                                    <li className="para">Dermatologist</li>
                                    <li className="para">E.N.T. Specialist</li>
                                    <li className="para">Enterologist</li>
                                    <li className="para">Gastroenterologist</li>
                                    <li className="para">
                                        General Practitioner
                                    </li>
                                    <li className="para">General Surgeon</li>
                                    <li className="para">Gynaecologist</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li className="para">
                                        Hospital Administrator
                                    </li>
                                    <li className="para">
                                        Medical Admitting Officer
                                    </li>
                                    <li className="para">Neurologist</li>
                                    <li className="para">Nutritionist</li>
                                    <li className="para">Obstetrician</li>
                                    <li className="para">Orthopaedist</li>
                                    <li className="para">Paediatrician</li>
                                    <li className="para">Pathologist</li>
                                    <li className="para">Physician</li>
                                    <li className="para">Physiologist</li>
                                    <li className="para">Psychiatrist</li>
                                    <li className="para">Radiologist</li>
                                    <li className="para">
                                        Resident Medical Officer
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MBBSCourse;
