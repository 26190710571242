import React from "react";
import "./Courses.css";

const MDMSCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>
                                Doctor of Medicine (MD) / Master in Surgery (MS)
                            </b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            M.D. of Doctor of Medicine is a highly sought after
                            postgraduate medicine course, that candidates
                            generally pursue after completing their M.B.B.S.
                            undergraduate course. M.D. is always pursued in a
                            specific specialization such as Radiology,
                            Cardiology, Gastroenterology etc. This is a 3 year/6
                            academic term course which also includes a rigorous
                            examination that tests both the theoretical and
                            practical skills of the candidate.{" "}
                        </p>
                        <p className="para">
                            This course is highly focused on a practical &
                            research-based methodology instead of just
                            theoretical knowledge. Medical Council of India is
                            the singular authority that has the mandate to
                            oversee, approve and recognize all the medical
                            colleges to see if they are fit to provide this
                            degree.
                        </p>
                        <p className="para">
                            Candidates need to be ready for a lot of reading&
                            studying if they wish to pursue their M.D. course.
                            It is perhaps one of the most popular courses chosen
                            by M.B.B.S. graduates due to many factors, including
                            the expected remuneration after course completion.{" "}
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <p className="para">
                            Only those candidates who hold an M.B.B.S. degree
                            are eligible to apply for the M.D. courses. In
                            addition to this, the M.B.B.S. degree must be from a
                            university that is recognised by the Medical Council
                            of India. The exact percentage required to apply may
                            vary across institutes. The score & rank obtained in
                            the common entrance exam (NEET-PG, AIIMS-PG,
                            JIPMER-PG) forms the criteria for selection &
                            admission.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Specialisations</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Dermatology</li>
                                    <li>Radio-therapy</li>
                                    <li>Radio-diagnosis</li>
                                    <li>Orthopedics</li>
                                    <li>Pathology</li>
                                    <li>General medicine</li>
                                    <li>Obstetrics and Gynaecology</li>
                                    <li>General surgery</li>
                                    <li>Ophthalmology</li>
                                    <li>Paediatrics</li>
                                    <li>Forensic medicine</li>
                                    <li>Psychiatry</li>
                                    <li>ENT</li>
                                    <li>
                                        Pulmonary Medicine/Respiratory Medicine
                                    </li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>
                                        Transfusion Medicine/Immuno-Haematology
                                        and Blood Transfusion
                                    </li>
                                    <li>Anaesthesiology</li>
                                    <li>Geriatrics</li>
                                    <li>Pharmacology</li>
                                    <li>
                                        Preventive and social medicine/community
                                        medicine
                                    </li>
                                    <li>
                                        Emergency and critical care/emergency
                                        medicine
                                    </li>
                                    <li>Sports medicine</li>
                                    <li>Family medicine</li>
                                    <li>Biochemistry</li>
                                    <li>Community health and administration</li>
                                    <li>Hospital administration</li>
                                    <li>Anatomy</li>
                                    <li>Physiology</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Thesis Requirement</h4>
                        <span className="para">(i) 4 x Theory Papers.</span>
                        <p className="para">
                            For M.D. courses in Basic Medical Sciences, the
                            syllabus will be as per the allotted distribution.
                            Each paper will consist of 2 x essay type questions
                            (of 25 marks each) and 5 x short answer questions
                            (of 10 marks each).
                        </p>

                        <p className="para">
                            Model question papers are available for each
                            specialisation.
                        </p>
                        <p className="para">
                            For M.D. courses in Clinical disciplines, Paper-I
                            will consist of Basic Medical Sciences and Paper-IV
                            will consist of topics from Recent Advances in the
                            concerned subjects.
                        </p>

                        <p className="para">
                            Paper-I of all Clinical subjects will have 10 x
                            short answers (of 10 marks each).
                        </p>
                        <p className="para">
                            Paper-II to Paper-IV will have 2 x essay type
                            questions (of 25 marks each) and 5 x short answer
                            questions (of 10 marks each).{" "}
                        </p>

                        <p className="para">
                            Model question papers on each specialisation is
                            easily available online.
                        </p>
                        <span className="para">
                            (ii) Basic Medical Sciences
                        </span>
                        <span className="para">
                            (iii) In order to ensure a smooth assessment and
                            evaluation process, the theory examinations are
                            generally held before the clinical/practical
                            examinations.
                        </span>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Written Examination</h4>
                        <p className="para">
                            At the completion of the course (3 years/6 academic
                            terms) the candidates have to undergo a mandatory
                            written examination, which is organised to evaluate
                            and certify the candidate’s skill, both theoretical
                            and practical.
                        </p>
                        <p className="para">
                            It is common practice that candidates are required
                            to secure a minimum of 50% marks, both in theory and
                            in practical sections separately for passing the
                            examination.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Theory Portions</h4>
                        <span className="para">(i) 4 x Theory Papers.</span>
                        <p className="para">
                            For M.D. courses in Basic Medical Sciences, the
                            syllabus will be as per the allotted distribution.
                            Each paper will consist of 2 x essay type questions
                            (of 25 marks each) and 5 x short answer questions
                            (of 10 marks each).
                        </p>

                        <p className="para">
                            Model question papers are available for each
                            specialisation.
                        </p>
                        <p className="para">
                            For M.D. courses in Clinical disciplines, Paper-I
                            will consist of Basic Medical Sciences and Paper-IV
                            will consist of topics from Recent Advances in the
                            concerned subjects.
                        </p>

                        <p className="para">
                            Paper-I of all Clinical subjects will have 10 x
                            short answers (of 10 marks each).
                        </p>
                        <p className="para">
                            Paper-II to Paper-IV will have 2 x essay type
                            questions (of 25 marks each) and 5 x short answer
                            questions (of 10 marks each).{" "}
                        </p>

                        <p className="para">
                            Model question papers on each specialisation is
                            easily available online.
                        </p>
                        <span className="para">
                            (ii) Basic Medical Sciences
                        </span>
                        <span className="para">
                            (iii) In order to ensure a smooth assessment and
                            evaluation process, the theory examinations are
                            generally held before the clinical/practical
                            examinations.
                        </span>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Clinical/Practical and Oral</h4>
                        <p className="para">
                            (i)In order to test the candidate’s knowledge and
                            level of competence in the Clinical Sciences
                            subjects, they are required to undergo an
                            examination which contains 1 x long case and 2 x
                            short cases.
                        </p>
                        <p className="para">
                            (ii) To test the candidate’s skill and competence
                            for making valid observations on experiments and
                            laboratory studies they are required to give a
                            practical examination in Basic Medical Sciences .
                        </p>
                        <p className="para">
                            (iii) To test each candidate’s knowledge about the
                            subject and to know their competence in
                            investigative procedures, therapeutic technique and
                            other aspects of the specialisation they are
                            required to undergo an oral examination.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Is it suitable for you?</h4>
                        <ul className="courses_list">
                            <li>
                                Candidates must possess excellent communication
                                and interpersonal skills as they would be
                                dealing with a large variety of people.
                            </li>
                            <li>
                                Candidates must stay updated with the
                                improvements in the field of technological
                                advancements & medical innovations.
                            </li>
                            <li>
                                Patience & physical stamina are key traits to
                                meet the gruelling demands of this profession.
                            </li>
                            <li>
                                Candidates must possess great skill in
                                performing tasks, especially with their hands.
                            </li>
                        </ul>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Employment opportunities</h4>
                        <ul className="courses_list">
                            <li>Biomedical Companies</li>
                            <li>Health Centres</li>
                            <li>Hospitals</li>
                            <li>Laboratories</li>
                            <li>Medical Colleges</li>
                            <li>Medical Foundation/Trust</li>
                            <li>Non-Profit Organizations</li>
                            <li>Nursing Homes</li>
                            <li>Pharmaceutical and Biotechnology Companies</li>
                            <li>Polyclinics</li>
                            <li>Private Practice</li>
                            <li>Research Institutes </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Job types</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Anaesthetist or Anaesthesiologist</li>
                                    <li>Bacteriologist</li>
                                    <li>Cardiologist</li>
                                    <li>Chief Medical Officer (CMO)</li>
                                    <li>Chiropodist</li>
                                    <li>Clinical Laboratory Scientist</li>
                                    <li>Dermatologist</li>
                                    <li>E.N.T. Specialist</li>
                                    <li>Enterologist</li>
                                    <li>Gastroenterologist</li>
                                    <li>General Practitioner</li>
                                    <li>General Surgeon</li>
                                    <li>Gynaecologist</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Hospital Administrator</li>
                                    <li>Medical Admitting Officer</li>
                                    <li>Neurologist</li>
                                    <li>Nutritionist</li>
                                    <li>Obstetrician</li>
                                    <li>Orthopaedist</li>
                                    <li>Paediatrician</li>
                                    <li>Pathologist</li>
                                    <li>Physician</li>
                                    <li>Physiologist</li>
                                    <li>Psychiatrist</li>
                                    <li>Radiologist</li>
                                    <li>Resident Medical Officer</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MDMSCourse;
