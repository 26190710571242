import React from "react";

const formatCurrency = (amount, currency) => {
    if (currency === "Rupees") {
        return amount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    } else {
        return amount.toString().replace(/(\d)(?=(\d\d\d)+$)/g, "$1,");
    }
};

const CurrencyFormat = (amount, currency) => {
    return (
        <span>
            <i
                className={
                    "fa " +
                    (currency === "Rupees" ? "fa-rupee" : "fa-dollar") +
                    " mr-1"
                }></i>
            {formatCurrency(amount, currency)}
        </span>
    );
};

export default CurrencyFormat;
