import React from "react";
import "./Checkout.css";
import { connect } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";
import { setCallbackValues } from "../../actions/Callbacks";
import { getPlanBenefits, getPlanDetails } from "../../actions/Listings";
import SaleIcon from "../../assets/sale.png";
import Logo from "../../assets/logo.png";
import server from "../../utils/Server";
import { getCartDetails, removeFromCart } from "../../actions/Home";

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            price: this.props.cart.cart_amount
                ? this.props.cart.cart_amount.total_amount
                : 0,
            plan_name: this.props.match.params.plan_name,
            coupon: "",
            error: ""
        };
        this.handleCallback = this.handleCallback.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this._handleCouponCode = this._handleCouponCode.bind(this);
    }

    componentWillMount = () => {
        this.props.onGetCartDetails();
    };

    handleRemoveItem = plan_id => {
        this.props.onRemoveFromCart(plan_id);
    };

    _handleCouponCode = coupon => {
        if (coupon !== "") {
            this.setState({ error: "" });
            this.props.onGetCartDetails(coupon);
        } else this.setState({ error: "Enter valid coupon" });
    };

    handleCheckOut = e => {
        e.preventDefault();

        // Check if user logged in
        if (
            this.props.is_auth &&
            this.props.auth_user &&
            this.props.auth_user !== ""
        ) {
            var auth_user = this.props.auth_user;

            // Check if plan selected

            var amount = this.state.price;
            var formData = new FormData(
                document.getElementById("checkout_form")
            );

            server.post("/save_checkout", formData).then(response => {
                if (response.data.code === 200) {
                    var order_id = response.data.order_id;

                    // Initialize payment
                    var options = {
                        key: "rzp_live_fRZhygNs7HIRwh",
                        amount: amount * 100,
                        name: "Counselling packages",
                        description: "GooCampus Edu Solutions",
                        image: Logo,
                        order_id: order_id,
                        theme: {
                            color: "#44a1a0"
                        },
                        handler: function (response) {
                            if (
                                response &&
                                response.razorpay_payment_id !== ""
                            ) {
                                // Make payment success in server
                                server
                                    .post("/payment_success", response)
                                    .then(resp => {
                                        if (resp.data.code === 200) {
                                            // Redirecting to success page after successfull payment
                                            window.location.href =
                                                "/#/payment_success";
                                        }
                                    });
                            }
                        },
                        prefill: {
                            contact: auth_user.mobile,
                            email: auth_user.email
                        }
                    };

                    let rzp = new window.Razorpay(options);
                    rzp.open();
                }
            });
        } else {
            this.handleLoginClick();
        }
    };

    handleCallback = (state, college, stream, level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, level);
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { is_auth, benefits, cart, plan } = this.props;
        const { plans, cart_amount } = this.props.cart;
        const { coupon, error } = this.state;

        return (
            <div className="row mt-4 pt-4">
                <div className="col">
                    <div className="container checkout_container text-center">
                        <h4 className="header"> CHECKOUT</h4>
                        <span className="small_text">
                            {" "}
                            You are just one step away from payment, select your
                            payment method.{" "}
                        </span>
                        <br />

                        {cart ? (
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-4">
                                    {plans && plans.length > 0
                                        ? plans.map(plan => (
                                              <div
                                                  key={plan.id}
                                                  className="row pricing_package mb-4">
                                                  <div className="col package_header">
                                                      <i
                                                          className="fa fa-trash remove_cart_btn"
                                                          onClick={() =>
                                                              this.handleRemoveItem(
                                                                  plan.id
                                                              )
                                                          }></i>
                                                      <h5 className="text-uppercase">
                                                          {" "}
                                                          {plan.plan_name}{" "}
                                                      </h5>
                                                      {/* <img src={CheckIcon} className="check_icon" alt="Check Icon" /> */}
                                                      {plan.offer_price > 0 &&
                                                      plan.offer_price <
                                                          plan.plan_price ? (
                                                          <div>
                                                              <span className="actual_price">
                                                                  {" "}
                                                                  <i className="fa fa-rupee"></i>{" "}
                                                                  {
                                                                      plan.plan_price
                                                                  }{" "}
                                                              </span>
                                                              <br />
                                                              <h1>
                                                                  {" "}
                                                                  <i className="fa fa-rupee"></i>{" "}
                                                                  {
                                                                      plan.offer_price
                                                                  }{" "}
                                                                  <img
                                                                      src={
                                                                          SaleIcon
                                                                      }
                                                                      alt="Offer"
                                                                      className="offer_banner"
                                                                  />{" "}
                                                              </h1>
                                                              {plan.offer_name !==
                                                              "" ? (
                                                                  <span className="small_text">
                                                                      <strong>
                                                                          {" "}
                                                                          {
                                                                              plan.offer_name
                                                                          }{" "}
                                                                      </strong>
                                                                  </span>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      ) : (
                                                          <h1>
                                                              {" "}
                                                              <i className="fa fa-rupee"></i>{" "}
                                                              {plan.plan_price}{" "}
                                                          </h1>
                                                      )}
                                                  </div>
                                              </div>
                                          ))
                                        : ""}
                                </div>

                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 p-4">
                                    <div className="pricing_package checkout_package p-3">
                                        <h2> CHECKOUT </h2>
                                        <br />

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3 text-justify">
                                                <span className="small_text ">
                                                    Have any offer code?
                                                </span>

                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        name="coupon"
                                                        className="form-control"
                                                        placeholder="Coupon code"
                                                        value={coupon}
                                                        onChange={e =>
                                                            this.setState({
                                                                coupon: e.target
                                                                    .value
                                                            })
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            type="button"
                                                            className="abroad_btn coupon_btn"
                                                            onClick={() =>
                                                                this._handleCouponCode(
                                                                    coupon
                                                                )
                                                            }>
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                                <span className="small_text text-danger">
                                                    {error}
                                                </span>
                                                {cart_amount &&
                                                cart_amount !== "" ? (
                                                    <span className="small_text text-success">
                                                        {cart_amount.discount_value >
                                                            0 &&
                                                        cart_amount.coupon_discount >
                                                            0
                                                            ? "Coupon discount " +
                                                              cart_amount.discount_value +
                                                              "% - " +
                                                              Math.ceil(
                                                                  cart_amount.coupon_discount
                                                              )
                                                            : ""}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}

                                                <br />

                                                <p>
                                                    Thank you for your
                                                    patronage. Once you make
                                                    payment, you will receive an
                                                    official communication from
                                                    our team, along with an
                                                    invoice. If you do not
                                                    receive an invoice within 48
                                                    hours of making payment,
                                                    please reach out to
                                                    finance@goocampus.in.
                                                </p>
                                                <br />
                                                <Link to="/refund_policy">
                                                    {" "}
                                                    Refund and cancellation
                                                    policy{" "}
                                                </Link>
                                                <br />
                                            </div>

                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3">
                                                <form
                                                    id="checkout_form"
                                                    onSubmit={
                                                        this.handleCheckOut
                                                    }
                                                    autoComplete="off">
                                                    {/* <input type="hidden" name="plan_id" defaultValue={plan.id} /> */}
                                                    {cart_amount &&
                                                    cart_amount !== "" &&
                                                    cart_amount[
                                                        "actual_price"
                                                    ] > 0 ? (
                                                        <div>
                                                            {cart_amount.discount >
                                                            0 ? (
                                                                <h6 className="small_text">
                                                                    {" "}
                                                                    Actual price
                                                                    :{" "}
                                                                    <span className="actual_price text_blue">
                                                                        <i className="fa fa-rupee"></i>{" "}
                                                                        {
                                                                            cart_amount.actual_price
                                                                        }{" "}
                                                                    </span>
                                                                    <br />
                                                                </h6>
                                                            ) : null}
                                                            {cart_amount.discount >
                                                            0 ? (
                                                                <h6 className="small_text">
                                                                    {" "}
                                                                    Discount :{" "}
                                                                    <i className="fa fa-rupee"></i>{" "}
                                                                    {
                                                                        cart_amount.discount
                                                                    }{" "}
                                                                </h6>
                                                            ) : null}
                                                            <h5>
                                                                {" "}
                                                                Sub total :{" "}
                                                                <i className="fa fa-rupee"></i>{" "}
                                                                {
                                                                    cart_amount.offer_price
                                                                }{" "}
                                                            </h5>
                                                            {cart_amount.discount_value >
                                                                0 &&
                                                            cart_amount.coupon_discount >
                                                                0 ? (
                                                                <h6 className="small_text">
                                                                    {" "}
                                                                    {"Coupon discount " +
                                                                        cart_amount.discount_value +
                                                                        "% - " +
                                                                        Math.ceil(
                                                                            cart_amount.coupon_discount
                                                                        )}
                                                                </h6>
                                                            ) : null}
                                                            {cart_amount.gst >
                                                            0 ? (
                                                                <h6>
                                                                    {" "}
                                                                    GST (18%) :{" "}
                                                                    <i className="fa fa-rupee"></i>{" "}
                                                                    {Math.ceil(
                                                                        cart_amount.gst
                                                                    )}{" "}
                                                                </h6>
                                                            ) : null}
                                                            <h3 className="header">
                                                                {" "}
                                                                Total :{" "}
                                                                <i className="fa fa-rupee"></i>{" "}
                                                                {Math.ceil(
                                                                    cart_amount.total_amount
                                                                )}{" "}
                                                            </h3>

                                                            <input
                                                                type="hidden"
                                                                name="total_amount"
                                                                value={Math.ceil(
                                                                    cart_amount.total_amount
                                                                )}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <h1 className="header">
                                                            {" "}
                                                            Total :{" "}
                                                            <i className="fa fa-rupee"></i>{" "}
                                                            0{" "}
                                                        </h1>
                                                    )}

                                                    <span className="small_text">
                                                        Amount is in Indian
                                                        Rupees - INR, Inclusive
                                                        of all taxes.
                                                    </span>
                                                    <br />

                                                    <div className="text-center p-2">
                                                        {cart_amount &&
                                                        cart_amount !== "" &&
                                                        cart_amount[
                                                            "actual_price"
                                                        ] > 0 ? (
                                                            <button
                                                                type="submit"
                                                                className="abroad_btn mt-3">
                                                                {" "}
                                                                <i className="fa fa-check mr-1"></i>{" "}
                                                                PROCEED TO PAY{" "}
                                                            </button>
                                                        ) : (
                                                            <h6 className="text-blue">
                                                                Select atleast 1
                                                                plan to proceed.
                                                            </h6>
                                                        )}

                                                        <Link to="/pricing">
                                                            <button
                                                                type="button"
                                                                className="abroad_btn cancel_btn mt-3">
                                                                {" "}
                                                                <i className="fa fa-rupee mr-1"></i>{" "}
                                                                BACK TO PRICING{" "}
                                                            </button>
                                                        </Link>

                                                        <br />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    {/* get in touch container */}
                    <div className="row interested_container">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                            <h2 className="main_header">
                                <b> Interested? </b>
                            </h2>
                            <h5 className="text-center mt-3">
                                {" "}
                                Request a free callback from our expert
                                cousellors to know more and get in touch with
                                us.
                            </h5>
                            <div className="mt-3">
                                {is_auth ? (
                                    ""
                                ) : (
                                    <button className="mr-3 mt-2 abroad_btn py-2 show_login">
                                        {" "}
                                        <i className="fa fa-sign-in mr-2"></i>{" "}
                                        SIGN IN / SIGN UP
                                    </button>
                                )}
                                <button
                                    className="mt-2 abroad_btn py-2"
                                    onClick={() =>
                                        this.handleCallback("", "", "", "")
                                    }>
                                    {" "}
                                    <i className="fa fa-phone mr-2"></i> REQUEST
                                    CALLBACK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        benefits: state.list.benefits,
        plan: state.list.member_plan,
        order_id: state.home.order_id,
        cart: state.home.cart
    };
};

const mapActionsToProps = {
    onGetPlanBenefits: getPlanBenefits,
    onGetPlanDetails: getPlanDetails,
    onSetCallbackValues: setCallbackValues,
    onGetCartDetails: getCartDetails,
    onRemoveFromCart: removeFromCart
};

export default connect(mapStatesToProps, mapActionsToProps)(Checkout);
