import React from "react";
import "./Layout.css";
import Routes from "../Routes";
import { connect } from "react-redux";
import { NavLink, HashRouter as Router, Link } from "react-router-dom";
import {
    getAutocompleteColleges,
    hideMessage,
    setAuthUser,
    userLogin,
    userLogout,
    getCartStatus
} from "../../actions/Home";
import { getCollegeStates } from "../../actions/States";
import LoginIcon from "../../assets/login.png";
import CloseButton from "../../assets/close_button.png";
import SetAuthToken from "../../utils/SetAuthToken";
import MaleDoctor from "../../assets/male_doctor.png";
import FemaleDoctor from "../../assets/female_doctor.png";
import Callback from "../Callback";
import Footer from "../Footer";
import $ from "jquery";

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: "" };
    }

    componentWillMount() {
        if (
            localStorage.getItem("auth_user") &&
            localStorage.getItem("auth_token")
        ) {
            SetAuthToken(localStorage.getItem("auth_token"));
            this.props.onSetAuthUser(
                JSON.parse(localStorage.getItem("auth_user"))
            );

            // Get cart status
            this.props.onGetCartStatus();
        }

        // this.props.onGetAutocompleteColleges('');
        this.props.onGetCollegeStates();
    }

    componentDidMount() {
        // Show side login screen
        $(".show_login, .show_profile").on("click", function () {
            $(".login_container")
                .removeClass("d-none")
                .removeClass("slideOutRight")
                .addClass("slideInRight");
        });

        // Hide side login screen
        $(".login_modal_container .menu_close_btn").on("click", function () {
            $(".login_container")
                .removeClass("slideInRight")
                .addClass("slideOutRight");
            // alert(1234);
        });

        // Hide side callback panel
        $(".main_callback_container .menu_close_btn").on("click", function () {
            $(".callback_container")
                .removeClass("slideInLeft")
                .addClass("slideOutLeft");
        });
    }

    clickRegister = () => {
        // Close the login menu by clicking close button and redirect to register page
        $(".login_modal_container .menu_close_btn").trigger("click");
        window.location.hash = "#/register";
    };

    handleUserLogin = e => {
        e.preventDefault();

        var email = document.getElementById("login_email").value;
        var pwd = document.getElementById("login_password").value;

        if (email !== "" && pwd.length >= 8) {
            this.setState({ error: "" });
            var formData = new FormData(document.getElementById("login_form"));
            formData.append("mobile",email);
            formData.append("password",pwd);
            this.props.onUserLogin(formData);
        } else if(email.length<=9  && email.length>10) {
            this.setState({ error: "enter 10 digit number" });
        }else
        {
            this.setState({error:"Invalid Password Or Phone"})
        }
    };

    handleLogOut = () => {
        this.props.onClickLogOut().then(res => {
            window.location.href = "/";
        });
    };

    render() {
        const { message, error, is_auth, auth_user, cart_counter } = this.props;

        return (
            <div className="row">
                <div className="col">
                    {/* All components in routing */}
                    <Routes />

                    {/* Footer component */}
                    <Footer />
                </div>

                {/* Error or message display container */}
                <div className="message_error_container">
                    {message && message !== "" ? (
                        <span className="message_text">
                            {" "}
                            <i className="fa fa-check-circle"></i> {message}{" "}
                        </span>
                    ) : (
                        ""
                    )}
                    {error && error !== "" ? (
                        <span className="error_text">
                            {" "}
                            <i className="fa fa-exclamation-circle"></i> {error}{" "}
                        </span>
                    ) : (
                        ""
                    )}
                </div>

                {/* Login slide menu */}
                <div className="login_modal_container login_container animated slideInRight d-none">
                    <img
                        src={CloseButton}
                        height="30"
                        alt="Close"
                        className="menu_close_btn mt-4 mr-1"
                    />

                    {/* Login and profile sub menu */}
                    {is_auth && auth_user ? (
                        // Display Profile if logged in
                        <div className="">
                            <h6> Hello Welcome back, </h6>
                            <h1>
                                <b> {auth_user.fname} </b>
                            </h1>

                            <img
                                src={
                                    auth_user.gender === "Male"
                                        ? MaleDoctor
                                        : FemaleDoctor
                                }
                                alt="Login"
                                height="140"
                                className="login_icon"
                            />
                            <br />

                            <Router>
                                <NavLink
                                    to={{
                                        pathname: "/college_filters",
                                        search: "?c_favourite=1"
                                    }}
                                    className="abroad_btn user_link"
                                    activeClassName="user_link_active">
                                    {" "}
                                    <i className="fa fa-heart mr-1"></i> My
                                    favourites{" "}
                                </NavLink>
                                <NavLink
                                    to="/change_stream_settings"
                                    className="abroad_btn user_link"
                                    activeClassName="user_link_active">
                                    {" "}
                                    <i className="fa fa-cogs mr-1"></i> Stream
                                    settings{" "}
                                </NavLink>
                                <NavLink
                                    to="/edit_profile"
                                    className="abroad_btn user_link"
                                    activeClassName="user_link_active">
                                    {" "}
                                    <i className="fa fa-user-md mr-1"></i> Edit
                                    profile{" "}
                                </NavLink>
                                <NavLink
                                    to="/change_password"
                                    className="abroad_btn user_link"
                                    activeClassName="user_link_active">
                                    {" "}
                                    <i className="fa fa-lock mr-1"></i> Change
                                    password{" "}
                                </NavLink>
                                <button
                                    className="abroad_btn user_link bg-danger"
                                    onClick={this.handleLogOut}>
                                    <b>
                                        {" "}
                                        LOGOUT{" "}
                                        <i className="fa fa-sign-out"></i>
                                    </b>
                                </button>
                            </Router>
                        </div>
                    ) : (
                        // Display login if not logged in
                        <div className="">
                            <h6> Hello Welcome back, </h6>

                            <h2> SIGN IN HERE </h2>

                            <img
                                src={LoginIcon}
                                alt="Login"
                                height="160"
                                className="login_icon"
                                style={{ padding: "20px" }}
                            />

                            <form
                                className="mt-3"
                                id="login_form"
                                onSubmit={this.handleUserLogin}
                                autoComplete="off">
                                <div className="form-group input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            {" "}
                                            <i className="fa fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input
                                        type="number"
                                        name="email"
                                        id="login_email"
                                        className="form-control"
                                        placeholder="Mobile Number"
                                    />
                                </div>

                                <div className="form-group input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            {" "}
                                            <i className="fa fa-lock"></i>
                                        </span>
                                    </div>
                                    <input
                                        type="password"
                                        name="password"
                                        id="login_password"
                                        className="form-control"
                                        placeholder="Password"
                                    />
                                </div>

                                <div className="mt-1 text-center">
                                    <span className="small_text text-light">
                                        <b>
                                            {" "}
                                            {this.state.error +
                                                " " +
                                                error +
                                                " " +
                                                message}{" "}
                                        </b>
                                    </span>
                                </div>

                                <div className="form-group text-center mt-4">
                                    <button
                                        type="submit"
                                        className="login_btn py-2 px-4">
                                        {" "}
                                        LOGIN{" "}
                                        <i className="fa fa-sign-in ml-1"></i>{" "}
                                    </button>
                                    <br />
                                    <br />
                                    <Router>
                                        <NavLink to="/forgot_password">
                                            <span className="text-light">
                                                {" "}
                                                Forgot password ?{" "}
                                            </span>
                                        </NavLink>
                                    </Router>
                                </div>
                            </form>

                            <div className="form-group text-center">
                                <button
                                    className="login_btn"
                                    title="Register"
                                    onClick={this.clickRegister}>
                                    {" "}
                                    REGISTER FOR FREE{" "}
                                    <i className="fa fa-user-plus ml-1"></i>
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {/* Login slide menu */}
                <div className="main_callback_container callback_container animated slideInLeft d-none">
                    <img
                        src={CloseButton}
                        height="30"
                        alt="Close"
                        className="menu_close_btn mt-4 mr-1"
                    />

                    {/* Callback component with set values */}
                    <Callback />
                </div>

                {is_auth && auth_user ? (
                    <Router>
                        <Link to="/checkout">
                            <div className="text-center mt-5 cart_icon">
                                <i className="fa fa-shopping-cart"></i>
                                <h6 className="cart_counter">{cart_counter}</h6>
                            </div>
                        </Link>
                    </Router>
                ) : null}
            </div>
        );
    }

    componentDidUpdate() {
        // Hide the error or messages
        if (this.props.error !== "" || this.props.message !== "") {
            setTimeout(
                function () {
                    this.props.onHideMessage();
                }.bind(this),
                2400
            );
        }
    }
}

const mapStatesToProps = state => {
    return {
        main_loader: state.home.main_loader,
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        is_login: state.home.is_login,
        error: state.home.error,
        message: state.home.message,
        cart_counter: state.home.cart_counter
    };
};

const mapActionsToProps = {
    onGetAutocompleteColleges: getAutocompleteColleges,
    onHideMessage: hideMessage,
    onUserLogin: userLogin,
    onSetAuthUser: setAuthUser,
    onClickLogOut: userLogout,
    onGetCollegeStates: getCollegeStates,
    onGetCartStatus: getCartStatus
};

export default connect(mapStatesToProps, mapActionsToProps)(Layout);
