import React from "react";
import "./Courses.css";

const BAMSCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>
                                Bachelor of Ayurvedic Medicine and Surgery
                                (BAMS){" "}
                            </b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            B.A.M.S. or the Bachelor of Ayurvedic Medicine &
                            Surgery is an undergraduate course in the field of
                            Ayurveda. The course lasts for 5 and a half years
                            and covers the entire gamut of modern medicine and
                            age-old traditional Ayurvedic techniques. Candidates
                            are also required to complete an year of internship
                            which is part of the course duration.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <ul className="courses_list">
                            <li>
                                Candidates need to have completed their 10+2 or
                                equivalent examination with Physics, Chemistry
                                and Biology as subjects.
                            </li>
                            <li>
                                Although knowledge of Sanskrit is not mandatory,
                                it is beneficial to have a basic understanding.
                            </li>
                            <li>
                                Candidates need to qualify the common entrance
                                exam (NEET-UG) with a high enough score to
                                become eligible to apply for colleges that offer
                                B.A.M.S. courses.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Syllabus</h4>
                        <table className="table table-bordered table-responsive table-striped">
                            <tbody>
                                <tr>
                                    <td rowspan="5">
                                        First Professional (one and half year)
                                    </td>
                                    <td>History of Ayurveda</td>
                                </tr>
                                <tr>
                                    <td>Sanskrit and Samhita</td>
                                </tr>
                                <tr>
                                    <td>
                                        PadarthaVigyana (Ayurvedic philosophy)
                                    </td>
                                </tr>
                                <tr>
                                    <td>RachnaSharira (anatomy)</td>
                                </tr>
                                <tr>
                                    <td>KriyaSharira (physiology)</td>
                                </tr>
                                <tr>
                                    <td rowspan="6">
                                        Second Professional (one and half year)
                                    </td>
                                    <td>
                                        Rasa ShastraavamBhaisajyaKalpana
                                        (Pharmaceuticals of Ayurveda)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dravyaguna (MateriaMedica of Ayurveda)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Agadtantra, VyavaharAyurveda and
                                        VidhiVaidyaka (Toxicology and
                                        Jurisprudence)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Nidan/VikritiVigyana (Pathology)
                                        /NadiParkisa (Pulse diagnosis)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Svasthavritta Yoga (Personal and social
                                        hygiene including dietetics)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        CharakaSamhita (A classical text of
                                        Ayurveda)
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="9">
                                        Final Professional (one and half year)
                                    </td>
                                    <td>
                                        Kaya Chikitsa (General Medicine
                                        including Panchakarma, Rasayana and
                                        Vajikarana)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Shalyatantra (General surgery and
                                        parasurgical techniques)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Shalakyatantra (ENT, Eye and Dentistry)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        PrasutitantraavamStriroga (Gynaecology
                                        and Obstetrics)
                                    </td>
                                </tr>
                                <tr>
                                    <td>Kaumarabhritya (Paediatrics)</td>
                                </tr>
                                <tr>
                                    <td>Medical Ethics</td>
                                </tr>
                                <tr>
                                    <td>Health Regulations</td>
                                </tr>
                                <tr>
                                    <td>Yoga</td>
                                </tr>
                                <tr>
                                    <td>Dissertation</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course suitability</h4>
                        <ul className="courses_list">
                            <li>
                                Candidates who are interested in pursuing a
                                Master’s Degree & those who wish to do research
                                in Ayurveda can apply for this course.
                            </li>
                            <li>
                                It is advantageous for candidates to possess
                                excellent communication & interpersonal skills,
                                this allows them to make critical decisions
                                after weighing in all the diagnosis.
                            </li>
                            <li>
                                Responsibility, self confidence, and
                                concentration are the key traits a candidate
                                must possess.
                            </li>
                            <li>
                                The candidate would have to be compassionate in
                                nature.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BAMSCourse;
