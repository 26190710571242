import { GET_STATES_SUCCESS, GET_COLLEGE_STATES } from "../actions/States";

const initialState = {
    states: [],
    college_states: [],
    page_size: 10,
    page_count: 0,
    total_count: 0
};

const State_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_STATES_SUCCESS:
            return Object.assign({}, state, {
                states: payload.states,
                page_size: payload.page_size,
                page_count: payload.page_count,
                total_count: payload.total_count
            });

        case GET_COLLEGE_STATES:
            return Object.assign({}, state, {
                college_states: payload.college_states
            });

        default:
            return state;
    }
};

export default State_reducer;
