import React from "react";
import "./EditProfile.css";
import { connect } from "react-redux";
import swal from "sweetalert";
import { updateProfile } from "../../actions/Home";
import MaleDoctor from "../../assets/male_doctor.png";
import FemaleDoctor from "../../assets/female_doctor.png";

class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = { user: this.props.auth_user };
        this.handleEditProfile = this.handleEditProfile.bind(this);
    }

    componentWillMount() {
        if (!this.props.is_auth || !this.props.auth_user) {
            window.location.hash = "#/";
        }
    }

    handleEditProfile = e => {
        const { name, value } = e.target;
        const { user } = this.state;
        user[name] = value;
        this.setState({ user: user });
    };

    handleUpdateProfile = e => {
        e.preventDefault();

        swal({
            title: "Are you sure?",
            text: "To update your profile?",
            buttons: ["Cancel", "Update"]
        }).then(willRequest => {
            if (willRequest) {
                var formData = new FormData(
                    document.getElementById("update_profile_form")
                );
                this.props.onUpdateProfile(formData);
            }
        });
    };

    render() {
        const { auth_user, states } = this.props;

        return (
            <div className="row mt-4 pt-4">
                <div className="col">
                    <div className="edit_profile_container text-center mt-5">
                        <h2 className="sub_header mb-4">
                            <i className="fa fa-user-md mr-1"></i> EDIT PROFILE{" "}
                        </h2>
                        <div className="row">
                            <div className="col"></div>

                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="edit_profile">
                                    <form
                                        className="mt-3"
                                        id="update_profile_form"
                                        onSubmit={this.handleUpdateProfile}>
                                        <div className="form-group my-2">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    className="form-control"
                                                    defaultValue="Male"
                                                    onChange={
                                                        this.handleEditProfile
                                                    }
                                                    defaultChecked={
                                                        auth_user.gender ==
                                                        "Male"
                                                            ? true
                                                            : false
                                                    }
                                                />{" "}
                                                <img
                                                    src={MaleDoctor}
                                                    alt="Doctor"
                                                    height="80"
                                                />
                                            </label>
                                            <span className="mx-3">or</span>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    className="form-control"
                                                    defaultValue="Female"
                                                    onChange={
                                                        this.handleEditProfile
                                                    }
                                                    defaultChecked={
                                                        auth_user.gender ==
                                                        "Female"
                                                            ? true
                                                            : false
                                                    }
                                                />{" "}
                                                <img
                                                    src={FemaleDoctor}
                                                    alt="Doctor"
                                                    height="80"
                                                />
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        First name * :{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="fname"
                                                        className="form-control text-capitalize"
                                                        placeholder="First name *"
                                                        value={
                                                            auth_user.fname
                                                                ? auth_user.fname
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleEditProfile
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        Last name * :{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="lname"
                                                        className="form-control text-capitalize"
                                                        placeholder="Last name *"
                                                        value={
                                                            auth_user.lname
                                                                ? auth_user.lname
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleEditProfile
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        Email ID * :{" "}
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Email ID *"
                                                        value={
                                                            auth_user.email
                                                                ? auth_user.email
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleEditProfile
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        Mobile number * :{" "}
                                                    </label>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        name="mobile"
                                                        className="form-control"
                                                        placeholder="Mobile Number *"
                                                        value={
                                                            auth_user.mobile
                                                                ? auth_user.mobile
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleEditProfile
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        Native state :{" "}
                                                    </label>
                                                    <select
                                                        name="state"
                                                        className="form-control"
                                                        value={
                                                            auth_user.state
                                                                ? auth_user.state
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleEditProfile
                                                        }>
                                                        <option value="">
                                                            {" "}
                                                            Native state{" "}
                                                        </option>
                                                        {states &&
                                                        states.length > 0
                                                            ? states.map(
                                                                  state => {
                                                                      return (
                                                                          <option
                                                                              key={
                                                                                  state.id
                                                                              }
                                                                              value={
                                                                                  state.id
                                                                              }>
                                                                              {
                                                                                  state.state_name
                                                                              }
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : ""}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        Native city :{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        className="form-control"
                                                        placeholder="Enter your city"
                                                        value={
                                                            auth_user.city
                                                                ? auth_user.city
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleEditProfile
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mt-4">
                                            <button
                                                type="submit"
                                                className="abroad_btn">
                                                {" "}
                                                <i className="fa fa-check"></i>{" "}
                                                UPDATE PROFILE{" "}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        states: state.state.college_states
    };
};

const mapActionsToProps = {
    onUpdateProfile: updateProfile
};

export default connect(mapStatesToProps, mapActionsToProps)(EditProfile);
