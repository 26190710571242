import React from "react";
import "../AboutUs/AboutUs.css";
import { connect } from "react-redux";
import $ from "jquery";
import { setCallbackValues } from "../../actions/Callbacks";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    handleCallback = (state, college, stream, level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, level);
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { is_auth } = this.props;

        return (
            <div className="row">
                <div className="col about_us_container mt-3">
                    <h3 className="text-center text-orange text-uppercase">
                        {" "}
                        Privacy policy{" "}
                    </h3>

                    <div className="row description_card">
                        <div className="col">
                            <p className="para">
                                The terms "We" / "Us" / "Our" / "Company"
                                individually and collectively refer to GooCampus
                                Edu Solutions Private Limited and the terms
                                "Visitor" and "User" refer to the users.
                            </p>
                            <p className="para">
                                This Privacy Policy is an electronic record in
                                the form of an electronic contract formed under
                                the information Technology Act, 2000 and the
                                rules made thereunder and the amended provisions
                                pertaining to electronic documents / records in
                                various statutes as amended by the information
                                Technology Act, 2000. This Privacy Policy does
                                not require any physical, electronic or digital
                                signature.
                            </p>
                            <p className="para">
                                This Privacy Policy is a legally binding
                                document between you and GooCampus Edu Solutions
                                Private Limited (both terms defined below). The
                                terms of this Privacy Policy will be effective
                                upon your acceptance of the same (directly or
                                indirectly in electronic form, by clicking on
                                the I accept tab or by use of the website or by
                                other means) and will govern the relationship
                                between you and GooCampus Edu Solutions Private
                                Limited for your use of the website “Website”
                                (defined below).
                            </p>
                            <p className="para">
                                This document is published and shall be
                                construed in accordance with the provisions of
                                the Information Technology (reasonable security
                                practices and procedures and sensitive personal
                                data of information) rules, 2011 under
                                Information Technology Act, 2000; that require
                                publishing of the Privacy Policy for collection,
                                use, storage and transfer of sensitive personal
                                data or information.
                            </p>
                            <p className="para">
                                Please read this Privacy Policy carefully by
                                using the Website, you indicate that you
                                understand, agree and consent to this Privacy
                                Policy. If you do not agree with the terms of
                                this Privacy Policy, please do not use this
                                Website.
                            </p>
                            <p className="para">
                                By providing us your Information or by making
                                use of the facilities provided by the Website,
                                You hereby consent to the collection, storage,
                                processing and transfer of any or all of Your
                                Personal Information and Non-Personal
                                Information by us as specified under this
                                Privacy Policy. You further agree that such
                                collection, use, storage and transfer of Your
                                Information shall not cause any loss or wrongful
                                gain to you or any other person.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header">User information </h4>
                            <p className="para">
                                To avail certain services on our Websites, users
                                are required to provide certain information for
                                the registration process namely: - 1) your name,
                                2) email address, 3) sex, 4) age, 5) PIN code,
                                6) credit card or debit card details 7) sexual
                                orientation, 8) academic information etc., and /
                                or your occupation, interests, and the like. The
                                Information as supplied by the users enables us
                                to improve our sites and provide you the most
                                user- friendly experience.
                            </p>
                            <p className="para">
                                All required information is service dependent
                                and we may use the above said user information
                                to, maintain, protect, and improve its services
                                (including advertising services) and for
                                developing new services.
                            </p>
                            <p className="para">
                                Such information will not be considered as
                                sensitive if it is freely available and
                                accessible in the public domain or is furnished
                                under the Right to Information Act, 2005 or any
                                other law for the time being in force.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Cookies</h4>
                            <p className="para">
                                To improve the responsiveness of the sites for
                                our users, we may use "cookies", or similar
                                electronic tools to collect information to
                                assign each visitor a unique, random number as a
                                User Identification (User ID) to understand the
                                user's individual interests using the Identified
                                Computer. Unless you voluntarily identify
                                yourself (through registration, for example), we
                                will have no way of knowing who you are, even if
                                we assign a cookie to your computer. The only
                                personal information a cookie can contain is
                                information you supply (an example of this is
                                when you use our College Finder feature). A
                                cookie cannot read data off your hard drive. Our
                                advertisers may also assign their own cookies to
                                your browser (if you click on their ads), a
                                process that we do not control.
                            </p>
                            <p className="para">
                                Our web servers automatically collect limited
                                information about your computer's connection to
                                the Internet, including your IP address, when
                                you visit our site. (Your IP address is a number
                                that lets computers attached to the Internet
                                know where to send you data -- such as the web
                                pages you view.) Your IP address does not
                                identify you personally. We use this information
                                to deliver our web pages to you upon request, to
                                tailor our site to the interests of our users,
                                to measure traffic within our site and let
                                advertisers know the geographic locations from
                                where our visitors come.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Links to other sites </h4>
                            <p className="para">
                                Our policy discloses the privacy practices for
                                our own web site only. Our site provides links
                                to other websites also that are beyond our
                                control. We shall in no way be responsible in
                                way for your use of such sites.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Information sharing </h4>
                            <p className="para">
                                We share the sensitive personal information to
                                any third party without obtaining the prior
                                consent of the user in the following limited
                                circumstances:
                            </p>
                            <p className="para">
                                When it is requested or required by law or by
                                any court or governmental agency or authority to
                                disclose, for the purpose of verification of
                                identity, or for the prevention, detection,
                                investigation including cyber incidents, or for
                                prosecution and punishment of offences. These
                                disclosures are made in good faith and belief
                                that such disclosure is reasonably necessary for
                                enforcing these Terms; for complying with the
                                applicable laws and regulations.
                            </p>
                            <p className="para">
                                We proposes to share such information within its
                                group companies and officers and employees of
                                such group companies for the purpose of
                                processing personal information on its behalf.
                                We also ensure that these recipients of such
                                information agree to process such information
                                based on our instructions and in compliance with
                                this Privacy Policy and any other appropriate
                                confidentiality and security measures.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Information security </h4>
                            <p className="para">
                                We take appropriate security measures to protect
                                against unauthorized access to or unauthorized
                                alteration, disclosure or destruction of data.
                                These include internal reviews of our data
                                collection, storage and processing practices and
                                security measures, including appropriate
                                encryption and physical security measures to
                                guard against unauthorized access to systems
                                where we store personal data.
                            </p>
                            <p className="para">
                                All information gathered on our Website is
                                securely stored within our controlled database.
                                The database is stored on servers secured behind
                                a firewall; access to the servers is
                                password-protected and is strictly limited.
                                However, as effective as our security measures
                                are, no security system is impenetrable. We
                                cannot guarantee the security of our database,
                                nor can we guarantee that information you supply
                                will not be intercepted while being transmitted
                                to us over the Internet. And, of course, any
                                information you include in a posting to the
                                discussion areas is available to anyone with
                                Internet access.
                            </p>
                            <p className="para">
                                However the internet is an ever evolving medium.
                                We may change our Privacy Policy from time to
                                time to incorporate necessary future changes. Of
                                course, our use of any information we gather
                                will always be consistent with the policy under
                                which the information was collected, regardless
                                of what the new policy may be.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Grievance redressal </h4>
                            <p className="para">
                                Redressal Mechanism: Any complaints, abuse or
                                concerns with regards to content and or comment
                                or breach of these terms shall be immediately
                                informed to the designated Grievance Officer as
                                mentioned below via in writing or through email
                                signed with the electronic signature to Maheen
                                Ejaz ("Grievance Officer").
                            </p>

                            <h6>
                                <strong>
                                    {" "}
                                    Mr. Maheen Ejaz (Grievance Officer){" "}
                                </strong>
                            </h6>
                            <p className="para">
                                {" "}
                                GooCampus Edu Solutions Pvt Ltd. <br /> # 138/6,
                                Ground Floor, 10th Main Road,
                                <br /> 6th A Cross, Sadashiv Nagar, <br /> RMV
                                Extension, Behind SBI Bank <br /> Bengaluru,
                                Karnataka - 560080 <br /> Landmark: Next to
                                Chevron Orchards <br />
                                <br /> Phone : 080 41743956 <br /> Email:
                                info@goocampus.in <br />
                            </p>
                        </div>
                    </div>

                    {/* get in touch container */}
                    <div className="row interested_container">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                            <h2 className="main_header">
                                <b> Interested? </b>
                            </h2>
                            <h5 className="text-center mt-3">
                                {" "}
                                Request a free callback from our expert
                                cousellors to know more and get in touch with
                                us.
                            </h5>
                            <div className="mt-3">
                                {/* {is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2" onClick={this.handleLoginClick}> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>} */}
                                <button
                                    className="mt-2 abroad_btn py-2"
                                    onClick={() =>
                                        this.handleCallback("", "", "", "")
                                    }>
                                    {" "}
                                    <i className="fa fa-phone mr-2"></i> REQUEST
                                    CALLBACK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth
    };
};

const mapActionsToProps = {
    onSetCallbackValues: setCallbackValues
};

export default connect(mapStatesToProps, mapActionsToProps)(PrivacyPolicy);
