import server from "../utils/Server";
import { subLoaderTrue, subLoaderFalse, displayError } from "./Home";

export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_DETAILS = "GET_NEWS_DETAILS";

export const getFilterNewsUpdates = filters => {
    
    return dispatch => {
        dispatch(subLoaderTrue());
        return server
            .get("/getnewsall" + filters)
            .then(response => {
                const { data } = response;

                dispatch(subLoaderFalse());
                if (data.code === 200)
                    dispatch(getFilterNewsUpdatesSuccess(data));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(subLoaderFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const getNewsDetails = id => {
    return dispatch => {
        dispatch(subLoaderTrue());
        return server
            .get("/get_news_details/" + id)
            .then(response => {
                const { data } = response;
                dispatch(subLoaderFalse());
                if (data.code === 200) dispatch(getNewsDetailsSuccess(data));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(subLoaderFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const getFilterNewsUpdatesSuccess = data => {
    return {
        type: GET_NEWS_SUCCESS,
        payload: {
            news_updates: data.news_updates,
            total_count: data.total_count,
            page_count: data.page_count,
            page_size: data.page_size,
            categories: data.categories,
            selected_categories: data.selected_categories
        }
    };
};

export const getNewsDetailsSuccess = data => {
    return {
        type: GET_NEWS_DETAILS,
        payload: {
            news: data.news,
            related_news: data.related_news
        }
    };
};
