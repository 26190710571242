import server from "../utils/Server";
import {
    mainLoadingTrue,
    mainLoadingFalse,
    displayError,
    displayMessage
} from "./Home";

export const GET_CUTOFF_SETTINGS_SUCCESS = "GET_CUTOFF_SETTINGS_SUCCESS";
export const GET_CUTOFFS_SUCCESS = "GET_CUTOFFS_SUCCESS";

// Get cutoff settings
export const getCutoffSettings = filters => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .get("/get_cutoff_settings?" + filters)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) dispatch(getCutoffSettingsSuccess(data));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Get settings action
export const getCutoffSettingsSuccess = data => {
    return {
        type: GET_CUTOFF_SETTINGS_SUCCESS,
        payload: {
            courses: data.courses,
            authorities: data.authorities,
            quotas: data.quotas,
            categories: data.categories,
            rounds: data.rounds,
            selected_settings: data.selected_settings
        }
    };
};

// Update cutoff settings
export const updateCutoffSettings = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/update_cutoff_settings", data)
            .then(response => {
                dispatch(mainLoadingFalse());
                let data = response.data;
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                } else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Get course cutoffs
export const getCourseCutoffs = filters => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .get("/get_course_cutoffs?" + filters)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) dispatch(getCutoffsSuccess(data));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Get cutoffs action
export const getCutoffsSuccess = data => {
    return {
        type: GET_CUTOFFS_SUCCESS,
        payload: {
            cutoffs: data.cutoffs,
            selected_settings: data.selected_settings
        }
    };
};
