import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Autocomplete from "../Autocomplete/HomeSearchAutocomplete";
import Rated from '../../assets/Rated.png';

import { connect } from "react-redux";
import {
    getAutocompleteColleges,
    getHomeDetails,
    addRemoveFavourite
} from "../../actions/Home";
import $ from "jquery";
import { setCallbackValues } from "../../actions/Callbacks";
import Achivements from '../../assets/acheivements.gif';
import SearchImage from "../../assets/search.png";
import NeetUgCounselling from '../../assets/neet-ug-link.png';
import NeetPgCounselling from '../../assets/1.png';
import calendarIcon from '../../assets/calendaricon.png';
import personalcounselling from '../../assets/kg.png';
import Image1 from '../../assets/slider4.jpeg';
import Image2 from '../../assets/slider3.jpeg';
import Image3 from '../../assets/slider5.jpeg';
import Image4 from '../../assets/slider2.jpeg';
import slidericon13 from '../../assets/icons_GCIndia-13.png';
import slidericon2 from '../../assets/icons_GCIndia-02.png';
import slidericon3 from '../../assets/icons_GCIndia-03.png';
import slidericon4 from '../../assets/icons_GCIndia-04.png';
import slidericon5 from '../../assets/icons_GCIndia-05.png';
import slidericon6 from '../../assets/icons_GCIndia-06.png';
import slidericon7 from '../../assets/icons_GCIndia-07.png';
import slidericon8 from '../../assets/icons_GCIndia-08.png';
import slidericon9 from '../../assets/icons_GCIndia-09.png';
import slidericon10 from '../../assets/icons_GCIndia-10.png';
import slidericon11 from '../../assets/icons_GCIndia-11.png';
import slidericon12 from '../../assets/icons_GCIndia-12.png';
import usaimg from '../../assets/usa.png';
import australia  from '../../assets/australia.png';
import uk from '../../assets/uk.png';
import mapimage from '../../assets/Mapofindia.png';
import Icon1 from'../../assets/icon1.png';
import Icon2 from'../../assets/icon2.png';
import Icon3 from'../../assets/icon3.png';
import Icon4 from'../../assets/icon4.png';
import Slider1 from '../../assets/Slider1.jpg';
import Slider2 from '../../assets/slider2.png';
import Clients from '../../assets/Clients.png';
import RatedGif from '../../assets/Clients.gif';
import ServiceImage1 from '../../assets/ServiceImage1.png';
import ServiceImage2 from '../../assets/ServiceImage2.png';
import ServiceImage3 from '../../assets/ServiceImage3.png';
import ServiceImage4 from '../../assets/ServiceImage4.png';
import ServiceImage5 from '../../assets/ServiceImage5.png';
import collegpredictorimg from '../../assets/college_predictor.png';
import Calendar from '../../assets/Calender.png';
import RankPredictor from '../../assets/Rank_predictor.png';
import RankPredictorImage from "../../assets/rank_predictor_background_image.png";
import maleDoctor from '../../assets/unisexdoctor.jpg';
import NewsIcon from "../../assets/news.png";
import CalendarIcon from "../../assets/event_calendar.png";
import ComapareIcon from "../../assets/compare.png";
import CollegesIcon from "../../assets/colleges.png";
import CutoffIcon from "../../assets/cutoff.png";
import VideoIcon from "../../assets/videos.png";
import AbroadIcon from "../../assets/abroad.png";
import UKIcon from "../../assets/uk.png";
import ReviewsIcon from "../../assets/review.png";
import AppScreen from "../../assets/app_screen.png";
import PlayStore from "../../assets/play_store.png";
import AppStore from "../../assets/app_store.png";
import QRCode from "../../assets/qr_code.png";
import ukcarrerfair from '../../assets/ukcareerfair.png';
import BGimages from "../../assets/goocampus_bg_nw.png";
import YouTube from "react-youtube";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { size } from "lodash";
import { getFilterNewsUpdates } from "../../actions/NewsUpdates";



class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display_state: "Karnataka",
            callback_college: "",
            callback_state: "",
            popular_states_display: "",
            modalState: true
        };
      

        this.handleShow =this.handleShow.bind(this);
        this.changeStateHandler = this.changeStateHandler.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.displayState = this.displayState.bind(this);
    }
    handleShow() {
        
        this.setState({ modalState: !this.state.modalState });
       
}

    
    componentWillMount = () => {
        this.props.onGetHomeDetails();
        this.props.onGetFilterNewsUpdates("");
    };

    componentDidMount = () => {
        setInterval(this.displayState, 2400);
       
    };

    displayState = () => {
        let states = this.props.college_states;

        if (states && states.length > 0) {
            // Fetching random states to display
            let randIndex = Math.floor(Math.random() * states.length);

            this.setState({ display_state: states[randIndex].state_name });
        }
    };

    changeStateHandler = e => {
        if (e.target.name === "c_state") {
            this.props.onGetAutocompleteColleges("?state_id=" + e.target.value);
        }
    };
    changeFilters = e => {
        this.setState({ active_page: 1 });
        var filtersData = $("#college_filters_form").serialize();
        var pageData = $("#college_pagination_form").serialize();
        var streams_data = "",
            levels_data = "",
            fmge_data = "";

        $(".streams_check:checked").each(function (index) {
            streams_data +=
                index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        $(".levels_check:checked").each(function (index) {
            levels_data += index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        $(".fmge_check:checked").each(function (index) {
            fmge_data += index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        pageData +=
            "&c_page_no=1&c_streams=" +
            streams_data +
            "&c_levels=" +
            levels_data +
            "&c_fmge=" +
            fmge_data;
        this.props.onGetFilterColleges("?" + filtersData + "&" + pageData);
    };

    
    submitSearchHandler = e => {
        var params = $("#college_search_form").serialize();
        this.props.history.push({
            pathname: "/college_filters",
            search: "?" + params
        });
    };

    handleCallback = (state, college, stream, course_level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, course_level);
    };
   
    handleFavouriteCollege = college_id => {
        if (this.props.is_auth && this.props.auth_user) {
            this.props.onAddRemoveFavourite(college_id);
            $("#fav_icon_" + college_id)
                .toggleClass("fa-heart-o")
                .toggleClass("fa-heart");
        } else {
            // Show login
            $(".login_container")
                .removeClass("d-none")
                .removeClass("slideOutRight")
                .addClass("slideInRight");
        }
    };

    
    render() {
        
        const {
            autocomplete_colleges,
            college_states,
            popular_colleges,
            favourite_colleges,
            is_auth,
            auth_user,
            img_url,
            news_updates,
        } = this.props;
        console.log(news_updates)
    let news=[];
    for(var i=0;i<news_updates.length;i++)
    {
        
        if(i<4){
        news.push(<div className="ml-5" >
                                <h5 className="text-left text-dark"><b style={{fontWeight:"bold"}}>{news_updates[i].news_title}</b></h5>
                                  <p className="text-left text-dark">{news_updates[i].brief_descr}</p>
            
                                
                                 <Link  to={
                                                        "/news_details/" +
                                                        news_updates[i].id
                                                    } href="#/news_updates" role="button" className="btn bg-orange text-white float-left news-btn">Read More</Link>
            
                               
        </div>)
    }
   
    }
         console.log(news)
        const { display_state } = this.state;
        const opts = {
            height: "300",
            width: "100%",
            playerVars: {
                autoplay: 0
            }
        };

        return (
            <div className="home_container pt-5" id="home_container" >
                          {/* <div className={"modal fadeIn" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                
                                <button type="button" className="close" onClick={this.handleShow}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <img src={ukcarrerfair}  width="100%" />
                            </div>
                            <div className="modal-footer" style={{justifyContent:"center"}}>
                                <a href="https://goocampusacademy.com/" role="button" className="btn btn-primary" style={{backgroundColor:"#fe904c"}} >Read More</a>
                               
                            </div>
                        </div>
                    </div>
                </div> */}
                         <Carousel  autoPlay={true} swipeable={true} showThumbs={false} showStatus ={false} interval={5000} infiniteLoop={true}>
                            <a  href="#" > 
                              <div>
                                  <img src={Slider1} alt="NeetPgCounselling" width="100%" />
                                  <div className="slider-content text-left">
                                    <h3 className="text-white sliderheading">Welcome to GooCampus!</h3>
                                    <p className="text-white slidercontent">We guide medical aspirants through the whole NEET counseling process till the seat allotment</p>
                                    <br />
                                   <span className="slider-button"><i href="https://bit.ly/NEET_Consult" target="_blank" className="fa fa-phone bg-orange py-3 px-3 rounded-circle text-light slider-icon" style={{fontSize:"25px",position:"relative",left:"4%",top:"1%"}}></i> <a href="https://bit.ly/NEET_Consult"  target="_blank"  className="btn more-btn mb-3" style={{borderRadius:"20px",background:"#ffffff33",color:"white"}} role="button">  Callback </a></span>
                                  </div> 
                              </div>
                            </a>


                            {/* <a href="https://tinyurl.com/NEET-UG-Course" target="_blank"> 
                             <div>
                                <img src={Slider2} alt="NeetUgCounselling" width="100%" />
                   
                                </div>
                             </a> */}
                             {/* <a href="https://www.goocampus.in/#/pricing" target="_blank"> 
                                <div>
                                    <img src={Image4} alt="Neet Ug Counselling Assistance " width="100%"  />
                                </div>
                             </a>
                            <a href="https://www.goocampus.in/#/pricing" target="_blank">
                            <div>
                                <img src={Image1} alt="Neet Ug Counselling Assistance " width="100%"  />
                   
                            </div>
                            </a> */}
            </Carousel>
     <div className="row home_service_container" style={{padding:"0px 2%"}}>
        <div className="col-md-4">
            <div className="card pt-2 card-main" style={{border:"1px solid #f47523"}}>
                <div className="card-body text-center px-3">
                    <br />
                <h3 className="text-white"><b>Why GooCampus?</b></h3>
                <br/>
                    <p className="text-white px-4" style={{fontSize:"1.2rem",textAlign:"justify"}}>GooCampus is a pioneer in the field of medical career guidance. We specialize in both under-graduate & post-graduate medicine & surgery courses in <b>India</b>, UK, USA and Australia.</p>
                    <a href="#/pricing" className="btn more-btn" style={{borderRadius:"20px",background:"#ffffff33",color:"white"}} role="button">Services &gt; </a>
                </div>
            </div>
        </div>
        <div className="col-md-8">
        <div className="row sub-card">
        <div className="col-md-4">
         <div className="card pt-3 mx-1 card-height"  style={{borderRadius:"10px",border:"1px solid #f47523"}} >
                <div class="card-body text-center mb-3">
                    <a href="#/rank_predictor"><img src={RankPredictor} width="50px" height="50px" alt="Rank Predictor"/>
                    <br />
                    <br />
                    <h5 className="text-dark"><b>Rank Predictor</b></h5>
                   
                    <p className="text-dark" style={{fontSize:"12px"}}>Find out your estimated rank for the entrance NEET exam by using the GooCampus NEET Rank Predictor tool.</p>
                    </a>
                   
                </div>
         </div>
        </div>
        <div className="col-md-4">
        <div className="card pt-3 mx-1 card-height" style={{borderRadius:"10px",border:"1px solid #f47523"}} >
               <a href="#/pricing"> <div className="card-body text-center">
                    <img src={personalcounselling} width="50px" height="50px" alt="College Predictor" />
                    <br />
                    <br />
                    <h5><b className="text-dark">Personalized Counselling</b></h5>
                    <p className="text-dark" style={{fontSize:"12px"}}>One to One counseling session to handhold you throughout the NEET counseling process, until the seat allotment.
                        
                    </p>
                   
                </div>
                </a>
        </div>
        </div>
        <div className="col-md-4">
        <div className="card pt-3 mx-1 card-height" style={{borderRadius:"10px",border:"1px solid #f47523"}}>
        <a href="#/calendar">  <div className="card-body text-center">
                <img src={Calendar} width="50px" height="50px" alt="Calender"/>
                    <br />
                    <br />
                    <h5 className="text-dark"><b>Calendar</b></h5>
                    <p className="text-dark" style={{fontSize:"12px"}}>Get important last dates and counseling process notifications and just tap a button to be reminded.</p>
                    
                </div>
                </a>
            </div>
        </div>
        
        </div>
        </div>
     </div>
              
  <div  id="demo" className="carousel slide" data-ride="carousel">
  
  <ul className="carousel-indicators">
    <li data-target="#demo" data-slide-to={0} className="active" />
    <li data-target="#demo" data-slide-to={1} />
    <li data-target="#demo" data-slide-to={2} />
    <li data-target="#demo" data-slide-to={3} />
   
  </ul>
  <div className="carousel-inner">
    <div className="carousel-item active">
        
    <div className="row">
            <div className="col-md-2 text-center">
                <img src={slidericon5} style={{width:"50px",height:"50px"}}/>
                <br/>
                Pre-Counselling<br /> Information</div>  
            <div className="col-md-2 text-center">
            <img src={slidericon2} style={{width:"50px",height:"50px"}}/>
                <br/>
                Notification <br />Updates</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon3} style={{width:"50px",height:"50px"}}/>
                <br/>
                Deadline <br />Reminders</div>               
            <div className="col-md-2 text-center">
            <img src={slidericon6} style={{width:"50px",height:"50px"}}/>
                <br/>
                Admission <br />Counseling</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon12} style={{width:"50px",height:"50px"}}/>
                <br/>
                Post Seat <br />Allotment guidance</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon8} style={{width:"50px",height:"50px"}}/>
                <br/>
                Registration <br />Support</div> 
    </div> 

    </div>
    <div className="carousel-item">
        <div className="row">
            <div className="col-md-2 text-center">
            <img src={slidericon10} style={{width:"50px",height:"50px"}}/>
                <br/>
                College <br />Selector</div>  
            <div className="col-md-2 text-center">
            <img src={slidericon13} style={{width:"50px",height:"50px"}}/>
                <br/>
                Documentation <br />Support</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon4} style={{width:"50px",height:"50px"}}/>
                <br/>
                Option entry <br />Support</div>               
            <div className="col-md-2 text-center">
            <img src={slidericon7} style={{width:"50px",height:"50px"}}/>
                <br/>
                Speciality-Specific <br />Mentorship</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon11} style={{width:"50px",height:"50px"}}/>
                <br/>
                Management/NRI<br /> Quota Support</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon9} style={{width:"50px",height:"50px"}}/>
                <br/>
                Stray vacancy <br />guidance</div> 
        </div>                    
    </div>
    <div className="carousel-item">
    <div className="row">
            <div className="col-md-2 text-center">
            <img src={slidericon5} style={{width:"50px",height:"50px"}}/>
                <br/>
                Pre-Counselling<br /> Information</div>  
            <div className="col-md-2 text-center">
            <img src={slidericon2} style={{width:"50px",height:"50px"}}/>
                <br/>
                Notification <br />Updates</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon3} style={{width:"50px",height:"50px"}}/>
                <br/>
                Deadline <br />Reminders</div>               
            <div className="col-md-2 text-center">
            <img src={slidericon6} style={{width:"50px",height:"50px"}}/>
                <br/>
                Admission <br />Counseling</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon12} style={{width:"50px",height:"50px"}}/>
                <br/>
                Post Seat <br />Allotment guidance</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon8} style={{width:"50px",height:"50px"}}/>
                <br/>
                Registration <br />Support</div> 
    </div> 

    </div>
    <div className="carousel-item">
        <div className="row">
            <div className="col-md-2 text-center">
            <img src={slidericon10} style={{width:"50px",height:"50px"}}/>
                <br/>
                College <br />Selector</div>  
            <div className="col-md-2 text-center">
            <img src={slidericon13} style={{width:"50px",height:"50px"}}/>
                <br/>
                Documentation <br />Support</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon4} style={{width:"50px",height:"50px"}}/>
                <br/>
                Option entry <br />Support</div>               
            <div className="col-md-2 text-center">
            <img src={slidericon7} style={{width:"50px",height:"50px"}}/>
                <br/>
                Speciality-Specific <br />Mentorship</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon11} style={{width:"50px",height:"50px"}}/>
                <br/>
                Management/NRI<br /> Quota Support</div> 
            <div className="col-md-2 text-center">
            <img src={slidericon9} style={{width:"50px",height:"50px"}}/>
                <br/>Stray vacancy <br />guidance</div> 
        </div>                    
    </div>
  </div>
  
</div>
   
<div  id="demo-mobile" className="carousel slide my-5" data-ride="carousel">
  <ul className="carousel-indicators">
    <li data-target="#demo" data-slide-to={0} className="active" />
    <li data-target="#demo" data-slide-to={1} />
    <li data-target="#demo" data-slide-to={2} />
    <li data-target="#demo" data-slide-to={3} />
   
  </ul>
  <div className="carousel-inner">
    <div className="carousel-item active">
    <div className="row">
            <div className="col-md-12 text-center">
            <img src={slidericon5} style={{width:"50px",height:"50px"}}/>
                <br/>
                Pre-Counselling  Information</div>  
           
    </div> 

    </div>
    <div className="carousel-item">
        <div className="row">
        <div className="col-md-12 text-center">
        <img src={slidericon2} style={{width:"50px",height:"50px"}}/>
                <br/>
            Notification  Updates</div> 
          
        </div>                    
    </div>
    <div className="carousel-item">
    <div className="row">
           
            <div className="col-md-12 text-center">
            <img src={slidericon3} style={{width:"50px",height:"50px"}}/>
                <br/>
                Deadline  Reminders</div>               
           
    </div> 

    </div>
    <div className="carousel-item">
        <div className="row">
             <div className="col-md-12 text-center">
             <img src={slidericon12} style={{width:"50px",height:"50px"}}/>
                <br/>
                Post Seat Allotment guidance</div> 
        </div>                    
    </div>
    <div className="carousel-item">
        <div className="row">
        <div className="col-md-12 text-center">
        <img src={slidericon8} style={{width:"50px",height:"50px"}}/>
                <br/>
            Registration Support</div> 
        </div>                    
    </div>
    <div className="carousel-item">
        <div className="row">
        <div className="col-md-12 text-center"><img src={slidericon10} style={{width:"50px",height:"50px"}}/>
                <br/>
                College Selector</div>  
        </div>                    
    </div>
    <div className="carousel-item">
        <div className="row">
            <div className="col-md-12 text-center">
            <img src={slidericon13} style={{width:"50px",height:"50px"}}/>
                <br/>
                Documentation Support</div>  
        </div>                    
    </div>
    <div className="carousel-item">
        <div className="row">
            <div className="col-md-12 text-center"><img src={slidericon4} style={{width:"50px",height:"50px"}}/>
                <br/>Option Entry Support</div>  
        </div>                    
    </div>
    <div className="carousel-item">
        <div className="row">
            <div className="col-md-12 text-center"><img src={slidericon7} style={{width:"50px",height:"50px"}}/>
                <br/>Speciality-Specific Mentorship</div>  
        </div>                    
    </div>
    <div className="carousel-item">
        <div className="row">
            <div className="col-md-12 text-center"><img src={slidericon11} style={{width:"50px",height:"50px"}}/>
                <br/>Management/NRI Quota Support</div>  
        </div>                    
    </div>
    <div className="carousel-item">
        <div className="row">
            <div className="col-md-12 text-center"><img src={slidericon9} style={{width:"50px",height:"50px"}}/>
                <br/>Stray Vacancy Guidance</div>  
        </div>                    
    </div>
  </div>
  
</div>
         <div className="row bg-dark my-2" >
            <div className="col-md-6 text-center">
            <h3 className="search_header mt-4 text-white">
                                {" "}
                                Search medical colleges in{" "}
                            </h3>
                            <h1 className="text-orange text-uppercase mt-3">
                                {display_state}{" "}
                            </h1>
                            <h5 className="small_text text-blue mt-4">
                                {" "}
                                {/* and other{" "} */}
                                {/* <Link to="/state_filters">
                                    <span className="text-blue">
                                        {" "}
                                        {college_states.length - 1} states /
                                        union territories{" "}
                                    </span>
                                </Link>{" "} */}
                                {/* across india. */}
                            </h5>
            </div>
            <div className="col-md-6 pl-5">
            <form id="college_search_form" autoComplete="off">
                                <div className="home_search_group mt-1">
                                    {/* <div className="input-group">
                                        <Autocomplete
                                            suggestions={
                                                autocomplete_colleges &&
                                                autocomplete_colleges.length > 0
                                                    ? autocomplete_colleges
                                                    : []
                                            }
                                        />

                                        <div className="input-group-append">
                                            <button
                                                type="button"
                                                className="btn btn-lg btn-warning"
                                                onClick={
                                                    this.submitSearchHandler
                                                }>
                                                <i className="fa fa-search mr-1"></i>
                                            </button>
                                        </div>
                                    </div> */}
                                    <input className="py-2 college_search_input search_input" type="search" name="c_search_key" style={{borderRadius:"50px"}} />
                                    <span><a role="button" href="/#/college_filters" className="btn college_searc_btn">Search</a></span>
                                </div>
                            </form>

            </div>
         </div>

<div className="row px-5 pt-5 mt-5 mb-0 pb-0" style={{background:"#fff9f7"}}>
    <div className="col-md-6">
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/8OLS447Ac4c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
   
    </div>
    <div className="col-md-6">
    <h3 className="text-center orange-color">News Updates</h3>
    <div className="" >
                        <Carousel autoPlay={true} swipeable={true} showThumbs={false} showStatus ={false} interval={5000} showIndicators={false} infiniteLoop={true}>
                
                {news}
           
                {/* <div className="ml-5" >
                     <h2 className="text-left text-dark">NEET PG 2022 - Refund Of MCC Security Deposit</h2>
                     <p className="text-left text-dark">MCC Financial Custodian has commenced the refund of security deposit to eligible PG 22 Candidates </p>

                    <p className="text-left text-dark">The Candidate wise refund details will be published in the MCC Portal on or before 28-02-23. ..</p>
                    <a href="#/news_updates" role="button" className="btn bg-orange text-white float-left news-btn">Read More</a>

                   
                </div> */}
                
              
               
                {/* <div className="ml-5" >
                     <h2 className="text-left text-dark">NEET PG 2022 - Refund Of MCC Security Deposit</h2>
                     <p className="text-left text-dark">MCC Financial Custodian has commenced the refund of security deposit to eligible PG 22 Candidates </p>

                    <p className="text-left text-dark">The Candidate wise refund details will be published in the MCC Portal on or before 28-02-23. ..</p>
                    <a href="#/news_updates" role="button" className="btn bg-orange text-white float-left news-btn">Read More</a>
                   
                </div> */}
              
                
                
                
                {/* <div className="ml-5" >
                     <h2 className="text-left text-dark">NEET PG 2022 - Refund Of MCC Security Deposit</h2>
                     <p className="text-left text-dark">MCC Financial Custodian has commenced the refund of security deposit to eligible PG 22 Candidates </p>

                    <p className="text-left text-dark">The Candidate wise refund details will be published in the MCC Portal on or before 28-02-23. ..</p>
                    <a href="#/news_updates" role="button" className="btn bg-orange text-white float-left news-btn">Read More</a>
                   
                </div> */}
               
                
                
                {/* <div className="ml-5" >
                     <h2 className="text-left text-dark">NEET PG 2022 - Refund Of MCC Security Deposit</h2>
                     <p className="text-left text-dark">MCC Financial Custodian has commenced the refund of security deposit to eligible PG 22 Candidates </p>

                    <p className="text-left text-dark">The Candidate wise refund details will be published in the MCC Portal on or before 28-02-23. ..</p>
                <a href="#/news_updates" role="button" className="btn bg-orange text-white float-left news-btn">Read More</a>
                   
                </div> */}
                
            </Carousel>

                        </div>
        
    </div>
</div>

<h2 className="text-center my-5 pt-2">What Our Clients Say?</h2>
<div className="row">

    <img  src={Clients} alt="testimonials" width="100%" />

        <div className="col-md-3 my-4" style={{borderRight:"1px solid black"}}>
             {/* <h3>Rated<span className="mb-5" style={{fontSize:"10px"}}>on<br/>Google</span></h3>                        */}
            
               <img src={RatedGif} width="100%"/>
            
        </div> 
        <div className="col-md-9">
        <Carousel autoPlay={true} swipeable={true} showThumbs={false} showStatus ={false} interval={5000} infiniteLoop={true}>
            
                <div className="row">
                    <div className="col-md-3 ">
                      <img className="testimonial_img mt-3" src={maleDoctor} />  
                      <h4>Sanjana Manjunatha</h4>
                    </div>
                    <div className="col-md-9 pt-4">
                       <p className="mt-4 text-dark"> "Goocampus has some of the best staff, who are very friendly and efficient. My highest regards to them for all help rendered.
                        My heartfelt thanks to Mr Harshith and everyone at Goocampus"</p>
                    </div>
                   
                </div>
           
              
             
                <div className="row">
                    <div className="col-md-3">
                      <img className="testimonial_img mt-3" src={maleDoctor} />  
                      <h4>Manasa G</h4>
                    </div>
                    <div className="col-md-9 pt-4">
                       <p className="mt-4 text-dark">"Goocampus has some of the best staff, who are very friendly, efficient and patient enough to clear all my doubts regardless of the time. I am truly lucky to come across them and to have them help with my journey"</p>
                    </div>
                </div>
                
              
                
                <div className="row">
                    <div className="col-md-3">
                      <img className="testimonial_img mt-3" src={maleDoctor}  />  
                      <h4>Suri Raju</h4>
                    </div>
                    <div className="col-md-9 pt-4">
                       <p className="mt-4 text-dark">"I have had an amazing journey with GooCampus till date. From choosing the right pathway to clearing my exams, Ashwini Ma’am, Ralph, Vipin and everyone in the team have made my journey absolutely smooth and stress free. 100% recommended."</p>
                    </div>
                </div>
               
             
                <div className="ml-5">
                <div className="row">
                    <div className="col-md-3">
                      <img className="testimonial_img text-left mt-3" src={maleDoctor}  />  
                      <h4>Naveen Navi</h4>
                    </div>
                    <div className="col-md-9 pt-4">
                       <p className="mt-4 text-dark">"I opted Goo campus for my pg counselling, every state counselling updates and all india updates were updated properly . Harshith sir and Ashwini mam handled my college choices perfectly in AIQ. Couldn’t ask for more perfection.Thanks a lot"</p>
                    </div>
                </div>
                   
                </div>
               
            </Carousel>
        </div>    
</div>

<div className="row">
    <img src={Achivements} width="100%" alt="achivements"/>
</div>
<h2 className="text-dark text-center my-5" id="ourservices"><span className="px-3" style={{borderRadius:"20px",background:"#ffe4d5"}}>Our Services</span></h2>
<div className="row our-services-desktop">
    <div className="col-md-4">
          <img src={ServiceImage1} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 px-5">
    <h3 className="my-3" style={{borderBottom:"1px solid #f47523"}}>Notification Updates</h3>

 
    <p>You will receive instant counseling notification updates to keep you posted about each process of every counseling authorities</p>
    <p>Latest fee structures to help you in making informed choices </p>
    <p>Deadline reminders, <b style={{fontWeight:700}}>Never miss a deadline</b></p>


    </div>
    
</div>
<div className="row our-services-desktop">
    
<div className="col-md-8 pt-5 px-5">
<h3 className="my-3" style={{borderBottom:"1px solid #f47523"}}>Cut Off Analysis</h3>

<p>In-depth specialty-specific rank cutoff analysis to narrow down your desirable college's list.</p>

<p>Before applying, it's important to assess your chances of getting into a college, based on in-depth cutoff analysis and trends.</p>
<p style={{fontWeight:700}}>Cutoff analysis eliminates guesswork from option entry selections.</p>
</div>
    <div className="col-md-4">
          <img src={ServiceImage2} width="100%" height="100%" />                          
    </div>
    
    
</div>
<div className="row our-services-desktop">
    <div className="col-md-4">
          <img src={ServiceImage3} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 px-5">
        <h3 className="my-3" style={{borderBottom:"1px solid #f47523"}}>One to one Counselling</h3>
        <p>Using our industry expertise and experience, we counsel you for all state counselling authorities and for AIQ counselling at the centre.</p>
        <p style={{fontWeight:700}}>Using our experience, you can avoid costly mistakes and save time.</p>
    </div>
    
</div>
<div className="row our-services-desktop">
    <div className="col-md-8 px-5">
        <h3 className="mt-5" style={{borderBottom:"1px solid #f47523"}}>Option Entry Support</h3>
        <p >Our experts will help you shortlist and optimally prioritize your option entry list.</p>
        <p>We prepare the most optimal option entries for you, for each counseling body, for every round to maximize your chances of selection.</p>
        <p style={{fontWeight:700}}>Option entries are a matter of planning and analysis, not luck</p>
    </div>
    <div className="col-md-4">
          <img src={ServiceImage4} width="100%" height="100%" />                          
    </div>
    
    
</div>
<div className="row our-services-desktop my-5">

    <div className="col-md-4">
          <img src={ServiceImage5} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 px-5">
    <h3 className="my-3" style={{borderBottom:"1px solid #f47523"}}>College Recommendation</h3>
      <p>Using our extensive network of medical students across the country, we gather the latest, verified, genuine-college reviews for your benefit and recommend the best possible college for you.</p>                              
      
      <p style={{fontWeight:700}}>Genuine reviews can make or break your admission decisions.</p>
    </div>

    
</div>


{/* mobile servies */}

<div className="row our-services-mobile">
    <div className="col-md-4">
          <img src={ServiceImage1} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 px-5">
    <h3 className="my-5">Notification Updates</h3>

 
    <p>You will receive instant counseling notification updates to keep you posted about each process of every counseling authorities</p>
    <p>Latest fee structures to help you in making informed choices </p>
    <p>Deadline reminders, <b style={{fontWeight:700}}>Never miss a deadline</b></p>


    </div>
    
</div>
<div className="row our-services-mobile">
    

    <div className="col-md-4">
          <img src={ServiceImage2} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 pt-5 px-5">
<h3 className="my-5">Cut Off Analysis</h3>

<p>In-depth specialty-specific rank cutoff analysis to narrow down your desirable college's list.</p>

<p>Before applying, it's important to assess your chances of getting into a college, based on in-depth cutoff analysis and trends.</p>
<p style={{fontWeight:700}}>Cutoff analysis eliminates guesswork from option entry selections.</p>
</div>
    
</div>
<div className="row our-services-mobile">
    <div className="col-md-4">
          <img src={ServiceImage3} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 px-5">
        <h3 className="my-5">One to one Counselling</h3>
        <p>Using our industry expertise and experience, we counsel you for all state counselling authorities and for AIQ counselling at the centre.</p>
        <p style={{fontWeight:700}}>Using our experience, you can avoid costly mistakes and save time.</p>
    </div>
    
</div>
<div className="row our-services-mobile">
    
    <div className="col-md-4">
          <img src={ServiceImage4} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 px-5">
        <h3 className="my-5">Option Entry Support</h3>
        <p >Our experts will help you shortlist and optimally prioritize your option entry list.</p>
        <p>We prepare the most optimal option entries for you, for each counseling body, for every round to maximize your chances of selection.</p>
        <p style={{fontWeight:700}}>Option entries are a matter of planning and analysis, not luck</p>
    </div>
    
    
</div>
<div className="row our-services-mobile my-5">

    <div className="col-md-4">
          <img src={ServiceImage5} width="100%" height="100%" />                          
    </div>
    <div className="col-md-8 px-5">
    <h3 className="my-5">College Recommendation</h3>
      <p>Using our extensive network of medical students across the country, we gather the latest, verified, genuine-college reviews for your benefit and recommend the best possible college for you.</p>                              
      
      <p style={{fontWeight:700}}>Genuine reviews can make or break your admission decisions.</p>
    </div>

    
</div>  

<div className="row text-light bg-dark animated fadeIn py-1">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                        <h2 className="main_header">
                            <b> Interested? </b>
                        </h2>
                        <h5 className="text-center mt-3">
                            {" "}
                            Request a free callback from our expert cousellors
                            to know more and get in touch with us.
                        </h5>
                        <div className="mt-3">
                            {/* {is_auth && auth_user ? '' : <button className="mr-3 mt-2 abroad_btn py-2 show_login"> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>} */}
                            <button
                                className="mt-2 abroad_btn py-2 bg-orange"
                                onClick={() =>
                                    this.handleCallback("", "", "", "")
                                }>
                                {" "}
                                <i className="fa fa-phone mr-2"></i> REQUEST
                                CALLBACK
                            </button>
                        </div>
                    </div>
                </div>


                <div className="row explore_container py-3 mt-5">
                    <div className="col"></div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-11 col-12 p-4 text-center home_service_container">
                        <h2 className="main_header text-white">
                            <b className="text-dark"> EXPLORE </b>
                        </h2>

                        {/* <ScrollAnimation animateIn="fadeIƒn" delay={100} duration={1} offset={50}> */}
                        <div className="row mt-3">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                                <Link to="/news_updates">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={NewsIcon}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6 className="mt-3 mb-1">
                                                <b> ADMISSION </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> NEWS </b>
                                            </h5>
                                            {/* <p> Explore all news according to interested states, stream and course level. </p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                                <Link to="/calendar">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={CalendarIcon}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6 className="mt-3 mb-1">
                                                <b> EVENT </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> CALENDAR </b>
                                            </h5>
                                            {/* <p> Explore all events based on your stream and course level through our calendar. </p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                                <Link to="/college_filters">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={CollegesIcon}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6 className="mt-3 mb-1">
                                                <b> SEARCH </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> COLLEGES </b>
                                            </h5>
                                            {/* <p> Explore all colleges you are interested to join acrooss the world. </p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                                <Link to="/videos">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={VideoIcon}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6 className="mt-3 mb-1">
                                                <b> GOOCAMPUS </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> TV </b>
                                            </h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            

                            {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
									<Link to="/cutoffs">
										<div className="card">
											<div className="card-body">
												<img src={CutoffIcon} alt="Icon" height="66"/>
												<h6 className="mt-3 mb-1"><b> COURSE </b></h6>
												<h4 className="mb-0"><b> CUTOFFS </b></h4>
											</div>
										</div>
									</Link>
								</div> */}

                          
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                                <a
                                    href="https://www.goocampusuk.com"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={UKIcon}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6 className="mt-3 mb-1">
                                                <b> GOOCAMPUS </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> UK </b>
                                            </h5>
                                            {/* <p> Explore the collegs, calendar, exam updates and news updates across UK. </p> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                            <a
                                    href="https://www.goocampususa.com"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={usaimg}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6 className="mt-3 mb-1">
                                                <b> GOOCAMPUS </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> USA </b>
                                            </h5>
                                            {/* <p> Compare any of colleges around the world to know more about colleges. </p>  */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                           
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                            <a
                                    href="https://www.goocampusaustralia.com"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={australia}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6  className="mt-3 mb-1">
                                                <b> GOOCAMPUS </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> AUSTRALIA </b>
                                            </h5>
                                            {/* <p> Rate and write, views reviews and get solutions to your questions within less time.</p>  */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-3">
                                <a
                                    href="https://www.goocampusabroad.com"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={AbroadIcon}
                                                alt="Icon"
                                                height="66"
                                            />
                                            <h6 className="mt-3 mb-1">
                                                <b> GOOCAMPUS </b>
                                            </h6>
                                            <h5 className="mb-0">
                                                <b> ABROAD </b>
                                            </h5>
                                            {/* <p> Explore all colleges you are interested to join acrooss the globe. </p> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        {/* </ScrollAnimation> */}
                    </div>
                    <div className="col"></div>
                </div>

                

                {/* App description container */}
                <div className="row p-4 popular_section animated fadeIn">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center">
                        <h2 className="main_header text-orange">
                            <b> GOOCAMPUS APP </b>
                        </h2>
                        <hr className="hori_line" />

                        <div className="row justify-content-center p-2">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-4">
                                <img
                                    src={AppScreen}
                                    alt="Banner"
                                    height="100%"
                                    width="100%"
                                />
                            </div>
                            {/* <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 bg-blue"></div> */}
                            <div className="col text-left">
                                <div className="p-4 bg-orange">
                                    <h3 className="header text-white">
                                        {" "}
                                        Download, Share, Support
                                    </h3>
                                    <h6 className="header text-white my-3">
                                        <i className="fa fa-check-circle text-white"></i>{" "}
                                        NEET counselling updates.
                                    </h6>
                                    <h6 className="header text-white my-3">
                                        <i className="fa fa-check-circle text-white"></i>{" "}
                                        Counselling events calendar.
                                    </h6>
                                    <h6 className="header text-white my-3">
                                        <i className="fa fa-check-circle text-white"></i>{" "}
                                        College and course cutoffs.
                                    </h6>
                                    <h6 className="header text-white my-3">
                                        <i className="fa fa-check-circle text-white"></i>{" "}
                                        Easy to use college search.
                                    </h6>
                                    <h6 className="header text-white my-3">
                                        <i className="fa fa-check-circle text-white"></i>{" "}
                                        College-wise details - free structure,
                                        total intake etc.
                                    </h6>
                                    <h6 className="header text-white my-3">
                                        <i className="fa fa-check-circle text-white"></i>{" "}
                                        Absolutely free to use, now and always.
                                    </h6>
                                </div>
                                <hr />

                                {/* App promo video */}
                                <div className="mt-4">
                                    <div className="card mt-0">
                                        <YouTube
                                            videoId="qL6EEvRp5Mw"
                                            opts={opts}
                                        />
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col text-center">
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.goocampusapp"
                                                target="_blank">
                                                <img
                                                    src={PlayStore}
                                                    alt="Play store"
                                                    height="77"
                                                    width="100%"
                                                />
                                            </a>
                                            <br />
                                            <br />
                                            <a
                                                href="https://apps.apple.com/us/app/goocampus/id1488589090?ls=1"
                                                target="_blank">
                                                <img
                                                    src={AppStore}
                                                    alt="App store"
                                                    height="77"
                                                    width="100%"
                                                />
                                            </a>
                                        </div>
                                        <div className="col text-center">
                                            <img
                                                src={QRCode}
                                                alt="QR code"
                                                height="200"
                                                width="200"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                
                {/* get in touch container */}
                {/* <ScrollAnimation animateIn="fadeIn" delay={100} duration={1}> */}
               
                <h2 className="text-dark text-center my-4">Visit US</h2>
                <div className="row">
                   <div className="col-md-7 pt-2">
                    {/* <iframe src="https://www.googlecom/maps/embed?pb=!1m18!1m12!1m3!1d3887.3845713702217!2d77.57671431482241!3d13.011164690829862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d09d8a01d11%3A0xa2ced0a30ea5b65!2sGooCampus%20Edu%20Solutions%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1677658930377!5m2!1sen!2sin" width="100%" height={300} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
                    <img src={mapimage} width="100%" alt="goocampus map"/>
                    </div>
                    <div className="col-md-5">
                    <div className="w-100 py-3 mt-1" style={{background:"whitesmoke"}}>
                         <div className="row">
                            <div className="col-md-3 pl-5 mt-4"><img src={Icon2} alt="Icon1" width="50" height="50" /></div>
                            <div className="col-md-8 info-mobile">
                                <h5 className="text-left"><b style={{fontWeight:"700"}}>Our Address</b></h5>
                                <b>138/6,Ground Floor,10th Main Road,6th A Cross,Sadashiv Nagar,RMV Extension,behind SBI Bank,Bengaluru,Karnataka-560030.</b></div>    
                         </div>       
                    </div>
                    <div className="w-100 py-3 mt-1" style={{background:"whitesmoke"}}>
                         <div className="row">
                            <div className="col-md-3 pl-5"><img src={Icon3} alt="Icon1" width="50" height="50" /></div>
                            <div className="col-md-8 info-mobile">
                                <h5 style={{fontWeight:"700"}}>Opening Hours</h5>
                                <b>Mon-Sat:</b><span>&nbsp;&nbsp;9am-7pm</span>    
                            </div>    
                         </div>       
                    </div>
                    <div className="w-100 py-3 mt-1" style={{background:"whitesmoke"}}>
                         <div className="row">
                            <div className="col-md-3 pl-5"><img src={Icon1} alt="Icon1" width="50" height="50" /></div>
                            <div className="col-md-8 info-mobile">
                               <h5 style={{fontWeight:"700"}}>Email</h5> 
                               <b>info@goocampus.in</b>    
                            </div>    
                         </div>       
                    </div>
                    <div className="w-100 py-3 mt-1 mb-3" style={{background:"whitesmoke"}}>
                         <div className="row">
                            <div className="col-md-3 pl-5"><img src={Icon4} alt="Icon1" width="50" height="50" /></div>
                            <div className="col-md-8 info-mobile">
                                <h5 style={{fontWeight:"700"}}>Phone</h5>
                                <a className="text-dark" href="tel:08041743956">08041743956,</a>
                                <a className="text-dark" href="tel:+91-9019925844">+91-9019925844</a>
                                
                            </div>    
                         </div>       
                    </div>
                    </div>
                    </div>
                {/* </ScrollAnimation> */}

                {/* Favourite colleges container */}
                {/* {favourite_colleges && favourite_colleges.length > 0 ? (
                    // <ScrollAnimation animateIn="fadeIn" delay={100} duration={1}>
                    <div
                        className="row p-4 popular_section animated fadeIn"
                        id="favourite_colleges_section">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center">
                            <h2 className="main_header">
                                <b> FAVOURITE COLLEGES </b>
                            </h2>
                            <hr className="hori_line" />

                            <h6 className="sub_header">
                                {" "}
                                Your most favourite colleges
                            </h6>

                            <div className="row justify-content-center p-2">
                                {favourite_colleges.map(college => (
                                    <div
                                        key={college.id}
                                        className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-4">
                                        <div className="card">
                                            <div className="col p-0">
                                                <img
                                                    src={
                                                        img_url +
                                                        college.col_image
                                                    }
                                                    alt="Banner"
                                                    height="200"
                                                    width="100%"
                                                />
                                                <div className="bg_overlay"></div>
                                                <i className="fav_icon fa fa-heart"></i>

                                                <div className="mci_fmge_group">
                                                    {college.is_popular ===
                                                    "1" ? (
                                                        <button className="popular_div">
                                                            {" "}
                                                            Popular{" "}
                                                        </button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className="col_details">
                                                    <h5 className="col_name text-capitalize">
                                                        {college.col_name}
                                                    </h5>
                                                    <span className="col_state">
                                                        {" "}
                                                        {college.state_name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="button-group">
                                                    <button
                                                        className="abroad_btn mr-2"
                                                        onClick={() =>
                                                            this.handleCallback(
                                                                college.col_state,
                                                                college.id,
                                                                "",
                                                                ""
                                                            )
                                                        }>
                                                        {" "}
                                                        <i className="fa fa-phone"></i>{" "}
                                                        Callback{" "}
                                                    </button>
                                                    <Link
                                                        to={
                                                            "/college_details/" +
                                                            college.col_name
                                                        }>
                                                        <button className="ml-2 abroad_btn">
                                                            {" "}
                                                            <i className="fa fa-eye"></i>{" "}
                                                            View college
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Link
                                to={{
                                    pathname: "/college_filters",
                                    search: "?c_favourite=1"
                                }}>
                                <button className="ml-2 abroad_btn py-2">
                                    {" "}
                                    <i className="fa fa-eye"></i> View all
                                    colleges
                                </button>
                            </Link>
                        </div>
                    </div>
                ) : (
                    // </ScrollAnimation>
                    ""
                )} */}

                {/* <Link to="/"><h4 className="text-center mt-5 up_arrow"><i className="fa fa-arrow-up"></i></h4></Link> */}
            </div>
        );
    }

    componentWillUnmount() {
        // this.props.onGetAutocompleteColleges('');
        clearInterval(this.displayState);
    }
}

const mapStatesToProps = state => {
    return {
        autocomplete_colleges: state.home.autocomplete_colleges,
        college_states: state.state.college_states,
        popular_colleges: state.home.popular_colleges,
        favourite_colleges: state.home.favourite_colleges,
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        img_url: state.home.img_url,
        news_updates: state.news.news_updates,
    };
};

const mapActionsToProps = {
    onGetHomeDetails: getHomeDetails,
    onGetAutocompleteColleges: getAutocompleteColleges,
    onAddRemoveFavourite: addRemoveFavourite,
    onSetCallbackValues: setCallbackValues,
    onGetFilterNewsUpdates: getFilterNewsUpdates,
};

export default connect(mapStatesToProps, mapActionsToProps)(Home);
