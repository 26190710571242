import React from "react";
import "./Courses.css";

const PGDiplomaCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>Post Graduate Diploma (PG Diploma)</b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            Post Graduate Diploma or PG Diploma is a two-year
                            course where candidates receive training on
                            intensive care and methods on how to relieve pain.
                            Candidates will be given an opportunity on how to
                            handle patients independently which will help them
                            learn problem solving skills in a live environment.
                        </p>
                        <p className="para">
                            It is important to note that not all subjects come
                            with the option of both a degree course and a
                            diploma course, for instances, there is no diploma
                            course for medicine or for surgery. A full-fledged
                            degree course requires candidates to submit a
                            thesis. Unless the degree candidates submit the
                            thesis, they are not allowed to appear for their
                            exam, however diploma candidates do not need to
                            submit any thesis as part of their course work.
                        </p>
                        <p className="para">
                            A regular degree course lasts for about 3 years
                            which consists of 4 theory papers (approximately)
                            and has both practical and oral exams including the
                            thesis paper they submit, whereas a diploma course
                            lasts only for 2 years and has only 3 theory papers
                            (approximately) and no thesis paper.
                        </p>
                        <p className="para">
                            One distinction between a regular degree course and
                            a diploma course is that only a candidate who has
                            completed their MD/MS degree can apply for academic
                            positions (like Assistance Professor) and can super
                            specialise (such as getting a DM/MCH). Diploma
                            holders can neither apply for academic positions,
                            nor can they super specialise in their field of
                            speciality, however they can choose to pursue a DNB
                            course (of 2 year duration) from an MCI recognised
                            institute to become eligible for super speciality
                            exams.
                        </p>
                        <p className="para">
                            PG Diploma candidates also have the option of
                            entering into MD courses directly in the 2nd year.
                            Broadly speaking, the overall employment
                            opportunities are better for an MD/MS candidate,
                            however securing an admission for these courses are
                            considerably quite tougher.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course content</h4>
                        <ul className="courses_list">
                            <li>
                                Extensive exposure to various common diseases
                                and their treatment/management.
                            </li>
                            <li>
                                Competence training in the latest equipment in
                                both OPD and OT.
                            </li>
                            <li>
                                Focus on promoting research work in medical
                                fields.
                            </li>
                            <li>
                                All candidates receive teaching in both ICUs and
                                ORs as part of their curriculum.
                            </li>
                            <li>
                                Training in resuscitation skills (competence
                                levels both basic & advanced).
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Advantages of PG - Diploma</h4>
                        <p className="para">
                            An aspiring PG-Diploma candidate will be trained on
                            how to manage common diseases while being exposed on
                            new methods on how to combat them. Candidates will
                            also be trained on the latest innovations in the
                            medical industry, especially on the equipment in the
                            Outpatient Department (OPD) and the Operation
                            Theatre.{" "}
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Specializations</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Diploma in Dermatology</li>
                                    <li>Diploma in Anaesthesia</li>
                                    <li>Diploma in Child Health</li>
                                    <li>
                                        Diploma in Obstetrics and Gynaecology
                                    </li>
                                    <li>Diploma in Psychological Medicine</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Diploma in Ophthalmology</li>
                                    <li>Diploma in Orthopaedics</li>
                                    <li>Diploma in Otorhinolaryngology</li>
                                    <li>Diploma in Radio-Diagnosis</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PGDiplomaCourse;
