import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import "mdbreact/dist/css/mdb.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "rc-datetime-picker/dist/picker.min.css";
import "rc-datetime-picker/dist/rc-datetime-picker.min.js";
import ErrorBoundary from "./components/ErrorBoundary";

import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
   
    <Provider store={store}>
        {" "}
        <App />{" "}
    </Provider>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
