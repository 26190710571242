import React from "react";
import "./Courses.css";

const BVScCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>Bachelor of Veterinary Science (BVSc)</b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            Bachelors in Veterinary Science or B.V.Sc. is an
                            undergraduate course which deals with the science
                            involving Animals. Veterinary Science, this course
                            in particular deals with the implementation of
                            medical, diagnostic and therapeutic principles on
                            various types of Animals. The duration of this
                            course is roughly 5 years (broken down into 10
                            semesters) including an internship of 6 months, the
                            exact duration may vary depending on the time period
                            of the internship. The course can also be known as
                            Bachelor’s in Veterinary Science and Animal
                            Husbandry or B.V.Sc. & AHB. Each institute has a
                            different criteria for completing the course, for
                            example: as per the Kerala Agricultural University
                            the maximum duration for completing the course is 8
                            academic years (16 semesters).
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body established under the Ministry of Agriculture
                            by the Government of India. To grant recognized
                            veterinary qualifications which are to be issued by
                            veterinary institutions the VCI sets minimum
                            standards of veterinary education. The curriculum of
                            this course includes theoretical and practical
                            training in various disciplines. Various
                            Agricultural Institutes in India provide Veterinary
                            courses. An Individual upon the completion of this
                            course is known as a ‘Veterinarian’. If you’re an
                            animal lover, then this course is for you. An animal
                            cannot verbally describe their pain and suffering
                            which is why any aspiring candidate needs to be
                            compassionate and caring towards animal behaviour to
                            determine any form of pain or abnormal behaviour.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <ul className="courses_list">
                            <li>
                                An aspiring candidate who wish to study the
                                Veterinary course should have an aggregate of
                                50% in the qualifying examination in the
                                subjects of English, Physics, Chemistry &
                                Biology.
                            </li>
                            <li>
                                If the candidate belongs to SC/ST or any other
                                special category of candidates as mentioned by
                                the Government, then the marks to be eligible
                                for admittance will be 10% less than the
                                requirement in the general category. The
                                admission to this course is done through NEET(
                                National Eligibility cum Entrance Test).
                            </li>
                            <li>
                                The National Testing Agency conducts an Entrance
                                Exam called NEET( National Eligibility cum
                                Entrance Test for filling up of 15% of the total
                                no. of seats under AIQ( All India Quota) and to
                                get admitted into respective state universities.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Subjects</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Veterinary Anatomy</li>
                                    <li>
                                        Veterinary Physiology and Biochemistry
                                    </li>
                                    <li>
                                        Veterinary Pharmacology and Toxicology
                                    </li>
                                    <li>Veterinary Parasitology</li>
                                    <li>Veterinary Microbiology</li>
                                    <li>Veterinary Pathology</li>
                                    <li>
                                        Veterinary Public Health and
                                        Epidemiology
                                    </li>
                                    <li>Animal Nutrition</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Animal Genetics and Breeding</li>
                                    <li>Livestock Production Management</li>
                                    <li>Livestock Products Technology</li>
                                    <li>
                                        Veterinary Gynaecology and Obstetrics
                                    </li>
                                    <li>Veterinary Surgery and Radiology</li>
                                    <li>Veterinary Medicine</li>
                                    <li>
                                        Veterinary and Animal Husbandry
                                        Extension Education
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Syllabus</h4>
                        <table className="table table-bordered table-responsive table-striped">
                            <thead>
                                <tr className="text-center">
                                    <th className="text-center">Course</th>
                                    <th className="text-center">Topics</th>
                                    <th className="text-center">
                                        Aim and Objective
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="bvsc_table_body">
                                <tr>
                                    <td>
                                        Comparative Osteology And Arthrology
                                    </td>
                                    <td>
                                        Technical terms, structure, chemical
                                        composition and classification of bones,
                                        Bones of axial skeleton of ox as a type
                                        and their comparison with those of
                                        horse, dog, pig and poultry,
                                        Classification and detailed study of
                                        different joints of the body.
                                    </td>
                                    <td>
                                        To make a student well versed with the
                                        bones and joints of different
                                    </td>
                                </tr>
                                <tr>
                                    <td>Comparative Splanchnology</td>
                                    <td>
                                        Descriptive anatomy of various organs of
                                        digestive system and associated glands
                                        of ox and their comparison with those of
                                        horse, dog, pig and poultry. Study of
                                        formation of thoracic, abdominal and
                                        pelvic cavities; reflection of the
                                        cavities, oesophagotomy, gastrotomy,
                                        rumenotomy, cystotomy, urethrotomy.
                                    </td>
                                    <td>
                                        To give a detailed overview of different
                                        systems constituting splanchnology.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Myology, Angiology, Neurology and
                                        Anesthesiology of Ox
                                    </td>
                                    <td>
                                        Classification of muscle fibers, Origin,
                                        insertion and relations of muscles of
                                        different body parts, Topographic
                                        anatomy of the vascular system
                                        comprising of heart, arteries, veins and
                                        lymphatics, gross anatomy of various
                                        sense organs.
                                    </td>
                                    <td>
                                        The course gives a thorough knowledge
                                        about the muscles, course of blood
                                        vessels and nerves of an ox as a type
                                        animal.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Theory and Practice of Histological and
                                        Histochemical Techniques
                                    </td>
                                    <td>
                                        Preparation of tissues for light
                                        microscopy using different fixatives,
                                        Different staining methods for routine
                                        light microscopy, Frozen sectioning
                                        techniques and staining methods for
                                        enzymes, carbohydrates, lipids,
                                        proteins, pigments etc.
                                    </td>
                                    <td>
                                        The course is to give exposure to
                                        methods of processing the tissues for
                                        research and teaching purposes.
                                    </td>
                                </tr>
                                <td>General Histology and Ultrastructure</td>
                                <td>
                                    Light and ultrastructural details of animal
                                    cell, Light and ultrastructural details of
                                    epithelial tissue, ultrastructural details
                                    of muscular tissue, details of connective
                                    tissue and nervous tissue.
                                </td>
                                <td>
                                    It is studied to understand basic principles
                                    of light microscopy and light and
                                    ultrastructure of four basic tissues.
                                </td>
                                <tr>
                                    <td>Developmental Anatomy</td>
                                    <td>
                                        Gametogenesis, fertilization, cleavage
                                        and gastrulation, Development of foetal
                                        membranes and placenta in domestic
                                        animals, Histogenesis of nervous system,
                                        sense organs, endocrine organs and
                                        cardiovascular system, Embryonic
                                        development of digestive, respiratory,
                                        uro-genital and musculoskeletal system.
                                    </td>
                                    <td>
                                        To understand the developmental
                                        processes of different body systems at
                                        various stages of pregnancy.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Principles and Applications of
                                        Biomechanics
                                    </td>
                                    <td>
                                        Biomechanics, its definition and scope
                                        with reference to anatomy and physiology
                                        of domestic animals and musculo-skeletal
                                        dynamics, Locomotion and clinical
                                        applications. Biomechanics of cortical
                                        and trabecular bones, Biomechanics of
                                        fracture fixation.
                                    </td>
                                    <td>
                                        To familiarize the student about the
                                        importance of biomechanics.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course suitability</h4>
                        <ul className="courses_list">
                            <li>
                                An aspiring candidates should be compassionate
                                and loving which will enable them to read the
                                symptoms from animal’s behaviour and diagnose
                                the disease/ailment.
                            </li>
                            <li>
                                The candidate should be able to handle
                                emergencies in rural areas where the working
                                hours could be irregular and long, the
                                candidates should be able to perform in the most
                                unexpected conditions even if the conditions are
                                uncomfortable.
                            </li>
                            <li>
                                The candidate should have the ability to make in
                                depth observations and should be capable of
                                adapting and work in the conditions with ease.
                            </li>
                            <li>
                                The candidate should be able understand the
                                animal and relieve them from the pain.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Advantages of B.V.Sc</h4>
                        <ul className="courses_list">
                            <li>
                                This course serves as a basis for further
                                studies in the field of Veterinary Science
                                through a masters degree i.e M.V.Sc. and a
                                Doctorate i.e Ph.D or M.Phil. Once either of
                                these courses are completed, you will be
                                eligible to become a lecturer in any
                                university/college.
                            </li>
                            <li>
                                Upon the completion of a Bachelor's degree in
                                Veterinary Science, an Individual can apply for
                                either a Master’s degree or a Doctorate degree
                                in various specializations such as Animal
                                Genetics & Breeding, Animal Nutrition, Animal
                                Reproduction, Livestock Products Technology,
                                Livestock Production & Management, Masters in
                                Wildlife Science, Poultry Science, Veterinary
                                Anatomy, Veterinary Medicine, Veterinary
                                Microbiology, Veterinary Parasitology,
                                Veterinary Pathology, Veterinary Pharmacology &
                                Toxicology, Veterinary Physiology, Veterinary
                                Biochemistry, Veterinary Surgery & Radiology,
                                Veterinary Animal Husbandry Extension,
                                Veterinary Public Health, etc.
                            </li>
                            <li>
                                If you love animals and are compassionate about
                                them, thena B.V.Sc is a very suitable course for
                                you.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Employment opportunities</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>
                                        Animal Resource Development under
                                        Panchayati Raj
                                    </li>
                                    <li>Army - Remount Veterinary Corps</li>
                                    <li>Banks</li>
                                    <li>
                                        Biological Products or Vaccine
                                        Institutions
                                    </li>
                                    <li>
                                        Biological Products and Vaccine Product
                                        Plants
                                    </li>
                                    <li>Breeding Farms / Hatchery</li>
                                    <li>BSF / Police</li>
                                    <li>Central Farms</li>
                                    <li>
                                        Clinical, Diagnostic & Investigation
                                        Centres attached to Veterinary Colleges
                                        and Research Institutes.
                                    </li>
                                    <li>Consultancy</li>
                                    <li>
                                        Corporate Bodies Eg. National Dairy
                                        Development Board, Milk Board and Milk
                                        Unions
                                    </li>
                                    <li>
                                        Diagnostic Laboratories - Pathology,
                                        Biochemistry, Microbiology
                                    </li>
                                    <li>
                                        Department of Animal Husbandry &
                                        Dairying
                                    </li>
                                    <li>Disease Investigation Centres</li>
                                    <li>Drug Research Institutions</li>
                                    <li>Experimental Animal Unit</li>
                                    <li>
                                        Experimental & Germ Free Animal
                                        Facilities attached to Veterinary &
                                        Medical Institutions.
                                    </li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Feed Processing Industry</li>
                                    <li>
                                        I.C.A.R. - Indian Council of
                                        Agricultural Research
                                    </li>
                                    <li>Ministry of Agriculture</li>
                                    <li>Quarantine Units</li>
                                    <li>Veterinary Hospitals</li>
                                    <li>Veterinary Centres</li>
                                    <li>Organised State Farms</li>
                                    <li>Poultry Farms</li>
                                    <li>Meat / Milk Processing Plants</li>
                                    <li>Polyclinics</li>
                                    <li>Public Health Laboratories</li>
                                    <li>
                                        Teaching / Research / Extension in
                                        Veterinary College / Institutes /
                                        Universities
                                    </li>
                                    <li>
                                        Para Veterinary Staff Training Schools
                                    </li>
                                    <li>
                                        Local Bodies / Municipalities /
                                        Panchayats
                                    </li>
                                    <li>
                                        Slaughter Houses/ Cattle Pounds / Public
                                        Health Laboratories
                                    </li>
                                    <li>Zoos & Wildlife Centres</li>
                                    <li>Race Clubs</li>
                                    <li>Stud Farms</li>
                                    <li>
                                        Veterinary Instruments / Equipment
                                        Industry
                                    </li>
                                    <li>X-Ray and Ultra-Sound Facility</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Job types</h4>
                        <p className="para">
                            Veterinarians can choose to work in any concerned
                            are be it with the government animal husbandry
                            departments or poultry farms or dairy farms. The
                            options are not limited here. Below are mentioned,
                            plenty of employment areas.
                        </p>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Animal Care and Service Worker</li>
                                    <li>Biological Scientist</li>
                                    <li>Veterinary Researcher</li>
                                    <li>Pharmacy Research Scientist</li>
                                    <li>
                                        Veterinary Technologists and Technician
                                    </li>
                                    <li>Private Practice</li>
                                    <li>Lecturer</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Veterinary Consultant</li>
                                    <li>Partner, Assistant to Practitioner</li>
                                    <li>Senior Medical Representative</li>
                                    <li>Veterinarian / Veterinary Doctor</li>
                                    <li>Veterinary Surgeon</li>
                                    <li>Associate Scientific Manager</li>
                                    <li>Manager Animal Farm</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BVScCourse;
