import React from "react";
import "./StateFilters.css";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { getFilterStates } from "../../actions/States";
import $ from "jquery";
import Loader from "../../assets/loader.gif";
import NoResultsIcon from "../../assets/no_results.png";
import { setCallbackValues } from "../../actions/Callbacks";

class StateFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active_page: 1 };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    componentWillMount() {
        this.props.onGetFilterStates("");
    }

    clearFiltersHandler = e => {
        window.location.reload();
    };

    handlePageChange(page_number) {
        this.setState({ active_page: page_number });

        var filterData = $("#states_filters_form").serialize();
        var pageData = $("#states_pagination_form").serialize();
        var fmge_data = "";

        $(".fmge_check:checked").each(function (index) {
            fmge_data += index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        pageData += "&c_page_no=" + page_number + "&c_fmge=" + fmge_data;
        this.props.onGetFilterStates("?" + filterData + "&" + pageData);
    }

    onChangeFilters = e => {
        this.setState({ active_page: 1 });

        var filterData = $("#states_filters_form").serialize();
        var pageData = $("#states_pagination_form").serialize();
        var fmge_data = "";

        $(".fmge_check:checked").each(function (index) {
            fmge_data += index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        pageData += "&c_page_no=1&c_fmge=" + fmge_data;

        this.props.onGetFilterStates("?" + filterData + "&" + pageData);
    };

    handleCallback = (state, college, stream, course_level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, course_level);
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { active_page } = this.state;
        const {
            states,
            sub_loader,
            page_size,
            page_count,
            total_count,
            is_auth
        } = this.props;

        return (
            <div className="row states_container pt-5">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
                    {/* Breadcrumbs and results counter container */}
                    <div className="row sort_container">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                            <nav area-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>{" "}
                                    </li>
                                    <li
                                        className="breadcrumb-item active"
                                        area-current="page">
                                        States
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                            <button
                                className="clear_btn float-left"
                                onClick={this.clearFiltersHandler}>
                                {" "}
                                Clear filters{" "}
                            </button>
                            <span className="small_text text-blue">
                                {" "}
                                Showing {page_count} of {total_count}{" "}
                                {total_count === 1 ? "state" : "state"}.
                            </span>
                        </div>
                    </div>

                    {/*  States display container */}
                    <div className="row px-1">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 px-2 mb-4">
                            <div className="filters_card">
                                <form
                                    id="states_filters_form"
                                    autoComplete="off">
                                    <h6
                                        className="filter_header"
                                        data-toggle="collapse"
                                        data-target="#search_collapse">
                                        {" "}
                                        Search{" "}
                                        <i className="fa fa-chevron-down"></i>
                                    </h6>

                                    <div
                                        className="filter_div collapse show"
                                        id="search_collapse">
                                        <div className="input-group p-1">
                                            <input
                                                type="search"
                                                name="c_search_key"
                                                onChange={this.onChangeFilters}
                                                className="form-control search_input"
                                                placeholder="Search state"
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <h6
                                        className="filter_header"
                                        data-toggle="collapse"
                                        data-target="#sort_collapse">
                                        {" "}
                                        Sorting{" "}
                                        <i className="fa fa-chevron-down"></i>
                                    </h6>

                                    <div
                                        className="filter_div collapse show pl-2"
                                        id="sort_collapse">
                                        <input
                                            type="radio"
                                            name="c_sort"
                                            defaultValue="1"
                                            onChange={this.onChangeFilters}
                                            defaultChecked="true"
                                        />{" "}
                                        <span className="check_label">
                                            {" "}
                                            States A-Z
                                        </span>
                                        <br />
                                        <input
                                            type="radio"
                                            name="c_sort"
                                            defaultValue="2"
                                            onChange={this.onChangeFilters}
                                        />{" "}
                                        <span className="check_label">
                                            {" "}
                                            States Z-A
                                        </span>
                                        <br />
                                        <input
                                            type="radio"
                                            name="c_sort"
                                            defaultValue="3"
                                            onChange={this.onChangeFilters}
                                        />{" "}
                                        <span className="check_label">
                                            {" "}
                                            Colleges High-Low
                                        </span>
                                        <br />
                                        <input
                                            type="radio"
                                            name="c_sort"
                                            defaultValue="4"
                                            onChange={this.onChangeFilters}
                                        />{" "}
                                        <span className="check_label">
                                            {" "}
                                            Colleges Low-High
                                        </span>
                                        <br />
                                        {/* <input type="radio" name="c_sort" defaultValue="5" onChange={this.onChangeFilters} defaultChecked="true" /> <span className="check_label"> Popularity</span><br/> */}
                                        {/* <input type="radio" name="c_sort" defaultValue="6" onChange={this.onChangeFilters} /> <span className="check_label"> FMGE passing High-Low</span><br/> */}
                                    </div>

                                    <h6
                                        className="filter_header"
                                        data-toggle="collapse"
                                        data-target="#filter_collapse">
                                        {" "}
                                        Filters{" "}
                                        <i className="fa fa-chevron-down"></i>
                                    </h6>

                                    <div
                                        className="filter_div show"
                                        id="filter_collapse">
                                        <div className="filter_selector">
                                            <h6 className="filter_sub_header">
                                                {" "}
                                                Popular{" "}
                                            </h6>
                                            <input
                                                type="checkbox"
                                                name="c_popular"
                                                defaultValue="1"
                                                onChange={this.onChangeFilters}
                                            />{" "}
                                            <span className="check_label">
                                                {" "}
                                                Popular states
                                            </span>
                                            <br />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/*  Filter results display and loader */}
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                            {sub_loader ? (
                                <img
                                    src={Loader}
                                    className="filter_loader"
                                    alt="Loader"
                                />
                            ) : (
                                <div className="row">
                                    {states && states.length > 0 ? (
                                        states.map(state => (
                                            <div
                                                key={state.id}
                                                className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                                <div className="card state_card">
                                                    <div className="col p-5">
                                                        {/* <img src={process.env.PUBLIC_URL + '/india.jpeg'} alt="Banner"/> */}
                                                        {/* <div className="bg_overlay"></div> */}

                                                        <div className="col_details">
                                                            <center>
                                                                <h3
                                                                    style={{
                                                                        color: "#f37421"
                                                                    }}>
                                                                    {
                                                                        state.state_name
                                                                    }{" "}
                                                                </h3>
                                                            </center>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="button-group">
                                                            <button
                                                                className="abroad_btn mr-2"
                                                                onClick={() =>
                                                                    this.handleCallback(
                                                                        state.id,
                                                                        "",
                                                                        "",
                                                                        ""
                                                                    )
                                                                }>
                                                                {" "}
                                                                <i
                                                                    className="fa fa-phone"
                                                                    title="Request callback"></i>{" "}
                                                            </button>
                                                            <Link
                                                                to={{
                                                                    pathname:
                                                                        "/college_filters",
                                                                    search:
                                                                        "?c_state=" +
                                                                        state.id
                                                                }}>
                                                                <button className="ml-2 abroad_btn">
                                                                    {" "}
                                                                    <i className="fa fa-eye"></i>{" "}
                                                                    View{" "}
                                                                    {
                                                                        state.total_colleges
                                                                    }{" "}
                                                                    {state.total_colleges ===
                                                                    1
                                                                        ? "college"
                                                                        : "colleges"}{" "}
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col text-center no_results p-3">
                                            <img
                                                src={NoResultsIcon}
                                                className=""
                                                alt="No results"
                                                height="100"
                                            />
                                            <br />
                                            <br />
                                            <h6> No matching results found.</h6>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/*  Pagination container show when results are greater than 10  */}
                            {!sub_loader && total_count > 10 ? (
                                <form
                                    id="states_pagination_form"
                                    autoComplete="off">
                                    <div className="row pt-2">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left text-blue">
                                            <span className="small_text">
                                                View{" "}
                                            </span>
                                            <select
                                                name="c_page_size"
                                                className="form-control ml-2 w-auto d-inline"
                                                value={page_size}
                                                onChange={this.onChangeFilters}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <span className="small_text ml-2">
                                                {" "}
                                                results per page.{" "}
                                            </span>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
                                            <div className="pagination_container table-responsive">
                                                <Pagination
                                                    activePage={active_page}
                                                    itemsCountPerPage={
                                                        page_size
                                                    }
                                                    totalItemsCount={
                                                        total_count
                                                    }
                                                    pageRangeDisplayed={6}
                                                    onChange={
                                                        this.handlePageChange
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    {/* get in touch container */}
                    <div className="row interested_container">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                            <h2 className="main_header">
                                <b> Interested? </b>
                            </h2>
                            <h5 className="text-center mt-3">
                                {" "}
                                Request a free callback from our expert
                                cousellors to know more and get in touch with
                                us.
                            </h5>
                            <div className="mt-3">
                                {/* {is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2" onClick={this.handleLoginClick}> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>} */}
                                <button
                                    className="mt-2 abroad_btn py-2"
                                    onClick={() =>
                                        this.handleCallback("", "", "", "")
                                    }>
                                    {" "}
                                    <i className="fa fa-phone mr-2"></i> REQUEST
                                    CALLBACK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        states: state.state.states,
        sub_loader: state.home.sub_loader,
        page_size: state.state.page_size,
        page_count: state.state.page_count,
        total_count: state.state.total_count,
        is_auth: state.home.is_auth
    };
};

const mapActionsToProps = {
    onGetFilterStates: getFilterStates,
    onSetCallbackValues: setCallbackValues
};

export default connect(mapStatesToProps, mapActionsToProps)(StateFilters);
