import {
    GET_CUTOFF_SETTINGS_SUCCESS,
    GET_CUTOFFS_SUCCESS
} from "../actions/Cutoffs";

const initialState = {
    courses: [],
    authorities: [],
    quotas: [],
    categories: [],
    years: [],
    rounds: [],
    cutoffs: [],
    selected_settings: []
};

const Cutoff_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CUTOFF_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                courses: payload.courses,
                authorities: payload.authorities,
                quotas: payload.quotas,
                categories: payload.categories,
                rounds: payload.rounds,
                selected_settings: payload.selected_settings
            });

        case GET_CUTOFFS_SUCCESS:
            return Object.assign({}, state, {
                cutoffs: payload.cutoffs,
                selected_settings: payload.selected_settings
            });

        default:
            return state;
    }
};

export default Cutoff_reducer;
