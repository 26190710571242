import server from "../utils/Server";
import { subLoaderTrue, subLoaderFalse } from "./Home";

export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_COLLEGE_STATES = "GET_COLLEGE_STATES";

export const getFilterStates = filters => {
    return dispatch => {
        dispatch(subLoaderTrue());
        return server.get("/get_filter_states" + filters).then(response => {
            dispatch(subLoaderFalse());
            if (response.data.code === 200) {
                dispatch(getFilterStatesSuccess(response.data));
            }
        });
    };
};

export const getCollegeStates = () => {
    return dispatch => {
        return server.get("/get_college_states").then(response => {
            if (response.data.code === 200) {
                dispatch(getCollegeStatesSuccess(response.data));
            }
        });
    };
};

export const getFilterStatesSuccess = data => {
    return {
        type: GET_STATES_SUCCESS,
        payload: {
            states: data.states,
            page_size: data.page_size,
            page_count: data.page_count,
            total_count: data.total_count
        }
    };
};

export const getCollegeStatesSuccess = data => {
    return {
        type: GET_COLLEGE_STATES,
        payload: {
            college_states: data.states
        }
    };
};
