import moment from "moment";

const DateFormat = date => {
    var publishedAt = date;

    if (date !== " " && date !== undefined) {
        if (date.includes(" ")) {
            publishedAt = date.replace(" ", "T") + "+00:00";
        }
        var today = moment(new Date());
        var formated_date = new Date(getLocalDate(publishedAt)).toString();
        var published = moment(new Date(getLocalDate(publishedAt)));

        var seconds = today.diff(published, "seconds");
        var minutes = today.diff(published, "minutes");
        var hours = today.diff(published, "hours");
        var days = today.diff(published, "days");

        if (seconds < 60) {
            return "Just now";
        } else if (seconds >= 60 && minutes < 60) {
            return minutes + (minutes === 1 ? " min " : " mins ") + "ago";
        } else if (minutes >= 60 && hours < 24) {
            return hours + (hours === 1 ? " hour " : " hours ") + "ago";
        } else if (hours >= 24 && hours < 120) {
            return days + (days === 1 ? " day " : " days ") + "ago";
        } else if (hours >= 120) {
            return (
                formated_date.slice(4, 10) + ", " + formated_date.slice(10, 15)
            );
        }
    } else {
        return null;
    }
};

const getLocalDate = dateTime => {
    var dt = new Date(dateTime);
    var minutes = dt.getTimezoneOffset();
    dt = new Date(dt.getTime() + minutes * 60000);
    return dt;
};

export default DateFormat;
