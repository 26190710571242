import React from "react";
import "./EventCalendar.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Calendar from "../../assets/calendar.png";
import { getEventCalendar } from "../../actions/Events";

class EventCalendar extends React.Component {
    componentDidMount() {
        this.props.onGetEventCalendar();
    }

    render() {
        const { ongoing_events, upcoming_events, completed_events } =
            this.props.all_events;

        return (
            <div className="container-fluid">
                <div className="row states_container pt-5 my-3">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
                        <h3 className="header text-orange text-uppercase">
                            {" "}
                            Calendar{" "}
                        </h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3">
                        {/* Ongoing events */}
                        <div className="card p-4 text-left mb-4">
                            <h4 className="text-orange"> ONGOING</h4>
                            <hr className="hori_line" />
                            {ongoing_events > 0 ? (
                                <Link to={"/events/ongoing/month/year"}>
                                    <div className="row mt-2 py-3">
                                        <div className="col">
                                            <h6 className="text-blue text-capitalize">
                                                {" "}
                                                Ongoing / Today{" "}
                                            </h6>
                                        </div>
                                        <div className="col text-center">
                                            <h6 className="text-blue">
                                                {ongoing_events}
                                            </h6>
                                        </div>
                                        <div className="col text-right">
                                            <h6 className="fa fa-chevron-right text-orange"></h6>
                                        </div>
                                    </div>
                                    <hr className="m-0" />
                                </Link>
                            ) : (
                                <h6 className="text-center text-blue">
                                    No events found.
                                </h6>
                            )}
                        </div>

                        {/* Upcoming events */}
                        <div className="card p-4 text-left mb-4">
                            <h4 className="text-orange"> UPCOMING</h4>
                            <hr className="hori_line" />
                            {upcoming_events && upcoming_events.length > 0 ? (
                                upcoming_events.map((event, index) => (
                                    <Link
                                        to={
                                            "/events/upcoming/" +
                                            event.month +
                                            "/" +
                                            event.year
                                        }
                                        key={index}>
                                        <div className="row mt-2 py-3">
                                            <div className="col">
                                                <h6 className="text-blue text-capitalize">
                                                    {" "}
                                                    {event.month +
                                                        " - " +
                                                        event.year}{" "}
                                                </h6>
                                            </div>
                                            <div className="col text-center">
                                                <h6 className="text-blue">
                                                    {event.total_events}
                                                </h6>
                                            </div>
                                            <div className="col text-right">
                                                <h6 className="fa fa-chevron-right text-orange"></h6>
                                            </div>
                                        </div>
                                        <hr className="m-0" />
                                    </Link>
                                ))
                            ) : (
                                <h6 className="text-center text-blue">
                                    No events found.
                                </h6>
                            )}
                        </div>

                        {/* Completed events */}
                        <div className="card p-4 text-left">
                            <h4 className="text-orange"> HISTORY</h4>
                            <hr className="hori_line" />
                            {completed_events && completed_events.length > 0 ? (
                                completed_events.map((event, index) => (
                                    <Link
                                        to={
                                            "/events/completed/" +
                                            event.month +
                                            "/" +
                                            event.year
                                        }
                                        key={index}>
                                        <div className="row mt-2 py-3">
                                            <div className="col">
                                                <h6 className="text-blue text-capitalize">
                                                    {" "}
                                                    {event.month +
                                                        " - " +
                                                        event.year}{" "}
                                                </h6>
                                            </div>
                                            <div className="col text-center">
                                                <h6 className="text-blue">
                                                    {event.total_events}
                                                </h6>
                                            </div>
                                            <div className="col text-right">
                                                <h6 className="fa fa-chevron-right text-orange"></h6>
                                            </div>
                                        </div>
                                        <hr className="m-0" />
                                    </Link>
                                ))
                            ) : (
                                <h6 className="text-center text-blue">
                                    No events found.
                                </h6>
                            )}
                        </div>
                        <br />
                    </div>
                    <div className="col p-3">
                        <div className="card p-3">
                            <img src={Calendar} width="100%" alt="Calendar" />
                            <div
                                className="p-4 mt-4 bg-orange"
                                style={{
                                    background:
                                        "linear-gradient(to bottom right, #44a1a0, #172a3a)"
                                }}>
                                <h2 className="header text-white"> Calendar</h2>
                                <h5 className="header text-white my-3">
                                    <i className="fa fa-check-circle text-white"></i>{" "}
                                    NEET counselling events.
                                </h5>
                                <h5 className="header text-white my-3">
                                    <i className="fa fa-check-circle text-white"></i>{" "}
                                    Counselling events.
                                </h5>
                                <h5 className="header text-white my-3">
                                    <i className="fa fa-check-circle text-white"></i>{" "}
                                    Result updates events.
                                </h5>
                                <h5 className="header text-white my-3">
                                    <i className="fa fa-check-circle text-white"></i>{" "}
                                    Aplication events.
                                </h5>
                                <h5 className="header text-white my-3">
                                    <i className="fa fa-check-circle text-white"></i>{" "}
                                    Exam dates.
                                </h5>
                                <h5 className="header text-white my-3">
                                    <i className="fa fa-check-circle text-white"></i>{" "}
                                    Remind events easily.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        all_events: state.event.all_events
    };
};

const mapActionsToProps = {
    onGetEventCalendar: getEventCalendar
};

export default connect(mapStatesToProps, mapActionsToProps)(EventCalendar);
