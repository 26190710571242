import axios from "axios";

const server = axios.create({
   //    baseURL : 'http://localhost:8000/api/user',
    //baseURL: "https://www.goocampus.in/goocampus_new/api/user",
    //baseURL:"https://api.stage.goocampus.in/api/user",
    baseURL:"https://api.prod.goocampus.in/api/user",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
    }
});

export default server;
