import React from "react";
import "./Cutoffs.css";
import { Link } from "react-router-dom";
import NoResultsIcon from "../../assets/no_results.png";
import { connect } from "react-redux";
import { getCourseCutoffs } from "../../actions/Cutoffs";

class Cutoffs extends React.Component {
    componentDidMount = () => {
        this.props.onGetCourseCutoffs();
    };

    render() {
        const { cutoffs } = this.props;

        return (
            <div className="container-fluid min_height">
                <div className="row states_container pt-5 my-3">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
                        <div className="row sort_container">
                            <div className="col"></div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <h3 className="text-center text-orange text-uppercase">
                                    {" "}
                                    CUTOFFS{" "}
                                </h3>
                            </div>
                            <div className="col text-right">
                                <Link to="/cutoff_settings">
                                    <button className="abroad_btn">
                                        <i className="fa fa-cogs mr-1"></i>{" "}
                                        Settings
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Display cutoffs */}
                {cutoffs && cutoffs.length > 0 ? (
                    <div className="row">
                        {cutoffs.map(cutoff => (
                            <div
                                key={cutoff.id}
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3 d-flex">
                                <div className="card p-3 w-100">
                                    <span className="small_text">
                                        Round {cutoff.cutoff_round}{" "}
                                        <span className="float-right">
                                            {cutoff.cutoff_year}
                                        </span>
                                    </span>
                                    <Link
                                        to={
                                            "/college_details/" +
                                            cutoff.col_name
                                        }>
                                        <h5 className="text-blue my-2">
                                            {cutoff.col_name}
                                        </h5>
                                    </Link>
                                    <h5 className="text-orange">
                                        Cutoff : {cutoff.cutoff_rank}
                                    </h5>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="row">
                        <div className="col text-center no_results p-3">
                            <img
                                src={NoResultsIcon}
                                alt="No results"
                                height="100"
                            />
                            <br />
                            <br />
                            <h6 className="text-blue">
                                No cutoffs found, <br />
                                <br /> Choose your interested course,
                                counselling authority, quota and category to
                                view cutoffs.
                            </h6>
                            {/* <button className="abroad_btn" style={[Styles.submit_btn, {padding: 10}]}>Choose now</button> */}
                        </div>
                    </div>
                )}
                <br />
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        cutoffs: state.cutoff.cutoffs
    };
};

const mapActionsToProps = {
    onGetCourseCutoffs: getCourseCutoffs
};

export default connect(mapStatesToProps, mapActionsToProps)(Cutoffs);
