import React from "react";
import "../AboutUs/AboutUs.css";
import { connect } from "react-redux";
import $ from "jquery";
import { setCallbackValues } from "../../actions/Callbacks";

class TermsConditions extends React.Component {
    constructor(props) {
        super(props);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    handleCallback = (state, college, stream, level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, level);
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { is_auth } = this.props;

        return (
            <div className="row">
                <div className="col about_us_container mt-3">
                    <h3 className="text-center text-orange text-uppercase">
                        {" "}
                        Terms and conditions{" "}
                    </h3>

                    <div className="row description_card">
                        <div className="col">
                            <p className="para">
                                The terms "We" / "Us" / "Our" / "Company"
                                individually and collectively refer to GooCampus
                                Edu Solutions Private Limited and the terms
                                "Visitor" and "User" refer to the users.
                            </p>
                            <p className="para">
                                This page states the Terms and Conditions under
                                which you (Visitor) may visit this website
                                (“Website”). Please read this page carefully. If
                                you do not accept the Terms and Conditions
                                stated here, we would request you to exit this
                                site. The business, any of its business
                                divisions and / or its companies or subsidiaries
                                to subsidiaries or such other investment
                                companies (in India or abroad) reserve their
                                respective rights to revise these Terms and
                                Conditions at any time by updating this posting.
                                You should visit this page periodically to
                                re-appraise yourself of the Terms and
                                Conditions, because they are binding on all
                                users of this Website.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header">Use of content </h4>
                            <p className="para">
                                All logos, brands, marks headings, labels,
                                names, signatures, numerals, shapes or any
                                combinations thereof, appearing in this site,
                                except as otherwise noted, are properties either
                                owned, or used under license, by the business
                                and / or its associate entities who feature on
                                this Website. The use of these properties or any
                                other content on this site, except as provided
                                in these terms and conditions or in the site
                                content, is strictly prohibited.
                            </p>
                            <p className="para">
                                You may not sell or modify the content of this
                                Website or reproduce, display, publicly perform,
                                distribute, or otherwise use the materials in
                                any way for any public or commercial purpose
                                without the respective organization’s or
                                entity’s written permission.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Acceptable website use </h4>
                            <p className="para">
                                Security Rules Visitors are prohibited from
                                violating or attempting to violate the security
                                of the Web site, including, without limitation,
                                (1) accessing data not intended for such user or
                                logging into a server or account which the user
                                is not authorized to access, (2) attempting to
                                probe, scan or test the vulnerability of a
                                system or network or to breach security or
                                authentication measures without proper
                                authorization, (3) attempting to interfere with
                                service to any user, host or network, including,
                                without limitation, via means of submitting a
                                virus or "Trojan horse" to the Website,
                                overloading, "flooding", "mail bombing" or
                                "crashing", or (4) sending unsolicited
                                electronic mail, including promotions and/or
                                advertising of products or services. Violations
                                of system or network security may result in
                                civil or criminal liability. The business and /
                                or its associate entities will have the right to
                                investigate occurrences that they suspect as
                                involving such violations and will have the
                                right to involve, and cooperate with, law
                                enforcement authorities in prosecuting users who
                                are involved in such violations.
                            </p>
                            <p className="para">
                                General Rules Visitors may not use the Website
                                in order to transmit, distribute, store or
                                destroy material (a) that could constitute or
                                encourage conduct that would be considered a
                                criminal offence or violate any applicable law
                                or regulation, (b) in a manner that will
                                infringe the copyright, trademark, trade secret
                                or other intellectual property rights of others
                                or violate the privacy or publicity of other
                                personal rights of others, or (c) that is
                                libelous, defamatory, pornographic, profane,
                                obscene, threatening, abusive or hateful.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Indemnity </h4>
                            <p className="para">
                                The User unilaterally agree to indemnify and
                                hold harmless, without objection, the Company,
                                its officers, directors, employees and agents
                                from and against any claims, actions and/or
                                demands and/or liabilities and/or losses and/or
                                damages whatsoever arising from or resulting
                                from their use of www.goocampus.in or their
                                breach of the terms.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header"> Liability </h4>
                            <p className="para">
                                User agrees that neither Company nor its group
                                companies, directors, officers or employees
                                shall be liable for any direct and/or indirect
                                and/or incidental and/or special and/or
                                consequential and/or exemplary damages,
                                resulting from the use and/or the inability to
                                use the service and/or for cost of procurement
                                of substitute goods and/or services or resulting
                                from any goods and/or data and/or information
                                and/or services purchased and/or obtained and/or
                                messages received and/or transactions entered
                                into through and/or from the service and/or
                                resulting from unauthorized access to and/or
                                alteration of user's transmissions and/or data
                                and/or arising from any other matter relating to
                                the service, including but not limited to,
                                damages for loss of profits and/or use and/or
                                data or other intangible, even if Company has
                                been advised of the possibility of such damages.
                            </p>
                            <p className="para">
                                User further agrees that Company shall not be
                                liable for any damages arising from
                                interruption, suspension or termination of
                                service, including but not limited to direct
                                and/or indirect and/or incidental and/or special
                                consequential and/or exemplary damages, whether
                                such interruption and/or suspension and/or
                                termination was justified or not, negligent or
                                intentional, inadvertent or advertent.
                            </p>
                            <p className="para">
                                User agrees that Company shall not be
                                responsible or liable to user, or anyone, for
                                the statements or conduct of any third party of
                                the service. In sum, in no event shall Company's
                                total liability to the User for all damages
                                and/or losses and/or causes of action exceed the
                                amount paid by the User to Company, if any, that
                                is related to the cause of action.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <h4 className="header">
                                {" "}
                                Disclaimer of consequential damages{" "}
                            </h4>
                            <p className="para">
                                In no event shall Company or any parties,
                                organizations or entities associated with the
                                corporate brand name us or otherwise, mentioned
                                at this Website be liable for any damages
                                whatsoever (including, without limitations,
                                incidental and consequential damages, lost
                                profits, or damage to computer hardware or loss
                                of data information or business interruption)
                                resulting from the use or inability to use the
                                Website and the Website material, whether based
                                on warranty, contract, tort, or any other legal
                                theory, and whether or not, such organization or
                                entities were advised of the possibility of such
                                damages.
                            </p>
                        </div>
                    </div>

                    <div className="row description_card">
                        <div className="col">
                            <p className="para">
                                By using the website and/or registering yourself
                                at www.goocampus.in, you authorise us to contact
                                you via email or phone call or sms and offer you
                                our services, imparting product knowledge, offer
                                promotional offers running on website & offers
                                offered by the associated third parties, for
                                which reasons, personally identifiable
                                information may be collected.
                            </p>
                            <p className="para">
                                And irrespective of the fact if also you have
                                registered yourself under DND or DNC or NCPR
                                service, you still authorise us to give you a
                                call from GooCampus for the above mentioned
                                purposes till 365 days of your registration with
                                us.
                            </p>
                            <p className="para">
                                This Privacy Policy covers GooCampus treatment
                                of personally identifiable information that
                                GooCampus collects when you are on the GooCampus
                                site, and when you use GooCampus services.
                            </p>
                            <p className="para">
                                This policy also covers GooCampus treatment of
                                personally identifiable information that
                                GooCampus business partner shares with
                                GooCampus. This policy does not apply to the
                                certain GooCampus products or services, when you
                                visit GooCampus pages, and when you enter
                                promotions.
                            </p>
                            <p className="para">
                                GooCampus may also receive personally
                                identifiable information from our business
                                partners. When you registered with GooCampus, we
                                may ask for your first name, last name, contact
                                no, email, location and gender. Once you
                                register with GooCampus and sign in to our
                                services, you are not anonymuous to us. Also
                                during registration, you may be requested to
                                register your mobile phone and email id, pager,
                                or other device to receive text messages, and
                                other services to your wireless device.
                            </p>
                            <p className="para">
                                By registration you authorize us to send
                                sms/email alerts to you for your login details
                                and any other service requirements or some
                                advertising messages/emails from us.
                            </p>
                        </div>
                    </div>

                    {/* get in touch container */}
                    <div className="row interested_container">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                            <h2 className="main_header">
                                <b> Interested? </b>
                            </h2>
                            <h5 className="text-center mt-3">
                                {" "}
                                Request a free callback from our expert
                                cousellors to know more and get in touch with
                                us.
                            </h5>
                            <div className="mt-3">
                                {/* {is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2" onClick={this.handleLoginClick}> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>} */}
                                <button
                                    className="mt-2 abroad_btn py-2"
                                    onClick={() =>
                                        this.handleCallback("", "", "", "")
                                    }>
                                    {" "}
                                    <i className="fa fa-phone mr-2"></i> REQUEST
                                    CALLBACK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth
    };
};

const mapActionsToProps = {
    onSetCallbackValues: setCallbackValues
};

export default connect(mapStatesToProps, mapActionsToProps)(TermsConditions);
