import server from "../utils/Server";
import { mainLoadingFalse, mainLoadingTrue, displayError } from "./Home";

export const GET_STREAMS_SUCCESS = "GET_STREAMS_SUCCESS";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const GET_STATE_COLLEGES_SUCCESS = "GET_STATE_COLLEGES_SUCCESS";
export const GET_BENEFITS_SUCCCESS = "GET_BENEFITS_SUCCCESS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";

// Call all the streams for dropdown
export const getDropDownStreams = () => {
    return dispatch => {
        return server
            .get("/get_dropdown_streams")
            .then(response => {
                const { data } = response;
                if (data.code === 200)
                    dispatch(getDropDownStreamsSuccess(data.streams));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Get streams success action
export const getDropDownStreamsSuccess = streams => {
    return {
        type: GET_STREAMS_SUCCESS,
        payload: {
            streams: streams
        }
    };
};

// Call all the course levels for dropdown
export const getStreamCourseLevels = stream_id => {
    return dispatch => {
        return server
            .get("/get_stream_course_levels?stream_id=" + stream_id)
            .then(response => {
                const { data } = response;
                if (data.code === 200)
                    dispatch(getLevelsSuccess(data.course_levels));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Call get course levels success action
export const getLevelsSuccess = course_levels => {
    return {
        type: GET_LEVELS_SUCCESS,
        payload: {
            course_levels: course_levels
        }
    };
};

export const getCourseLevels = () => {
    return dispatch => {
        return server
            .get("/get_course_levels")
            .then(response => {
                const { data } = response;
                if (data.code === 200)
                    dispatch(getLevelsSuccess(data.course_levels));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const getStateColleges = state_id => {
    return dispatch => {
        return server.get("/get_state_colleges/" + state_id).then(response => {
            if (response.data.code === 200) {
                dispatch(onGetStateColleges(response.data.state_colleges));
            }
        });
    };
};

export const onGetStateColleges = state_colleges => {
    return {
        type: GET_STATE_COLLEGES_SUCCESS,
        payload: {
            state_colleges: state_colleges
        }
    };
};

export const getPlanBenefits = () => {
    return dispatch => {
        return server.get("/get_plan_benefits").then(response => {
            if (response.data.code === 200) {
                dispatch(onGetBenefitsSuccess(response.data.benefits));
            }
        });
    };
};

export const onGetBenefitsSuccess = benefits => {
    return {
        type: GET_BENEFITS_SUCCCESS,
        payload: {
            benefits: benefits
        }
    };
};

export const getMembershipPlans = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());

        return server
            .get("/get_membership_plans")
            .then(response => {
                dispatch(mainLoadingFalse());
                if (response.data.code === 200) {
                    dispatch(onGetPlansSuccess(response.data.member_plans));
                } else {
                    dispatch(displayError("Failed, try again later"));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const onGetPlansSuccess = member_plans => {
    return {
        type: GET_PLANS_SUCCESS,
        payload: {
            member_plans: member_plans
        }
    };
};

export const getPlanDetails = plan_name => {
    return dispatch => {
        dispatch(mainLoadingTrue());

        return server
            .get("/get_plan_details/" + plan_name)
            .then(response => {
                dispatch(mainLoadingFalse());
                if (response.data.code === 200) {
                    dispatch(
                        onGetPlanDetailsSuccess(response.data.member_plan)
                    );
                } else {
                    dispatch(displayError("Failed, try again later"));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const onGetPlanDetailsSuccess = member_plan => {
    return {
        type: GET_PLAN_SUCCESS,
        payload: {
            member_plan: member_plan
        }
    };
};

// Get youtube videos
export const getYoutubeVideos = filters => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return fetch(
            "https://www.googleapis.com/youtube/v3/search?key=AIzaSyDr1ibyMegmJ6rl_RVhsbh87JdWyfcEoDg&channelId=UCmo54Vb1QG6YoBplgmIh0TQ&part=snippet,id&order=date&maxResults=12&" +
                filters
        )
            .then(res => res.json())
            .then(response => {
                dispatch(mainLoadingFalse());
                dispatch(getVideosSuccess(response));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Videos success action
export const getVideosSuccess = videos => {
    return {
        type: GET_VIDEOS_SUCCESS,
        payload: {
            videos: videos
        }
    };
};
