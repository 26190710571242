import server from "../utils/Server";
import { mainLoadingTrue, mainLoadingFalse, displayError } from "./Home";

export const GET_CALENDAR_SUCCESS = "GET_CALENDAR_SUCCESS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";

export const getEventCalendar = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .get("/get_all_events")
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (data.code === 200)
                    dispatch(getCalendarSuccess(data.all_events));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const getCalendarSuccess = all_events => {
    return {
        type: GET_CALENDAR_SUCCESS,
        payload: {
            all_events: all_events
        }
    };
};

export const getFilteredEvents = filters => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .get("/get_events_filtered?" + filters)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (data.code === 200) dispatch(getEventsSuccess(data.events));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const getEventsSuccess = events => {
    return {
        type: GET_EVENTS_SUCCESS,
        payload: {
            events: events
        }
    };
};
