import React from "react";
import "./Videos.css";
import { connect } from "react-redux";
import NoResultsIcon from "../../assets/no_results.png";
import { getYoutubeVideos } from "../../actions/Listings";
import DateFormat from "../../utils/DateFormat";
import YouTube from "react-youtube";

class Videos extends React.Component {
    constructor(props) {
        super(props);
        this.state = { videos: [] };
        this._handleChangePage = this._handleChangePage.bind(this);
    }

    componentDidMount = () => {
        this.props.onGetYoutubeVideos();
    };

    _handleChangePage = token => {
        var filters = "pageToken=" + token;
        this.props.onGetYoutubeVideos(filters);
    };

    render() {
        const { videos } = this.props;
        const opts = {
            height: "200",
            width: "100%",
            playerVars: {
                autoplay: 0
            }
        };

        return (
            <div className="container-fluid mt-5 pt-4">
                <div className="row states_container">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
                        <h3 className="header text-orange text-uppercase">
                            {" "}
                            Goocampus TV{" "}
                        </h3>
                        {/* {videos.pageInfo ? <span className="small_text">Showing {videos.pageInfo.resultsPerPage} of {videos.pageInfo.totalResults} videos.</span> : null } */}
                    </div>
                </div>

                <div className="row mt-4">
                    {videos.items && videos.items.length > 0 ? (
                        videos.items.map((video, index) => (
                            <div
                                key={index}
                                className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-3 pb-3 d-flex">
                                <div className="card w-100 p-2">
                                    {/* Play youtube videos */}
                                    <YouTube
                                        videoId={video.id.videoId}
                                        opts={opts}
                                    />

                                    {/* Display video description */}
                                    <div className="mt-3">
                                        <h6 className="text-blue">
                                            {video.snippet.title}
                                        </h6>
                                        <p className="small_text mb-1">
                                            {video.snippet.description}
                                        </p>
                                        <p className="date_text mb-1">
                                            {DateFormat(
                                                video.snippet.publishedAt
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col text-center no_results p-3">
                            <img
                                src={NoResultsIcon}
                                alt="No results"
                                height="100"
                            />
                            <br />
                            <br />
                            <h6>No videos found.</h6>
                        </div>
                    )}
                </div>

                {/* Show pagination */}
                {videos.items && videos.items.length > 0 ? (
                    <div className="row mt-3 mb-5">
                        <div className="col">
                            {videos.prevPageToken ? (
                                <button
                                    className="abroad_btn"
                                    onClick={() =>
                                        this._handleChangePage(
                                            videos.prevPageToken
                                        )
                                    }>
                                    <i className="fa fa-chevron-circle-left"></i>{" "}
                                    Previous
                                </button>
                            ) : null}
                            {videos.nextPageToken ? (
                                <button
                                    className="abroad_btn float-right"
                                    onClick={() =>
                                        this._handleChangePage(
                                            videos.nextPageToken
                                        )
                                    }>
                                    Next{" "}
                                    <i className="fa fa-chevron-circle-right"></i>{" "}
                                </button>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        videos: state.list.videos
    };
};

const mapActionsToProps = {
    onGetYoutubeVideos: getYoutubeVideos
};

export default connect(mapStatesToProps, mapActionsToProps)(Videos);
