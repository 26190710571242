import {
    GET_COLLEGES_SUCCESS,
    GET_COLLEGE_SUCCESS,
    COLLEGE_COMPARE_SUCCESS
} from "../actions/Colleges";

const initialState = {
    colleges: [],
    college_details: {},
    page_count: 0,
    page_size: 10,
    total_count: 0,
    states: [],
    compare_collages: []
};

const College_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_COLLEGES_SUCCESS:
            return Object.assign({}, state, {
                colleges: payload.colleges,
                page_count: payload.page_count,
                page_size: payload.page_size,
                total_count: payload.total_count,
                states: payload.states
            });

        case GET_COLLEGE_SUCCESS:
            return Object.assign({}, state, {
                college_details: payload.college
            });

        case COLLEGE_COMPARE_SUCCESS:
            return Object.assign({}, state, {
                compare_collages: payload.colleges
            });

        default:
            return state;
    }
};

export default College_reducer;
