import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class HomeSearchAutocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: ""
        };
    }

    // Event fired when the input value is changed
    onChange = e => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    // Event fired when the user clicks on a suggestion
    onClick = e => {
        var collegeName = e.currentTarget.innerText.replace(/ /g, "+");
        window.location.href = "/#/college_details/" + collegeName;

        // Update the user input and reset the rest of the state
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
    };

    // Event fired when the user presses a key down
    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13) {
            var collegeName = filteredSuggestions[activeSuggestion].replace(
                / /g,
                "+"
            );
            window.location.href = "/#/college_details/" + collegeName;

            this.setState({
                // activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <div className="autocomplete_results">
                        <ul className="suggestions">
                            {filteredSuggestions.map((suggestion, index) => {
                                let className;

                                // Flag the active suggestion with a class
                                if (index === activeSuggestion) {
                                    className = "suggestion-active";
                                }

                                return (
                                    <li
                                        className={className}
                                        key={index}
                                        onClick={onClick}>
                                        {suggestion}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            } else {
                suggestionsListComponent = (
                    <div className="autocomplete_results">
                        <div className="no-suggestions">
                            <span className="small_text">
                                No results found.
                            </span>
                        </div>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    name="c_search_key"
                    type="search"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={userInput}
                    className="form-control"
                    placeholder="Search for colleges"
                    autoComplete="off"
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default HomeSearchAutocomplete;
