import { GET_NEWS_SUCCESS, GET_NEWS_DETAILS } from "../actions/NewsUpdates";

const initialState = {
    news_updates: [],
    total_count: 0,
    page_count: 0,
    page_size: 0,
    categories: [],
    selected_categories: [],
    news: {},
    related_news: []
};

const News_update_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_NEWS_SUCCESS:
            return Object.assign({}, state, {
                news_updates: payload.news_updates,
                total_count: payload.total_count,
                page_count: payload.page_count,
                page_size: payload.page_size,
                categories: payload.categories,
                selected_categories: payload.selected_categories
            });

        case GET_NEWS_DETAILS:
            return Object.assign({}, state, {
                news: payload.news,
                related_news: payload.related_news
            });

        default:
            return state;
    }
};

export default News_update_reducer;
