import React from "react";
import "./EditProfile";
import swal from "sweetalert";
import { connect } from "react-redux";
import { updateCutoffSettings, getCutoffSettings } from "../../actions/Cutoffs";

class CutoffSettings extends React.Component {
    constructor(props) {
        super(props);
        const { selected_settings } = this.props;
        this.state = {
            course: selected_settings ? selected_settings.course : "",
            authority: selected_settings ? selected_settings.authority : "",
            quota: selected_settings ? selected_settings.quota : "",
            category: selected_settings ? selected_settings.category : "",
            round: selected_settings ? selected_settings.round : ""
        };
        this.handleChangeSettings = this.handleChangeSettings.bind(this);
    }

    componentWillMount() {
        this.props.onGetCutoffSettings();
    }

    handleChangeSettings = e => {
        const { name, value } = e.target;
        var filters = "";

        if (name === "int_course") {
            this.setState({ course: value });
            this.props.onGetCutoffSettings("course=" + value);
        } else if (name === "int_authority") {
            this.setState({ authority: value });
            this.props.onGetCutoffSettings(
                "course=" + this.state.course + "&authority=" + value
            );
        } else if (name === "int_quota") {
            this.setState({ quota: value });
            this.props.onGetCutoffSettings(
                "course=" +
                    this.state.course +
                    "&authority=" +
                    this.state.authority +
                    "&quota=" +
                    value
            );
        } else if (name === "int_category") {
            this.setState({ category: value });
            this.props.onGetCutoffSettings(
                "course=" +
                    this.state.course +
                    "&authority=" +
                    this.state.authority +
                    "&quota=" +
                    this.state.quota +
                    "&category=" +
                    value
            );
        } else {
            this.setState({ round: value });
        }
    };

    handleUpdateSettings = e => {
        e.preventDefault();

        swal({
            title: "Are you sure?",
            text: "To update your settings?",
            buttons: ["Cancel", "Update"]
        }).then(willRequest => {
            if (willRequest) {
                var formData = new FormData(
                    document.getElementById("cutoff_settings_form")
                );
                this.props.onUpdateSettings(formData);
            }
        });
    };

    render() {
        const { auth_user, courses, authorities, quotas, categories, rounds } =
            this.props;
        const { course, authority, quota, category, round } = this.state;

        return (
            <div className="row mt-4 pt-4">
                <div className="col">
                    <div className="edit_profile_container text-center mt-5">
                        <h2 className="sub_header mb-4">
                            <i className="fa fa-cogs mr-1"></i> CUTOFF SETTINGS{" "}
                        </h2>

                        <div className="row">
                            <div className="col"></div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="edit_profile">
                                    <p className="para mb-3">
                                        Choose course, counselling authority,
                                        quota, category, year and round you're
                                        interested in so we can personlise your
                                        cutoffs.{" "}
                                    </p>

                                    <form
                                        className="mt-3"
                                        id="cutoff_settings_form"
                                        onSubmit={this.handleUpdateSettings}>
                                        <div className="form-group">
                                            <label className="small_text">
                                                {" "}
                                                Choose course * :{" "}
                                            </label>
                                            <select
                                                name="int_course"
                                                className="form-control"
                                                value={course ? course : ""}
                                                onChange={
                                                    this.handleChangeSettings
                                                }
                                                required>
                                                <option value="">
                                                    {" "}
                                                    Interested course{" "}
                                                </option>
                                                {courses && courses.length > 0
                                                    ? courses.map(course => (
                                                          <option
                                                              key={course.id}
                                                              value={course.id}>
                                                              {
                                                                  course.course_name
                                                              }
                                                          </option>
                                                      ))
                                                    : ""}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">
                                                {" "}
                                                Choose authority * :{" "}
                                            </label>
                                            <select
                                                name="int_authority"
                                                className="form-control"
                                                value={
                                                    authority ? authority : ""
                                                }
                                                onChange={
                                                    this.handleChangeSettings
                                                }
                                                required>
                                                <option value="">
                                                    {" "}
                                                    Interested authority{" "}
                                                </option>
                                                {authorities &&
                                                authorities.length > 0
                                                    ? authorities.map(
                                                          authority => (
                                                              <option
                                                                  key={
                                                                      authority.id
                                                                  }
                                                                  value={
                                                                      authority.id
                                                                  }>
                                                                  {
                                                                      authority.author_code
                                                                  }
                                                              </option>
                                                          )
                                                      )
                                                    : ""}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">
                                                {" "}
                                                Choose quota * :{" "}
                                            </label>
                                            <select
                                                name="int_quota"
                                                className="form-control"
                                                value={quota ? quota : ""}
                                                onChange={
                                                    this.handleChangeSettings
                                                }
                                                required>
                                                <option value="">
                                                    {" "}
                                                    Interested quota{" "}
                                                </option>
                                                {quotas && quotas.length > 0
                                                    ? quotas.map(quota => (
                                                          <option
                                                              key={quota.id}
                                                              value={quota.id}>
                                                              {quota.quota}
                                                          </option>
                                                      ))
                                                    : ""}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">
                                                {" "}
                                                Choose category * :{" "}
                                            </label>
                                            <select
                                                name="int_category"
                                                className="form-control"
                                                value={category ? category : ""}
                                                onChange={
                                                    this.handleChangeSettings
                                                }
                                                required>
                                                <option value="">
                                                    {" "}
                                                    Interested category{" "}
                                                </option>
                                                {categories &&
                                                categories.length > 0
                                                    ? categories.map(
                                                          category => (
                                                              <option
                                                                  key={
                                                                      category.id
                                                                  }
                                                                  value={
                                                                      category.id
                                                                  }>
                                                                  {
                                                                      category.category
                                                                  }
                                                              </option>
                                                          )
                                                      )
                                                    : ""}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">
                                                {" "}
                                                Choose round * :{" "}
                                            </label>
                                            <select
                                                name="int_round"
                                                className="form-control"
                                                value={round ? round : ""}
                                                onChange={
                                                    this.handleChangeSettings
                                                }
                                                required>
                                                <option value="">
                                                    {" "}
                                                    Interested round{" "}
                                                </option>
                                                {rounds && rounds.length > 0
                                                    ? rounds.map(round => (
                                                          <option
                                                              key={round.id}
                                                              value={round.id}>
                                                              {round.round}
                                                          </option>
                                                      ))
                                                    : ""}
                                            </select>
                                        </div>

                                        <div className="form-group mt-4">
                                            <button
                                                type="submit"
                                                className="abroad_btn">
                                                {" "}
                                                <i className="fa fa-check"></i>{" "}
                                                UPDATE SETTINGS{" "}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        courses: state.cutoff.courses,
        authorities: state.cutoff.authorities,
        quotas: state.cutoff.quotas,
        categories: state.cutoff.categories,
        years: state.cutoff.years,
        rounds: state.cutoff.rounds,
        selected_settings: state.cutoff.selected_settings
    };
};

const mapActionsToProps = {
    onUpdateSettings: updateCutoffSettings,
    onGetCutoffSettings: getCutoffSettings
};

export default connect(mapStatesToProps, mapActionsToProps)(CutoffSettings);
