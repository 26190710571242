import React from "react";
import "./NewsUpdates";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getNewsDetails } from "../../actions/NewsUpdates";
import Loader from "../../assets/loader.gif";
import NoResultsIcon from "../../assets/no_results.png";
import DateFormat from "../../utils/DateFormat";

class NewsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { news_id: this.props.match.params.news_id };
        this._handleRelatedNews = this._handleRelatedNews.bind(this);
    }

    componentDidMount() {
        this.props.onGetNewsDetails(this.state.news_id);
    }

    _handleRelatedNews = news_id => {
        this.props.onGetNewsDetails(news_id);
    };

    render() {
        const { is_auth, img_url, sub_loader, news, related_news } = this.props;
        console.log(img_url)
        return (
            <div className="container-fluid states_container pt-5 my-5">
                {/* Breadcrumbs and results counter container */}
                <div className="row sort_container">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                        <nav area-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>{" "}
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/news_updates">News feed</Link>{" "}
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    area-current="page">
                                    News details
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col text-center">
                        <h3 className="header text-orange text-uppercase">
                            {" "}
                            News feed{" "}
                        </h3>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"></div>
                </div>

                {/*  News feed container */}
                <div className="row px-1 pt-3">
                    {/*  Filter results display and loader */}
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                        {sub_loader ? (
                            <div className="text-center">
                                <img
                                    src={Loader}
                                    className="filter_loader"
                                    alt="Loader"
                                />
                            </div>
                        ) : (
                            <div className="card">
                                {news && news !== "" ? (
                                    <div className="p-3">
                                        <h5 className="filter_header text-capitalize pl-0 text-orange">
                                            {news.news_title}
                                        </h5>
                                        {news.news_authority ? (
                                            <h6 className="text-blue">
                                                {
                                                    news.news_authority
                                                        .author_name
                                                }{" "}
                                                {news.news_authority.state &&
                                                news.news_authority.state !== ""
                                                    ? ", " +
                                                      news.news_authority.state
                                                    : null}{" "}
                                            </h6>
                                        ) : null}

                                        <div>
                                            {news.created_at ? (
                                                <span className="small_text">
                                                    {" "}
                                                    Posted :{" "}
                                                    {DateFormat(
                                                        news.created_at.slice(
                                                            0,
                                                            10
                                                        )
                                                    )}
                                                </span>
                                            ) : null}
                                            {news.news_category ? (
                                                <span className="small_text float-right">
                                                    {
                                                        news.news_category
                                                            .category
                                                    }
                                                </span>
                                            ) : null}
                                        </div>
                                        <hr className="my-2" />
                                        <p className="text-blue medium_text">
                                            {news.brief_descr}{" "}
                                        </p>
                                        <p className="text-blue medium_text">
                                            {news.detailed_descr}{" "}
                                        </p>

                                        <div className="mt-4">
                                            {/* check for new link */}
                                            {news.news_link &&
                                            news.news_link !== "" ? (
                                                <a
                                                    href={news.news_link}
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    <button className="abroad_btn">
                                                        <i className="fa fa-link"></i>{" "}
                                                        Link
                                                    </button>
                                                </a>
                                            ) : (
                                                <button
                                                    className="abroad_btn"
                                                    disabled={true}>
                                                    <i className="fa fa-link"></i>{" "}
                                                    Link
                                                </button>
                                            )}

                                            {/* check for new file */}
                                            {news.news_file &&
                                            news.news_file !== "" ? (
                                                <a
                                                    href={
                                                        "https://api.prod.goocampus.in/"+ news.news_file
                                                    }
                                                    rel="noopener noreferrer"
                                                    download
                                                    target="_blank"
                                                    className="float-right">
                                                    <button className="abroad_btn">
                                                        <i className="fa fa-download"></i>{" "}
                                                        File
                                                    </button>
                                                </a>
                                            ) : (
                                                <button
                                                    className="abroad_btn float-right"
                                                    disabled={true}>
                                                    <i className="fa fa-download"></i>{" "}
                                                    File
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col text-center no_results p-3">
                                        <img
                                            src={NoResultsIcon}
                                            alt="No results"
                                            height="100"
                                        />
                                        <br />
                                        <br />
                                        <h6>No matching results found.</h6>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Related post of same authority */}
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 px-2 mb-4 card">
                        <div className="p-2">
                            <h5 className="filter_header pl-0">
                                Related posts
                            </h5>
                            {related_news && related_news.length > 0 ? (
                                related_news.map(news => (
                                    <div key={news.id} className="mt-2">
                                        <a
                                            onClick={() =>
                                                this._handleRelatedNews(news.id)
                                            }>
                                            <h6 className="text-blue mb-0 text-capitalize">
                                                {news.news_title}{" "}
                                            </h6>
                                            <span className="small_text">
                                                {" "}
                                                Posted :{" "}
                                                {DateFormat(
                                                    news.created_at.slice(0, 10)
                                                )}
                                            </span>
                                            <hr />
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center text-blue my-3">
                                    No news found.
                                </p>
                            )}

                            {/* Show browse more button */}
                            {related_news && related_news.length > 5 ? (
                                <div className="text-center my-3">
                                    <Link to="/news_updates">
                                        <button className="abroad_btn">
                                            Browse more
                                        </button>
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        img_url: state.home.img_url,
        sub_loader: state.home.sub_loader,
        news: state.news.news,
        related_news: state.news.related_news
    };
};

const mapActionsToProps = {
    onGetNewsDetails: getNewsDetails
};

export default connect(mapStatesToProps, mapActionsToProps)(NewsDetails);
