const DateFormat = props => {
    if (props) {
        let months = [];
        if (props.type == 2)
            months = [
                "Jan",
                "Feb",
                "Mar",
                "April",
                "May",
                "June",
                "July",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
                "Dec"
            ];
        else
            months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];

        var dt = new Date(props.date);
        var date = dt.getDate();
        var month = months[dt.getMonth()];
        var year = dt.getFullYear();
        return month + " " + date + ", " + year;
    }
};

export default DateFormat;
