import React from "react";
import "./Checkout.css";
import { connect } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";
import { setCallbackValues } from "../../actions/Callbacks";

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = { plan_name: this.props.match.params.plan_name };
        this.handleCallback = this.handleCallback.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    handleCallback = (state, college, stream, level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, level);
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { is_auth, auth_user } = this.props;

        return (
            <div className="row mt-5 pt-4">
                <div className="col">
                    <div className="container checkout_container text-center">
                        <h2 className="header">
                            {" "}
                            <i className="fa fa-check-circle mr-1"></i> PAYMENT
                            SUCCESS
                        </h2>
                        <br />
                        <span className="small_text">
                            {" "}
                            Thank you {auth_user.fname} for being member with
                            GooCampus, We will reach you shortly within next 24
                            hours.{" "}
                        </span>
                        <br />
                        <br />
                        <p>
                            Thank you for your patronage. Once you make payment,
                            you will receive an official communication from our
                            team, along with an invoice. If you do not receive
                            an invoice within 48 hours of making payment, please
                            reach out to <br /> finance@goocampus.in.
                        </p>

                        <Link to="/refund_policy">
                            {" "}
                            Refund and cancellation policy{" "}
                        </Link>
                        <br />
                        <br />

                        <div className="row d-none">
                            <div className="col"></div>

                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 p-4">
                                <div className="pricing_package checkout_package p-3">
                                    <h2>
                                        {" "}
                                        <i className="fa fa-check-circle text-success mr-1"></i>{" "}
                                        PAYMENT SUCCESS{" "}
                                    </h2>
                                    <br />

                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-4">
                                            <p>
                                                Thank you for your patronage.
                                                Once you make payment, you will
                                                receive an official
                                                communication from our team,
                                                along with an invoice. If you do
                                                not receive an invoice within 48
                                                hours of making payment, please
                                                reach out to
                                                finance@goocampus.in.
                                            </p>
                                            <br />
                                            <Link to="/refund_policy">
                                                {" "}
                                                Refund and cancellation policy{" "}
                                            </Link>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>

                    {/* get in touch container */}
                    <div className="row interested_container">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                            <h2 className="main_header">
                                <b> Interested? </b>
                            </h2>
                            <h5 className="text-center mt-3">
                                {" "}
                                Request a free callback from our expert
                                cousellors to know more and get in touch with
                                us.
                            </h5>
                            <div className="mt-3">
                                {is_auth ? (
                                    ""
                                ) : (
                                    <button className="mr-3 mt-2 abroad_btn py-2 show_login">
                                        {" "}
                                        <i className="fa fa-sign-in mr-2"></i>{" "}
                                        SIGN IN / SIGN UP
                                    </button>
                                )}
                                <button
                                    className="mt-2 abroad_btn py-2"
                                    onClick={() =>
                                        this.handleCallback("", "", "", "")
                                    }>
                                    {" "}
                                    <i className="fa fa-phone mr-2"></i> REQUEST
                                    CALLBACK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        order_id: state.home.order_id
    };
};

const mapActionsToProps = {
    onSetCallbackValues: setCallbackValues
};

export default connect(mapStatesToProps, mapActionsToProps)(PaymentSuccess);
