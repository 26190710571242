import React from "react";
import "./NewsUpdates.css";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { getFilterNewsUpdates } from "../../actions/NewsUpdates";
import $ from "jquery";
import Loader from "../../assets/loader.gif";
import NoResultsIcon from "../../assets/no_results.png";
import { setCallbackValues } from "../../actions/Callbacks";
import DateFormat from "../../utils/DateFormat";

class NewsUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active_page: 1 };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    componentWillMount() {
        this.props.onGetFilterNewsUpdates("");
    }

    clearFiltersHandler = e => {
        window.location.reload();
    };

    handlePageChange(page_number) {
        this.setState({ active_page: page_number });

        var filterData = $("#states_filters_form").serialize();
        var pageData = $("#states_pagination_form").serialize();
        var categories = "";

        $(".categories_check:checked").each(function (index) {
            categories += index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        pageData +=
            "&page_no=" +
            page_number +
            "&categories=" +
            JSON.stringify(categories.split(","));
        this.props.onGetFilterNewsUpdates("?" + filterData + "&" + pageData);
    }

    onChangeFilters = e => {
        this.setState({ active_page: 1 });

        var filterData = $("#states_filters_form").serialize();
        var pageData = $("#states_pagination_form").serialize();
        var categories = [];

        $(".categories_check:checked").each(function (index) {
            categories.push($(this).val());
        });

        pageData += "&page_no=1&categories=" + JSON.stringify(categories);

        this.props.onGetFilterNewsUpdates("?" + filterData + "&" + pageData);

        console.log(pageData);
    };

    handleCallback = (state, college, stream, course_level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, course_level);
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { active_page } = this.state;
        const {
            is_auth,
            sub_loader,
            news_updates,
            total_count,
            page_count,
            page_size,
            categories,
            auth_user,
            selected_categories
        } = this.props;
      
        
        var newsmessage= <div className="col text-center no_results p-3"> <img src={NoResultsIcon} alt="No results" height="100"/><br /><br /><h6>No matching results found.</h6></div>;
        if(is_auth===false){
            newsmessage=<p className="col text-center no_results p-3">Kindly Login to View The College News</p>
        }
        else{
            newsmessage= <div className="col text-center no_results p-3"> <img src={NoResultsIcon} alt="No results" height="100"/><br /><br /><h6>No matching results found.</h6></div>;
        }
        
        return (
            <div className="container-fluid states_container pt-5 my-5">
                {/* Breadcrumbs and results counter container */}
                <div className="row sort_container">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                        <nav area-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>{" "}
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    area-current="page">
                                    News feed
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col text-center">
                        <h3 className="header text-orange text-uppercase">
                            {" "}
                            News feed{" "}
                        </h3>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                        <span className="small_text text-blue">
                            {" "}
                            Showing {page_count} of {total_count} news updates.
                        </span>
                    </div>
                </div>

                {/*  News feed container */}
                <div className="row px-1 pt-3">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 px-2 mb-4">
                        <div className="card">
                            <form id="states_filters_form" autoComplete="off">
                                <h6
                                    className="filter_header"
                                    data-toggle="collapse"
                                    data-target="#search_collapse">
                                    {" "}
                                    Search{" "}
                                    <i className="fa fa-chevron-down"></i>
                                </h6>

                                <div
                                    className="filter_div collapse show"
                                    id="search_collapse">
                                    <div className="input-group p-1">
                                        <input
                                            type="search"
                                            name="search_key"
                                            onChange={this.onChangeFilters}
                                            className="form-control search_input"
                                            placeholder="Search news"
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fa fa-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <h6
                                    className="filter_header"
                                    data-toggle="collapse"
                                    data-target="#sort_collapse">
                                    {" "}
                                    Sorting{" "}
                                    <i className="fa fa-chevron-down"></i>
                                </h6>

                                <div
                                    className="filter_div collapse show pl-2"
                                    id="sort_collapse">
                                    <input
                                        type="radio"
                                        name="sort_key"
                                        defaultValue="1"
                                        onChange={this.onChangeFilters}
                                        defaultChecked="true"
                                    />{" "}
                                    <span className="check_label">
                                        {" "}
                                        Recent first
                                    </span>
                                    <br />
                                    <input
                                        type="radio"
                                        name="sort_key"
                                        defaultValue="2"
                                        onChange={this.onChangeFilters}
                                    />{" "}
                                    <span className="check_label">
                                        {" "}
                                        Old first
                                    </span>
                                    <br />
                                    <input
                                        type="radio"
                                        name="sort_key"
                                        defaultValue="3"
                                        onChange={this.onChangeFilters}
                                    />{" "}
                                    <span className="check_label">
                                        {" "}
                                        News A-Z
                                    </span>
                                    <br />
                                    <input
                                        type="radio"
                                        name="sort_key"
                                        defaultValue="4"
                                        onChange={this.onChangeFilters}
                                    />{" "}
                                    <span className="check_label">
                                        {" "}
                                        News Z-A
                                    </span>
                                    <br />
                                </div>

                                <h6
                                    className="filter_header"
                                    data-toggle="collapse"
                                    data-target="#filter_collapse">
                                    {" "}
                                    Filters{" "}
                                    <i className="fa fa-chevron-down"></i>
                                </h6>

                                <div
                                    className="filter_div collapse show pl-2"
                                    id="filter_collapse">
                                    <div className="filter_selector">
                                        <h6 className="filter_sub_header">
                                            {" "}
                                            Categories{" "}
                                        </h6>
                                        {categories && categories.length > 0 ? (
                                            categories.map(cat => (
                                                <span key={cat.id}>
                                                    <input
                                                        type="checkbox"
                                                        className="categories_check"
                                                        defaultValue={cat.id}
                                                        onChange={
                                                            this.onChangeFilters
                                                        }
                                                    />{" "}
                                                    <span className="check_label">
                                                        {" "}
                                                        {cat.category}
                                                    </span>
                                                    <br />
                                                </span>
                                            ))
                                        ) : (
                                            <p className="small_text text-center text-blue mt-4">
                                                No categories
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/*  Filter results display and loader */}
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                        {/* <div className="col"> */}
                        {sub_loader ? (
                            <div className="text-center">
                                <img
                                    src={Loader}
                                    className="filter_loader"
                                    alt="Loader"
                                />
                            </div>
                        ) : (
                            <div className="row">
                                {news_updates && news_updates.length > 0 ? (
                                    news_updates.map(news => (
                                        <div
                                            key={news.id}
                                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 pb-0 d-flex">
                                            <div className="card w-100">
                                                <Link
                                                    to={
                                                        "/news_details/" +
                                                        news.id
                                                    }>
                                                    <div className="p-3">
                                                        {news.news_authority ? (
                                                            <h6 className="small_text text-orange">
                                                                {
                                                                    news
                                                                        .news_authority
                                                                        .author_code
                                                                }{" "}
                                                                {news
                                                                    .news_authority
                                                                    .state &&
                                                                news
                                                                    .news_authority
                                                                    .state !==
                                                                    ""
                                                                    ? ", " +
                                                                      news
                                                                          .news_authority
                                                                          .state
                                                                    : null}{" "}
                                                            </h6>
                                                        ) : null}
                                                        <h6 className="text-blue">
                                                            {news.news_title}{" "}
                                                        </h6>
                                                        <span className="small_text text-dark">
                                                            {" "}
                                                            Posted :{" "}
                                                            {DateFormat(
                                                                news.created_at.slice(
                                                                    0,
                                                                    10
                                                                )
                                                            )}
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                  
                                    newsmessage
                                )}
                            </div>
                        )}

                        {/*  Pagination container show when results are greater than 10  */}
                        {!sub_loader && total_count > 10 ? (
                            <form
                                id="states_pagination_form"
                                autoComplete="off">
                                <div className="row pt-2">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left text-blue">
                                        <span className="small_text">
                                            View{" "}
                                        </span>
                                        <select
                                            name="page_size"
                                            className="form-control ml-2 w-auto d-inline"
                                            value={page_size}
                                            onChange={this.onChangeFilters}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        <span className="small_text ml-2">
                                            {" "}
                                            results per page.{" "}
                                        </span>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
                                        <div className="pagination_container table-responsive">
                                            <Pagination
                                                activePage={active_page}
                                                itemsCountPerPage={page_size}
                                                totalItemsCount={total_count}
                                                pageRangeDisplayed={6}
                                                onChange={this.handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
          auth_user: state.home.auth_user,
        sub_loader: state.home.sub_loader,
        news_updates: state.news.news_updates,
        total_count: state.news.total_count,
        page_count: state.news.page_count,
        page_size: state.news.page_size,
        categories: state.news.categories,
        selected_categories: state.news.selected_categories
    };
};

const mapActionsToProps = {
    onGetFilterNewsUpdates: getFilterNewsUpdates,
    onSetCallbackValues: setCallbackValues
};

export default connect(mapStatesToProps, mapActionsToProps)(NewsUpdates);
