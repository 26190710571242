import React from "react";
import "./EditProfile";
import swal from "sweetalert";
import { connect } from "react-redux";
import { updateSettings } from "../../actions/Home";
import {
    getDropDownStreams,
    getStreamCourseLevels
} from "../../actions/Listings";

class ChangeStreamSettings extends React.Component {
    constructor(props) {
        super(props);
        const { auth_user } = this.props;
        this.state = {
            user: auth_user,
            int_states: auth_user.int_states
                ? auth_user.int_states.split(",")
                : []
        };
        this.handleChangeSettings = this.handleChangeSettings.bind(this);
        this.handleAddState = this.handleAddState.bind(this);
    }

    componentWillMount() {
        if (!this.props.is_auth || !this.props.auth_user) {
            window.location.hash = "#/";
        }
    }

    componentDidMount() {
        this.props.onGetStreams();
        this.props.onGetStreamCourseLevels(this.state.user.int_stream);
    }

    handleChangeSettings = e => {
        const { name, value } = e.target;
        const { user } = this.state;
        user[name] = value;

        if (name === "int_stream") {
            this.props.onGetStreamCourseLevels(value);
            user["int_course_level"] = "";
        }

        this.setState({ user: user });
    };

    handleAddState = state => {
        let { int_states } = this.state;

        if (int_states.includes(state.toString())) {
            const state_index = int_states.indexOf(state.toString());
            int_states.splice(state_index, 1);
        } else int_states.push(state.toString());

        this.setState({ int_states: int_states });
    };

    handleUpdateSettings = e => {
        e.preventDefault();

        swal({
            title: "Are you sure?",
            text: "To update your settings?",
            buttons: ["Cancel", "Update"]
        }).then(willRequest => {
            if (willRequest) {
                var formData = new FormData(
                    document.getElementById("update_settings_form")
                );
                formData.append("int_states", this.state.int_states.toString());
                this.props.onUpdateSettings(formData);
            }
        });
    };

    render() {
        const { auth_user, states, streams, levels } = this.props;
        const { int_states } = this.state;

        return (
            <div className="row mt-4 pt-4">
                <div className="col">
                    <div className="edit_profile_container text-center mt-5">
                        <h2 className="sub_header mb-4">
                            <i className="fa fa-cogs mr-1"></i> CHANGE SETTINGS{" "}
                        </h2>

                        <div className="row">
                            <div className="col"></div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="edit_profile">
                                    <p className="para mb-3">
                                        Choose the stream and course level
                                        you're interested in so we can
                                        personlise your experience. Please note
                                        you have to change the stream and course
                                        level to see respective exam and
                                        couselling updates.
                                    </p>

                                    <form
                                        className="mt-3"
                                        id="update_settings_form"
                                        onSubmit={this.handleUpdateSettings}>
                                        <div className="row mt-4">
                                            <div className="col-md-6 col-12 px-3">
                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        Choose stream * :{" "}
                                                    </label>
                                                    <select
                                                        name="int_stream"
                                                        className="form-control"
                                                        value={
                                                            auth_user.int_stream
                                                                ? auth_user.int_stream
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleChangeSettings
                                                        }>
                                                        <option value="">
                                                            {" "}
                                                            Interested stream{" "}
                                                        </option>
                                                        {streams &&
                                                        streams.length > 0
                                                            ? streams.map(
                                                                  stream => (
                                                                      <option
                                                                          key={
                                                                              stream.id
                                                                          }
                                                                          value={
                                                                              stream.id
                                                                          }>
                                                                          {
                                                                              stream.name
                                                                          }
                                                                      </option>
                                                                  )
                                                              )
                                                            : ""}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12 px-3">
                                                <div className="form-group">
                                                    <label className="small_text">
                                                        {" "}
                                                        Choose course level * :{" "}
                                                    </label>
                                                    <select
                                                        name="int_course_level"
                                                        className="form-control"
                                                        value={
                                                            auth_user.int_course_level
                                                                ? auth_user.int_course_level
                                                                : ""
                                                        }
                                                        onChange={
                                                            this
                                                                .handleChangeSettings
                                                        }>
                                                        <option value="">
                                                            {" "}
                                                            Interested level{" "}
                                                        </option>
                                                        {levels &&
                                                        levels.length > 0
                                                            ? levels.map(
                                                                  level => (
                                                                      <option
                                                                          key={
                                                                              level.id
                                                                          }
                                                                          value={
                                                                              level.id
                                                                          }>
                                                                          {
                                                                              level.level_name
                                                                          }
                                                                      </option>
                                                                  )
                                                              )
                                                            : ""}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        {(auth_user.int_stream == 1 ||
                                            auth_user.int_stream == 2) &&
                                        auth_user.int_course_level ? (
                                            <div className="form-group p-1">
                                                <label className="small_text">
                                                    {" "}
                                                    Choose states * :{" "}
                                                </label>
                                                <br />
                                                <div className="row text-left">
                                                    {states && states.length > 0
                                                        ? states.map(state => (
                                                              <div
                                                                  key={state.id}
                                                                  className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                                  <input
                                                                      type="checkbox"
                                                                      defaultValue={
                                                                          state.id
                                                                      }
                                                                      defaultChecked={
                                                                          int_states &&
                                                                          int_states.includes(
                                                                              state.id.toString()
                                                                          )
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      onChange={() =>
                                                                          this.handleAddState(
                                                                              state.id
                                                                          )
                                                                      }
                                                                  />{" "}
                                                                  <span className="text-blue">
                                                                      {
                                                                          state.state_name
                                                                      }
                                                                  </span>
                                                              </div>
                                                          ))
                                                        : ""}
                                                </div>
                                            </div>
                                        ) : null}

                                        <div className="form-group mt-4">
                                            <button
                                                type="submit"
                                                className="abroad_btn">
                                                {" "}
                                                <i className="fa fa-check"></i>{" "}
                                                UPDATE SETTINGS{" "}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        states: state.state.college_states,
        streams: state.list.streams,
        levels: state.list.course_levels
    };
};

const mapActionsToProps = {
    onUpdateSettings: updateSettings,
    onGetStreams: getDropDownStreams,
    onGetStreamCourseLevels: getStreamCourseLevels
};

export default connect(
    mapStatesToProps,
    mapActionsToProps
)(ChangeStreamSettings);
