import {
    GET_STREAMS_SUCCESS,
    GET_LEVELS_SUCCESS,
    GET_STATE_COLLEGES_SUCCESS,
    GET_BENEFITS_SUCCCESS,
    GET_PLANS_SUCCESS,
    GET_PLAN_SUCCESS,
    GET_VIDEOS_SUCCESS
} from "../actions/Listings";

const initialState = {
    streams: [],
    course_levels: [],
    state_colleges: [],
    benefits: [],
    member_plans: [],
    member_plan: {},
    videos: []
};

const Listing_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_STREAMS_SUCCESS:
            return Object.assign({}, state, { streams: payload.streams });

        case GET_LEVELS_SUCCESS:
            return Object.assign({}, state, {
                course_levels: payload.course_levels
            });

        case GET_STATE_COLLEGES_SUCCESS:
            return Object.assign({}, state, {
                state_colleges: payload.state_colleges
            });

        case GET_BENEFITS_SUCCCESS:
            return Object.assign({}, state, { benefits: payload.benefits });

        case GET_PLANS_SUCCESS:
            return Object.assign({}, state, {
                member_plans: payload.member_plans
            });

        case GET_PLAN_SUCCESS:
            return Object.assign({}, state, {
                member_plan: payload.member_plan
            });

        case GET_VIDEOS_SUCCESS:
            return Object.assign({}, state, { videos: payload.videos });

        default:
            return state;
    }
};

export default Listing_reducer;
