import React from "react";
import "./Courses.css";

const BHMSCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>
                                Bachelor of Homoeopathic Medicine and Surgery
                                (BHMS)
                            </b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            Bachelors in Homeopathic Medicine and Surgery or
                            B.H.M.S is an undergraduate course which follows a
                            holistic system of medicine where an individual is
                            given tablets which contains highly diluted
                            substances which pushes the body’s natural system of
                            healing. The course duration is 5½ years including
                            an internship which is mandatory. This course as a
                            holistic alternative medicine degree provides the
                            candidates with an overview of the homoeopathic
                            medicinal system with an in-depth knowledge of the
                            subject. This course can also be pursued through a
                            distance education program or through
                            correspondence.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <ul className="courses_list">
                            <li>
                                To pursue this course, the candidate must have a
                                minimum aggregate of 50% or an equivalent
                                qualification in the subjects of Physics,
                                Chemistry, Biology and English in 10+2/Class 12.
                            </li>
                            <li>
                                To be eligible for admittance in B.H.M.S, the
                                candidate must attempt NEET UG (National
                                Eligibility-cum-Entrance Test) and obtain the
                                minimum qualifying score. The admission is
                                purely based on merit and the performance of the
                                candidate in NEET UG. The minimum age criteria
                                is 17 years.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course suitability</h4>
                        <ul className="courses_list">
                            <li>
                                Aspiring candidates who wish to serve mankind
                                can surely join this course.
                            </li>
                            <li>
                                Those who possess good logical thinking and have
                                a good mindset are well suitable to pursue
                                B.H.M.S.
                            </li>
                            <li>
                                Those individuals who can be proficient in
                                Homeopathic remedies and wish to promote natural
                                health by becoming an alternative medicine
                                practitioner can pursue this course.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BHMSCourse;
