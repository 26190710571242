import React from "react";
import "./EventCalendar.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DateFormat from "../DateFormat";
import { getFilteredEvents } from "../../actions/Events";

class EventCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.match.params.type,
            month: this.props.match.params.month,
            year: this.props.match.params.year
        };
    }

    componentDidMount() {
        const { type, month, year } = this.state;
        var filters = "type=" + type + "&month=" + month + "&year=" + year;
        this.props.onGetFilteredEvents(filters);
    }

    render() {
        const { events } = this.props;
        const { type, month, year } = this.state;

        return (
            <div className="container-fluid min_height">
                <div className="row states_container pt-5 my-3">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
                        <div className="row sort_container">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                                <nav area-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>{" "}
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/calendar">Calendar</Link>
                                        </li>
                                        <li
                                            className="breadcrumb-item active text-capitalize"
                                            area-current="page">
                                            {type} events
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <h4 className="header text-blue text-capitalize">
                                    {" "}
                                    {type === "ongoing"
                                        ? "Ongoing events"
                                        : "Events - " +
                                          month +
                                          ", " +
                                          year}{" "}
                                </h4>
                            </div>
                            <div className="col"></div>
                        </div>
                    </div>
                </div>

                {/* Events display */}
                <div className="row">
                    {events && events.length > 0
                        ? events.map((event, index) => (
                              <div
                                  key={index}
                                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex">
                                  <div
                                      className="card w-100 my-3"
                                      style={{ padding: 15 }}>
                                      {event.event_authority ? (
                                          <h6 className="date_text text-dark text-capitalize">
                                              {
                                                  event.event_authority
                                                      .author_code
                                              }{" "}
                                              {
                                                  event.event_authority
                                                      .author_state
                                              }
                                          </h6>
                                      ) : null}
                                      <a
                                          data-toggle="collapse"
                                          data-target={
                                              "#event_details_" + index
                                          }>
                                          <h6 className="text-blue text-capitalize">
                                              {event.event_name}
                                          </h6>
                                      </a>
                                      <h6
                                          style={{
                                              position: "absolute",
                                              top: -16,
                                              left: -12,
                                              backgroundColor: "#233f78",
                                              color: "white",
                                              padding: 8,
                                              fontSize: 13,
                                              borderRadius: 30
                                          }}>
                                          {index + 1}
                                      </h6>

                                      {/* Event details */}
                                      {event.event_details &&
                                      event.event_details !== "" ? (
                                          <div
                                              id={"event_details_" + index}
                                              className="collapse">
                                              <p className="text-left my-1 medium_text text-blue">
                                                  {event.event_details}
                                              </p>
                                          </div>
                                      ) : null}

                                      {type == "ongoing" ? (
                                          <span className="date_text">
                                              Last Date :{" "}
                                              <DateFormat
                                                  date={event.event_ends}
                                                  type={1}
                                              />
                                          </span>
                                      ) : null}
                                      {type == "upcoming" ? (
                                          event.event_starts ==
                                          event.event_ends ? (
                                              <span className="date_text">
                                                  <span>
                                                      <DateFormat
                                                          date={
                                                              event.event_starts
                                                          }
                                                          type={1}
                                                      />
                                                  </span>
                                              </span>
                                          ) : (
                                              <span className="date_text">
                                                  <span>
                                                      <DateFormat
                                                          date={
                                                              event.event_starts
                                                          }
                                                          type={1}
                                                      />
                                                  </span>{" "}
                                                  <span className="float-right">
                                                      <DateFormat
                                                          date={
                                                              event.event_ends
                                                          }
                                                          type={1}
                                                      />
                                                  </span>
                                              </span>
                                          )
                                      ) : null}
                                      {type == "completed" ? (
                                          <span className="date_text">
                                              Ended on :{" "}
                                              <DateFormat
                                                  date={event.event_ends}
                                                  type={1}
                                              />
                                          </span>
                                      ) : null}
                                      {/* <button className="abroad_btn w-25 mt-2 p-1">Remind</button> */}
                                  </div>
                              </div>
                          ))
                        : <p className="col text-center no_results p-3">Kindly Login To View The Event</p>}
                </div>
                <br />
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        events: state.event.events
    };
};

const mapActionsToProps = {
    onGetFilteredEvents: getFilteredEvents
};

export default connect(mapStatesToProps, mapActionsToProps)(EventCalendar);
