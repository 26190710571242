import React from "react";
import "./Courses.css";

const MVScCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>Masters of Veterinary Science (MVSc)</b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            Masters in Veterinary Science of M.V.Sc is a
                            postgraduate course in the field of Agriculture
                            Science and Technology. This course deals with the
                            specialization in the treatment of animals in
                            specific. The duration of this course is 2 years
                            which is broken down into four semesters. The time
                            period for the completion of this course is 4 years,
                            the duration may vary depending on the institute.
                            Unless specified, the language medium for the course
                            is English. The syllabus for this course is focused
                            more on research and practical training modules when
                            compared to B.V.Sc
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body which was established under the Ministry of
                            Agriculture by the Government of India. The
                            Veterinary Council of India sets minimum standards
                            of education which is required by the respective
                            institutions to grant recognized qualifications.
                            Numerous Veterinary schools and institutions across
                            all states offer a PG course in Veterinary Science.
                            Depending on an individual’s choice of
                            specialization, the course Masters in Veterinary
                            Science is chosen. Various universities offer a wide
                            selection of M.Sc courses in Animal Sciences. Some
                            Veterinary Institutions also offer courses(M.V.Sc)
                            in Dairy related subjects
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <ul className="courses_list">
                            <li>
                                Aspiring candidates who have completed their
                                qualification in B.V.Sc & AH from an institution
                                recognized by the Veterinary Council of India
                                are eligible for the M.V.Sc Course.
                            </li>
                            <li>
                                Admittance to this course is based on the scores
                                obtained in the All India Competitive
                                Examination conducted by the Education Division,
                                Indian Council of Agricultural Research, New
                                Delhi. The minimum age criteria to be eligible
                                for this course is between 20 years to 22 years.
                                Certain institutions maintain an upper age limit
                                of 35 years, while some don’t.
                            </li>
                            <li>
                                Aspiring candidates who have cleared their
                                B.V.Sc & AH degree as per the conditions put
                                down by the Veterinary Council of India Must
                                have obtained a minimum aggregate of 55%. The
                                requirement for the minimum aggregate may have a
                                variation depending on the Institution. For
                                candidates belonging to the SC/ST category do
                                not have any specific criteria apart from the
                                minimum requirement to pass the examination.
                            </li>
                            <li>
                                As decided by the representative of the Advisory
                                Committee, foreign, NRI and PIO candidates
                                should have completed deficiency courses to
                                obtain the basic qualification of an M.Sc.
                                degree.
                            </li>
                            <li>
                                The candidates belonging to the above mentioned
                                category have a minimum criteria of obtaining at
                                least 6.60 (in a 10-point scale) as their
                                C.G.P.A in their Bachelor’s of Veterinary
                                Science. i.e 66%.
                            </li>
                            <li>
                                The selection of students for the admittance
                                into this course is purely based the scores
                                obtained in the Entrance Examination, as well as
                                the aggregate obtained in the qualifying
                                examination of B.V.Sc & AH.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Specialisations</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Animal Nutrition</li>
                                    <li>Animal Genetics and Breeding</li>
                                    <li>Animal Reproduction</li>
                                    <li>Livestock Products Technology</li>
                                    <li>Livestock Production Management</li>
                                    <li>Masters in Wildlife Science</li>
                                    <li>Poultry Science</li>
                                    <li>Veterinary Anatomy</li>
                                    <li>Veterinary Medicine</li>
                                    <li>Veterinary Microbiology</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Veterinary Parasitology</li>
                                    <li>Veterinary Pathology</li>
                                    <li>Veterinary Physiology</li>
                                    <li>
                                        Veterinary Pharmacology and Toxicology
                                    </li>
                                    <li>Veterinary Microbiology</li>
                                    <li>Veterinary Biochemistry</li>
                                    <li>
                                        Veterinary Gynaecology and Obstetrics
                                    </li>
                                    <li>Veterinary Surgery and Radiology</li>
                                    <li>
                                        Veterinary and Animal Husbandry
                                        Extension
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course Regulations</h4>
                        <p className="para">
                            Masters in Veterinary Science of M.V.Sc is a
                            postgraduate course in the field of Agriculture
                            Science and Technology. This course deals with the
                            specialization in the treatment of animals in
                            specific. The duration of this course is 2 years
                            which is broken down into four semesters. The time
                            period for the completion of this course is 4 years,
                            the duration may vary depending on the institute.
                            Unless specified, the language medium for the course
                            is English. The syllabus for this course is focused
                            more on research and practical training modules when
                            compared to B.V.Sc
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body which was established under the Ministry of
                            Agriculture by the Government of India. The
                            Veterinary Council of India sets minimum standards
                            of education which is required by the respective
                            institutions to grant recognized qualifications.
                            Numerous Veterinary schools and institutions across
                            all states offer a PG course in Veterinary Science.
                            Depending on an individual’s choice of
                            specialization, the course Masters in Veterinary
                            Science is chosen. Various universities offer a wide
                            selection of M.Sc courses in Animal Sciences. Some
                            Veterinary Institutions also offer courses(M.V.Sc)
                            in Dairy related subjects
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Additional Weight-age Marks/Points.
                        </h4>
                        <p className="para">
                            Masters in Veterinary Science of M.V.Sc is a
                            postgraduate course in the field of Agriculture
                            Science and Technology. This course deals with the
                            specialization in the treatment of animals in
                            specific. The duration of this course is 2 years
                            which is broken down into four semesters. The time
                            period for the completion of this course is 4 years,
                            the duration may vary depending on the institute.
                            Unless specified, the language medium for the course
                            is English. The syllabus for this course is focused
                            more on research and practical training modules when
                            compared to B.V.Sc
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body which was established under the Ministry of
                            Agriculture by the Government of India. The
                            Veterinary Council of India sets minimum standards
                            of education which is required by the respective
                            institutions to grant recognized qualifications.
                            Numerous Veterinary schools and institutions across
                            all states offer a PG course in Veterinary Science.
                            Depending on an individual’s choice of
                            specialization, the course Masters in Veterinary
                            Science is chosen. Various universities offer a wide
                            selection of M.Sc courses in Animal Sciences. Some
                            Veterinary Institutions also offer courses(M.V.Sc)
                            in Dairy related subjects
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">System of Education</h4>
                        <p className="para">
                            Masters in Veterinary Science of M.V.Sc is a
                            postgraduate course in the field of Agriculture
                            Science and Technology. This course deals with the
                            specialization in the treatment of animals in
                            specific. The duration of this course is 2 years
                            which is broken down into four semesters. The time
                            period for the completion of this course is 4 years,
                            the duration may vary depending on the institute.
                            Unless specified, the language medium for the course
                            is English. The syllabus for this course is focused
                            more on research and practical training modules when
                            compared to B.V.Sc
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body which was established under the Ministry of
                            Agriculture by the Government of India. The
                            Veterinary Council of India sets minimum standards
                            of education which is required by the respective
                            institutions to grant recognized qualifications.
                            Numerous Veterinary schools and institutions across
                            all states offer a PG course in Veterinary Science.
                            Depending on an individual’s choice of
                            specialization, the course Masters in Veterinary
                            Science is chosen. Various universities offer a wide
                            selection of M.Sc courses in Animal Sciences. Some
                            Veterinary Institutions also offer courses(M.V.Sc)
                            in Dairy related subjects
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Credit Requirement</h4>
                        <p className="para">
                            Masters in Veterinary Science of M.V.Sc is a
                            postgraduate course in the field of Agriculture
                            Science and Technology. This course deals with the
                            specialization in the treatment of animals in
                            specific. The duration of this course is 2 years
                            which is broken down into four semesters. The time
                            period for the completion of this course is 4 years,
                            the duration may vary depending on the institute.
                            Unless specified, the language medium for the course
                            is English. The syllabus for this course is focused
                            more on research and practical training modules when
                            compared to B.V.Sc
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body which was established under the Ministry of
                            Agriculture by the Government of India. The
                            Veterinary Council of India sets minimum standards
                            of education which is required by the respective
                            institutions to grant recognized qualifications.
                            Numerous Veterinary schools and institutions across
                            all states offer a PG course in Veterinary Science.
                            Depending on an individual’s choice of
                            specialization, the course Masters in Veterinary
                            Science is chosen. Various universities offer a wide
                            selection of M.Sc courses in Animal Sciences. Some
                            Veterinary Institutions also offer courses(M.V.Sc)
                            in Dairy related subjects
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Credit load</h4>
                        <p className="para">
                            Masters in Veterinary Science of M.V.Sc is a
                            postgraduate course in the field of Agriculture
                            Science and Technology. This course deals with the
                            specialization in the treatment of animals in
                            specific. The duration of this course is 2 years
                            which is broken down into four semesters. The time
                            period for the completion of this course is 4 years,
                            the duration may vary depending on the institute.
                            Unless specified, the language medium for the course
                            is English. The syllabus for this course is focused
                            more on research and practical training modules when
                            compared to B.V.Sc
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body which was established under the Ministry of
                            Agriculture by the Government of India. The
                            Veterinary Council of India sets minimum standards
                            of education which is required by the respective
                            institutions to grant recognized qualifications.
                            Numerous Veterinary schools and institutions across
                            all states offer a PG course in Veterinary Science.
                            Depending on an individual’s choice of
                            specialization, the course Masters in Veterinary
                            Science is chosen. Various universities offer a wide
                            selection of M.Sc courses in Animal Sciences. Some
                            Veterinary Institutions also offer courses(M.V.Sc)
                            in Dairy related subjects
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Attendance</h4>
                        <p className="para">
                            Masters in Veterinary Science of M.V.Sc is a
                            postgraduate course in the field of Agriculture
                            Science and Technology. This course deals with the
                            specialization in the treatment of animals in
                            specific. The duration of this course is 2 years
                            which is broken down into four semesters. The time
                            period for the completion of this course is 4 years,
                            the duration may vary depending on the institute.
                            Unless specified, the language medium for the course
                            is English. The syllabus for this course is focused
                            more on research and practical training modules when
                            compared to B.V.Sc
                        </p>
                        <p className="para">
                            The Veterinary Council of India is the recognized
                            body which was established under the Ministry of
                            Agriculture by the Government of India. The
                            Veterinary Council of India sets minimum standards
                            of education which is required by the respective
                            institutions to grant recognized qualifications.
                            Numerous Veterinary schools and institutions across
                            all states offer a PG course in Veterinary Science.
                            Depending on an individual’s choice of
                            specialization, the course Masters in Veterinary
                            Science is chosen. Various universities offer a wide
                            selection of M.Sc courses in Animal Sciences. Some
                            Veterinary Institutions also offer courses(M.V.Sc)
                            in Dairy related subjects
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Evaluation</h4>
                        <ul className="courses_list">
                            <li>
                                The student’s progress shall be graded on the
                                basis of periodical examinations.
                            </li>
                            <li>
                                A mid-semester theoretical examination will be
                                conducted by the teacher/HOD/Dean/Associate Dean
                                where at least half the course has been
                                completed (i.e in the 11th week since the
                                commencement of the semester).
                            </li>
                            <li>
                                The Mid-semester examination will consist of a
                                theory paper with a duration of 1-hour. The end
                                term examination will a minimum duration of 2
                                hours which will include theory and practical.
                            </li>
                            <li>
                                The schedule for the end-term examination will
                                be notified in advance with the approval of the
                                dean in the respective faculty by the Registrar.
                            </li>
                            <li>
                                The grading of the total for the theoretical and
                                practical examination will have the same
                                magnitude as the number of credits.{" "}
                            </li>
                        </ul>

                        <table className="table table-bordered table-responsive table-striped">
                            <tbody>
                                <tr>
                                    <td>Theory: Mid-semester examination </td>
                                    <td>20%</td>
                                </tr>
                                <tr>
                                    <td>Semester-end examination </td>
                                    <td>80 %</td>
                                </tr>
                                <tr>
                                    <td>
                                        Practical: Record of Class work
                                        (Attendance, Assignments & Practical
                                        Journal etc.)
                                    </td>
                                    <td>20%</td>
                                </tr>
                                <tr>
                                    <td>Semester-end examination</td>
                                    <td>80%</td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="para">
                            The maximum score for the Viva Examination will be
                            25% of the total score allotted for the practical
                            examination. (i.e for one credit, it’s 10/40)
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Grading</h4>
                        <ul className="courses_list">
                            <li>
                                Once the semester has ended, the student will be
                                awarded grade points on the scale of 0-10
                                points, which will be based on the students
                                performance in the examinations. The marks
                                awarded in a course is divided by 10 and is
                                displayed to two decimal places. This relates to
                                the grade points allotted in that course.
                            </li>
                            <li>
                                Grade Point average or GPA is defined as the
                                quotient of the total grade points awarded to
                                the student in span of a semester divided by the
                                total number of credits of the courses which
                                were completed successfully during that
                                semester. It will be calculated to the second
                                decimal point.
                            </li>
                            <li>
                                Cumulative Grade Point Average or CGPA is
                                defined as the quotient of total grade points
                                awarded to the student in the span of the
                                complete course which will be divided by the
                                total number of credits which were gained by the
                                student. It will be calculated to the second
                                decimal point.
                            </li>
                            <li>
                                The minimum CGPA required to complete a course
                                in M.V.Sc is 6.60
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Academic Status and Deficiency Improvement
                        </h4>
                        <p className="para">
                            An aspiring candidate must compulsorily obtain a
                            Cumulative Grade Point Average (CGPA) of at least
                            6.60 for the Masters Degree and 7.00 for a Doctor’s
                            Degree upon the completion of the required courses
                            and the research projects assigned by the Advisory
                            Committee.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course suitability</h4>
                        <p className="para">
                            An aspiring candidate must compulsorily obtain a
                            Cumulative Grade Point Average (CGPA) of at least
                            6.60 for the Masters Degree and 7.00 for a Doctor’s
                            Degree upon the completion of the required courses
                            and the research projects assigned by the Advisory
                            Committee.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Employment opportunities</h4>
                        <ul className="courses_list">
                            <li>Disease Investigation Centres</li>
                            <li>Breeding Farms/Hatchery</li>
                            <li>Department of Animal Husbandry & Dairying</li>
                            <li>
                                Biological Products and Vaccine Product Plants
                                Dealers
                            </li>
                            <li>Experimental Animal Unit</li>
                            <li>Drug Research Institutions</li>
                            <li>Natural History Museums</li>
                            <li>Experimental & Germ-free Animal Facilities</li>
                            <li>Fishery Department</li>
                            <li>Marine Amusement Park</li>
                            <li>National Parks and Wildlife Sanctuaries</li>
                            <li>Para Veterinary Staff Training Schools</li>
                            <li>Shelter Houses</li>
                        </ul>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Job types</h4>
                        <ul className="courses_list">
                            <li>Animal Farm Manager</li>
                            <li>Veterinarian/Veterinary Doctor</li>
                            <li>Biological Scientist</li>
                            <li>Veterinarian</li>
                            <li>Associate Scientific Manager</li>
                            <li>Veterinary Technologist & Technician</li>
                            <li>Pharmacy Research Scientist</li>
                            <li>Veterinary Consultant</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MVScCourse;
