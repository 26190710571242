import React from "react";
import "./Courses.css";

const MDSCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>Masters in Dental Surgery (MDS)</b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            Masters in Dental Surgery or M.D.S is a postgraduate
                            course in Dentistry. It is the branch of medicine
                            which deals with the anatomy and development and
                            research on diseases of the teeth. The duration of
                            the course is usually three years, however there is
                            another course for a duration of 2 years,
                            exclusively for candidates who have cleared their PG
                            Diploma course recognized by the Dental Council of
                            India.{" "}
                        </p>
                        <p className="para">
                            The course in particular helps you in developing
                            competence in critical analysis, research, and
                            advanced clinical methods that will be recognized
                            for a registration as a specialist. Dental Council
                            of India (D.C.I) is the official regulatory body for
                            all dental courses. Dental institutes must follow
                            the official regulations as stipulated by DCI. It is
                            a very practical oriented course which trains you on
                            real world applications for 3 years.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <ul className="courses_list">
                            <li>
                                A candidate is required to be physically fit &
                                healthy to undertake the M.D.S course and should
                                not suffer from any disease, especially
                                contagious ones.
                            </li>
                            <li>
                                A certificate of temporary registration issued
                                by the Dental Council of India must be obtained
                                by Foreign Nationals who wish to pursue the
                                M.D.S. course. This will be applicable for the
                                duration of the training and would be restricted
                                to the dental institution where he/she has been
                                admitted.
                            </li>
                            <li>
                                The selection of candidates for M.D.S is based
                                on the merit of the student. To determine which
                                student is meritorious, the National Eligibility
                                cum-Entrance Test is conducted by National Board
                                of Examinations. To be eligible for this Post
                                Graduate course, you must have completed your
                                Bachelor’s in Dental Surgery(B.D.S) or an
                                equivalent qualification recognized & approved
                                by the Dental Council of India. A student who
                                does not possess the qualifications for the
                                required course must secure an approval by the
                                Dental Council of India before he/she can be
                                taken into consideration for an M.D.S course at
                                any University in India.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Specialisations</h4>
                        <p className="para">
                            M.D. is always pursued in a particular
                            specialisation, and this more or less determines the
                            field of expertise the candidate wants to take in
                            their medical career. There are many
                            specialisations, some of which are :
                        </p>
                        <ul className="courses_list">
                            <li>Prosthodontics</li>
                            <li>Periodontics</li>
                            <li>Orthodontics</li>
                            <li>Oral & Maxillofacial Surgery</li>
                            <li>Operative Dentistry</li>
                            <li>Pedodontics& Preventive Dentistry</li>
                            <li>
                                Conservative, Endodontics& Aesthetic Dentistry
                            </li>
                            <li>Periodontology & Oral Implantology</li>
                            <li>Oral Medicine & Radiology</li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Syllabus</h4>
                        <table className="table table-bordered table-responsive table-striped">
                            <tbody>
                                <tr>
                                    <td rowspan="4">
                                        Oral and Maxillofacial Surgery
                                    </td>
                                    <td>Applied Basic Sciences</td>
                                </tr>
                                <tr>
                                    <td>Minor Oral Surgery and Trauma</td>
                                </tr>
                                <tr>
                                    <td>Maxillofacial Surgery</td>
                                </tr>
                                <tr>
                                    <td>
                                        Recent Advances in Oral and
                                        Maxillofacial Surgery
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="4">Periodontics</td>
                                    <td>Applied Basic Sciences</td>
                                </tr>
                                <tr>
                                    <td>
                                        Etiopathogenesis Of Periodontal Diseases
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Diagnosis, Treatment, Preventive
                                        Periodontology and Implantology
                                    </td>
                                </tr>
                                <tr>
                                    <td>Recent Advances in Periodontics</td>
                                </tr>
                                <tr>
                                    <td rowspan="4">Conservative Dentistry</td>
                                    <td>Applied Basic Sciences</td>
                                </tr>
                                <tr>
                                    <td>
                                        Conservative Dentistry including
                                        Preventive dentistry and dental
                                        Materials
                                    </td>
                                </tr>
                                <tr>
                                    <td>Endodontics</td>
                                </tr>
                                <tr>
                                    <td>
                                        Recent Advances in Conservative
                                        Dentistry
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="4">Oral Pathology</td>
                                    <td>Applied Basic Sciences</td>
                                </tr>
                                <tr>
                                    <td>
                                        Oral Pathology, Oral Microbiology &
                                        Forensic Odontology
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Laboratory and Histopathological
                                        Techniques
                                    </td>
                                </tr>
                                <tr>
                                    <td>Recent Advances in Oral Pathology</td>
                                </tr>
                                <tr>
                                    <td rowspan="4">Orthodontics</td>
                                    <td>Applied Basic Sciences</td>
                                </tr>
                                <tr>
                                    <td>
                                        Growth and Development, Basics in
                                        orthodontics, Diagnosis & Radiology
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Bio-Mechanics & Various Orthodontics
                                        Tissue Changes, Techniques & Treatment
                                        Planning
                                    </td>
                                </tr>
                                <tr>
                                    <td>RRecent Advances in Orthodontics</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Course suitability</h4>
                        <ul className="courses_list">
                            <li>
                                Dentistry is widely considered as a very
                                lucrative medical profession and candidates who
                                successfully complete are likely to see visible
                                improvement in their income.
                            </li>
                            <li>
                                Candidates who have completed their B.D.S.
                                course and are looking to further improve their
                                knowledge on the health problems related to the
                                teeth, mouth, gums and other oral areas should
                                also look at pursuing this course.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Employment opportunities</h4>
                        <ul className="courses_list">
                            <li>Dental Colleges & Research Institutes</li>
                            <li>Hospitals</li>
                            <li>Military Services</li>
                            <li>Dental Laboratories</li>
                            <li>Private Clinics & Hospitals</li>
                        </ul>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Job types</h4>
                        <ul className="courses_list">
                            <li>Ceramist</li>
                            <li>Dental Assistant</li>
                            <li>Dental Hygienist</li>
                            <li>Dental Lab Technician</li>
                            <li>Dental Surgeon</li>
                            <li>Forensics & Oral Pathologist</li>
                            <li>Medical Representative</li>
                            <li>Private Practitioner</li>
                            <li>Professors & Home Tutor</li>
                            <li>Public Health Specialist</li>
                            <li>Sales Representative</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MDSCourse;
