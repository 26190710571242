import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import Home_reducer from "../reducers/Home";
import College_reducer from "../reducers/Colleges";
import State_reducer from "../reducers/States";
import Listing_reducer from "../reducers/Listings";
import Callback_reducer from "../reducers/Callbacks";
import News_update_reducer from "../reducers/NewsUpdates";
import Event_reducer from "../reducers/Events";
import Cutoff_reducer from "../reducers/Cutoffs";

const All_reducers = combineReducers({
    home: Home_reducer,
    college: College_reducer,
    state: State_reducer,
    list: Listing_reducer,
    news: News_update_reducer,
    callback: Callback_reducer,
    event: Event_reducer,
    cutoff: Cutoff_reducer
});

const store = createStore(All_reducers, applyMiddleware(thunk));

export default store;
