import React from "react";
import "./Courses.css";

const DNBCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>Diplomate of National Board (DNB)</b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            Diplomate of National Board or the D.N.B. as it is
                            popularly called is the name given to post graduate
                            degree courses and post-doctoral courses pertaining
                            to the various disciplines in medicine. D.N.B. does
                            not stand for any single medical course, it is a
                            general title for a course attached with a
                            particular specialisation. The
                            specializations/courses in D.N.B. can be divided
                            into two categories: Broad Specialities and Super
                            Specialities.
                        </p>
                        <p className="para">
                            The D.N.B. is conducted by the National Board of
                            Examinations (NBE). The NBE is an autonomous body
                            under the Central Government established in 1975.
                            The purpose behind establishing NBE was to improve
                            the quality of medical education by laying down high
                            quality norms & standards for postgraduate medicine
                            examinations across the country. The advantage of
                            such norms was to have uniform standards &
                            mechanisms for evaluation of the minimum level of
                            attainment of the objective for which PG medical
                            courses were started.{" "}
                        </p>
                        <p className="para">
                            D.N.B. courses are considered to be at par with the
                            other postgraduate and post doctorate degrees for
                            all intents and purposes including for employment
                            opportunities. D.N.B. degree holding candidates are
                            therefore also eligible to be considered for a
                            specialist’s position or as a faculty in any
                            hospital.{" "}
                        </p>
                        <p className="para">
                            Although it is at par with MD/MS courses, most of
                            the candidates appearing for postgraduate medical
                            programs are not familiar with it as it is only a
                            few years old, whereas MD/MS programs have been
                            around for a long time. It is a common practice for
                            candidates who fail to secure an MD/MS seat to look
                            for opportunities in D.N.B.{" "}
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Age limit</h4>
                        <p className="para">
                            The upper age limit to pursue a D.N.B. course if 50
                            years.
                        </p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Duration</h4>
                        <p className="para">
                            The duration of the D.N.B. course is 3 years of
                            training (a minimum training of 20 hours per week in
                            3 years).{" "}
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Training</h4>
                        <p className="para">
                            During the D.N.B. course students perform a wide
                            array of clinical work, emergency duties and also
                            expected to maintain records. They are normally
                            posted in various locations and are required to
                            participate in meetings (mostly academic in nature),
                            present case studies, and participate/conduct
                            reviews & seminars. Throughout the course, their
                            skills & competence are constantly tested/evaluated.{" "}
                        </p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 className="header">Stipend</h4>
                        <p className="para">
                            D.N.B. trainees are provided with a stipend during
                            their training period.{" "}
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">D.N.B. specialities</h4>
                        <p className="para">
                            To be eligible to apply for any D.N.B. specialities,
                            it is mandatory to complete MBBS or equivalent and
                            qualify the DNB-CET examination.
                        </p>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>DNB Anatomy</li>
                                    <li>DNB Emergency Medicine</li>
                                    <li>DNB Field Epidemiology</li>
                                    <li>
                                        DNB Immunohematology & Transfusion
                                        Medicine
                                    </li>
                                    <li>DNB Rural Surgery</li>
                                    <li>DNB Endocrinology</li>
                                    <li>DNB Hematology</li>
                                    <li>DNB Medical Genetics</li>
                                    <li>DNB Neonatology</li>
                                    <li>DNB Peripheral Vascular Surgery</li>
                                    <li>DNB Rheumatology</li>
                                    <li>DNB Neurosurgery</li>
                                    <li>DNB Paediatric Surgery</li>
                                    <li>DNB Neurology</li>
                                    <li>DNB Gastroenterology</li>
                                    <li>
                                        DNB Clinical Pharmacology and
                                        Therapeutics
                                    </li>
                                    <li>DNB Plastic Surgery</li>
                                    <li>DNB Genito-Urinary Surgery</li>
                                    <li>DNB Cardiothoracic Surgery</li>
                                    <li>DNB Surgical Gastroenterology</li>
                                    <li>DNB Surgical Oncology</li>
                                    <li>DNB Thoracic Surgery</li>
                                    <li>DNB Physiology</li>
                                    <li>DNB Biochemistry</li>
                                    <li>DNB Pathology</li>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>DNB Microbiology</li>
                                    <li>DNB Forensic Medicine</li>
                                    <li>DNB Maternal Child Health</li>
                                    <li>DNB Nephrology</li>
                                    <li>DNB Cardiology</li>
                                    <li>DNB Pharmacology</li>
                                    <li>DNB General Medicine</li>
                                    <li>DNB Paediatrics</li>
                                    <li>DNB Psychiatry</li>
                                    <li>DNB Radiotherapy</li>
                                    <li>DNB Radio Diagnosis</li>
                                    <li>DNB Anaesthesiology</li>
                                    <li>DNB Dermatology & Venereology</li>
                                    <li>DNB Orthopaedics</li>
                                    <li>DNB Respiratory Diseases</li>
                                    <li>DNB Nuclear Medicine</li>
                                    <li>DNB General Surgery</li>
                                    <li>DNB Obstetrics & Gynaecology</li>
                                    <li>DNB Ophthalmology</li>
                                    <li>DNB Otorhinolaryngology</li>
                                    <li>
                                        DNB Physical Medicine & Rehabilitation
                                    </li>
                                    <li>DNB Social & Preventive Medicine</li>
                                    <li>
                                        DNB Health Administration including
                                        Hospital Administration
                                    </li>
                                    <li>DNB Family Medicine</li>
                                    <li>
                                        DNB Immunohematology & Transfusion
                                        Medicine
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Important points</h4>
                        <ul className="courses_list">
                            <li>
                                For entry into Broad Specialities, the
                                candidates need to qualify the D.N.B. CET exam.
                                The CET scores are valid for a year.
                            </li>
                            <li>
                                A merit list based on the candidate’s
                                performance in the examination will be published
                                subject to a minimum score of 50%.
                            </li>
                            <li>
                                Those candidates who qualify the CET examination
                                can register online to participate in the
                                counselling for admission to D.N.B. courses,
                                conducted by the NBE.
                            </li>
                            <li>
                                The counselling is centralised in nature and
                                seats are alloted only on the basis of merit.
                                The candidates can choose their
                                subject/specialisation based on their merit.
                            </li>
                            <li>
                                Candidates have the option to register (submit
                                the application and pay the fee) for different
                                specialisations, provided they complete the
                                registration process for each specialisation. If
                                a candidate gets accepted and pays fees for a
                                seat in a subject and then opts for a different
                                seat, their admission fee automatically gets
                                transferred to that subject.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <p className="para">
                            Candidates must have completed their M.B.B.S.
                            degree, the one-year of mandatory internship and
                            should have qualified the D.N.B. - CET exam.
                        </p>
                        <p className="para">
                            Those candidates who possess a Provisional
                            Registration certificate issued by the MCI/State
                            Medical Council and who are more than likely to
                            complete their one year of mandatory internship
                            before the last date of application form submission
                            are also eligible to apply for the CET examination,
                            subject to them submitting a certificate from the
                            Head of the Institution/Hospital that they can
                            complete their one year of mandatory internship on
                            or before the examinations.
                        </p>
                        <p className="para">
                            It is mandatory for candidates to submit their
                            internship completion certificate and a certificate
                            of permanent registration with MCI/State Medical
                            Council.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Syllabus</h4>
                        <table className="table table-bordered table-responsive table-striped">
                            <tbody>
                                <tr>
                                    <td>Academic Sessions/Lectures</td>
                                    <td>
                                        3-4 days in a week is usually reserved
                                        for academic sessions. The institute
                                        usually informs the students well in
                                        advance.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Seminars and Case Discussions </td>
                                    <td>
                                        Every week, there will be a D.N.B.
                                        seminar and a case presentation,
                                        exclusively for the D.N.B. students. The
                                        institute usually keeps the students
                                        informed.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Paper Presentations</td>
                                    <td>
                                        Each students in consultation with the
                                        program coordinator needs to select a
                                        topic and present a paper for
                                        presentation in a state-wide or
                                        nation-wide conference.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Audits/Projects</td>
                                    <td>
                                        In consultation with the program
                                        coordinator, each students needs to
                                        choose at least one topic (the more the
                                        projects, the better) and have it
                                        approved by the Institutional Review
                                        Board/Ethics Committee. All projects
                                        must be completed within 6 months of
                                        starting so that they may be presented
                                        in conferences (both state and
                                        national), submitted to potential
                                        publishers or even presented in-house.
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="4">Thesis/Research Project</td>
                                    <td>
                                        Students need to decide and get their
                                        thesis topics approved within one month
                                        of joining the D.N.B. course.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Each student is assigned a guide (from
                                        amongst the Consultants) and they are
                                        henceforth responsible for all of the
                                        student’s thesis work and log book.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Students are required to complete their
                                        thesis paper and submit it to the
                                        national board/hospital at least 6
                                        months before the final examination.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Students need to submit an update to
                                        their thesis every month to the program
                                        coordinator.
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="3">Conferences/Workshops</td>
                                    <td>
                                        Students whose papers are accepted to be
                                        presented to an external
                                        conference/workshop will have all their
                                        expenses (registration fee, travel,
                                        accommodation, daily expenses)
                                        reimbursed by the institute. However,
                                        before applying for such an external
                                        event, the students need to obtain
                                        permission from the Head of Academics
                                        and the DIrector of the institute.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        An institute will not allow more than
                                        two students from the hospital for a
                                        conference at any given time.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Students need to rout any abstracts for
                                        paper presentations with the Academics
                                        department or the Director’s approval.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Teaching</td>
                                    <td>
                                        Students must learn the basic
                                        methodology of teaching and should
                                        conduct clinical demonstrations for
                                        undergraduate students (including nurses
                                        and para-medical staff).
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Guidelines for Writing Thesis/Dissertation
                        </h4>
                        <p className="para">
                            The whole point of making the thesis paper a
                            mandatory part of all D.N.B. courses is to introduce
                            the students to the various research methodologies.
                            Research is a vital part of the entire D.N.B. course
                            structure. The faculty members who guide the
                            students ensure that each thesis paper is a feasible
                            venture.
                        </p>
                        <ul className="courses_list">
                            <li>
                                <b>Length : </b> The thesis papers are normally
                                restricted to under 100 pages.
                            </li>
                            <li>
                                <b>Title : </b> The title of the these should
                                always be clear, concise and must clearly
                                explain the nature and relevance of the topic.{" "}
                            </li>
                            <li>
                                <b>Introduction : </b> The introduction must
                                always state the purpose of the study, mention
                                the gaps in the current understanding of the
                                subject and be very clear about any hypothesis
                                (if any).
                            </li>
                            <li>
                                <b>Objectives of the study : </b> Must always be
                                clear, specific and as concise as possible.
                                Objectives must also be easy to measure.
                            </li>
                            <li>
                                <b>LReview of Literatureength : </b> All the
                                literature used must be relevant, complete and
                                as latest as possible.
                            </li>
                            <li>
                                <b>Material and Methods : </b> The students need
                                to clearly mention the type of study and details
                                of any material and/or experimental design
                                procedure and processes used for collection of
                                data. Statistical methods (if any) used for data
                                collection must also be clearly specified. The
                                statement of limitations and any ethical issues
                                involved should be made clear.
                            </li>
                            <li>
                                <b>Observations : </b> All observations must be
                                organised in readily identifiable sections and
                                have correct analysis of data that is generally
                                presented using charts, graphs, tables and
                                diagrams.
                            </li>
                            <li>
                                <b>Discussion : </b> All the observations of the
                                study should be discussed and as best practice
                                dictates it should be compared with other
                                research studies. The observations must
                                highlight the original finds and ideally must
                                also make a suggestion for the future.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Rules for Submission of Thesis/ Dissertation by
                            candidates for DNB
                        </h4>
                        <ul className="courses_list">
                            <li>
                                The protocol of the thesis should be submitted
                                to the office of the NBE through the head of the
                                institution within 3 months of joining the
                                D.N.B. course.
                            </li>
                            <li>
                                If a student’s protocol is not accepted they
                                will be informed via email by the institute.
                            </li>
                            <li>
                                The guides assigned to the students will be
                                recognised postgraduate teachers in the medical
                                college/university or any other NBE accredited
                                institute with at least 5 years of experience in
                                the specialisation post completion of the
                                postgraduate degree. They must enclose the
                                certificate of postgraduate teaching and being
                                guide recognised by the university/NBE along
                                with the thesis paper.
                            </li>
                            <li>
                                A guide can provide guidance to only one MD/MS
                                candidate and one university diploma candidate
                                (who wishes to take the D.N.B. exam) or one
                                direct NBE candidate. The total number of
                                candidates should be two, from all sources
                                combined.
                            </li>
                            <li>
                                Candidates who appear for the super speciality
                                subjects (ex: cardiology, cardio-thoracic
                                surgery) do not need to write a thesis paper if
                                they have already done so during their MD/MS/NBE
                                examinations. Such candidates must submit proof
                                of them having completed their thesis paper.{" "}
                            </li>
                            <li>
                                If the candidates appearing in the broad
                                specialities have written their thesis during
                                their MD/MS examination, then they do not need
                                to submit a thesis paper. Such candidates need
                                to submit proof of having completed their thesis
                                previously.
                            </li>
                            <li>
                                If a candidate’s thesis needs to be modified,
                                the Board will return the thesis to the
                                candidate with their suggestions in writing.
                                Such candidates need to re-submit their thesis
                                with the changes and get it approved by the
                                assessors of the Board to complete their course.
                            </li>
                            <li>
                                If the Board detects any unethical practice in
                                the thesis, then such work is liable to be
                                rejected and the students may face disciplinary
                                action as recommended by the Board.
                            </li>
                            <li>
                                The thesis paper must be submitted at least 6
                                months before the commencement of the D.N.B.
                                examination. The theory results will be declared
                                only of those candidates whose thesis paper has
                                been accepted by the Board.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">DNB Evaluation</h4>
                        <p className="para">
                            Every student is evaluated by the program
                            coordinator on a monthly basis. These evaluation
                            forms must be collected from the respective
                            departments and be evaluated by the consultants at
                            the end of every month. The filled in form should
                            ideally be submitted to the respective departments
                            before the 10th of every month.{" "}
                        </p>
                        <p className="para">
                            <b>Consultant Evaluation :</b> The students also get
                            the chance to evaluate the consultant with whom they
                            are posted (on a monthly basis). These forms need to
                            be collected from the respective department and
                            submitted after evaluation by the 10th of the every
                            following month.
                        </p>
                        <p className="para">
                            <b>Log Books :</b> Students need to collect their
                            logbooks from the respective department at the time
                            of course commencement. This logbook needs to be
                            regularly signed by the doctors and updates need to
                            submitted at the end of every month for monthly
                            evaluation.
                        </p>
                        <p className="para">
                            <b>Assessments/ Examinations :</b> The objective of
                            the two-way assessment is simply to give them
                            regular feedback about their performance and to
                            prepare them for the end-term examination by
                            exposing them to the pattern of the examination. As
                            part of this concurrent evaluation the D.N.B.
                            candidates will also be evaluated by an independent
                            local appraiser every six months. This appraise will
                            be selected by the NBE. The evaluation process would
                            include a theory examination for a total of 100
                            marks.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            DNB Broad Specialities Examination Details
                        </h4>
                        <ul className="courses_list">
                            <li>
                                <b>Theory Exam : </b>
                                <ul className="courses_list">
                                    <li>4 papers of 100 marks each.</li>
                                    <li>
                                        10 short notes of 10 marks each in each
                                        of these papers.
                                    </li>
                                    <li>Maximum time permitted is 3 hours.</li>
                                    <li>
                                        Candidates need to score at least 50%
                                        aggregate in the 4 papers to qualify the
                                        theory exam.
                                    </li>
                                    <li>
                                        Only those candidates who qualify the
                                        theory exam are permitted to take up the
                                        practical exam.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Practical Exam : </b>
                                <ul className="courses_list">
                                    <li>Total marks 300.</li>
                                    <li>
                                        Consists of both clinical examination
                                        and viva and candidates must obtain 50%
                                        marks in both to qualify the practical
                                        exam.
                                    </li>
                                    <li>
                                        Candidates have a maximum of 3 attempts
                                        to clear the practical exam.
                                    </li>
                                    <li>
                                        Being absent from the practical exam is
                                        counted as an attempt.
                                    </li>
                                    <li>
                                        Appearance in the first practical exam
                                        is mandatory.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Guidelines & Instruction whilst pursuing DNB
                        </h4>
                        <ul className="courses_list">
                            <li>
                                Final exams or end term examinations are
                                conducted on a zonal level.
                            </li>
                            <li>
                                Post 3 months of joining a DNB course, the
                                candidate must submit the Thesis protocol to NBE
                                for approval.
                            </li>
                            <li>
                                6 months prior to the final exam or end term the
                                thesis must be sent to NBE for acceptance.
                            </li>
                            <li>
                                Under the guidance of a Guide a thesis protocol
                                has to be prepared which is then reviewed by 3
                                hospital experts, where at least one will from
                                the department. Along with the recommendations
                                of the experts the protocol must be submitted to
                                the research committee chairman for approval.
                            </li>
                            <li>
                                Without a formal letter of permission form the
                                chairman, the work on the thesis must not be
                                started or be sent to the NBE.
                            </li>
                            <li>
                                Training will be considered complete only once
                                the paper based on the thesis is sent for
                                publication.{" "}
                            </li>
                            <li>
                                All candidates must maintain their logbooks in
                                the prescribed manner and present it for the
                                final practical examinations.
                            </li>
                            <li>
                                Attendance and monthly evaluation scores also
                                have to be entered in the logbook and must be
                                countersigned.
                            </li>
                            <li>
                                Candidates must maintain more than 75%
                                attendance in academic meetings.
                            </li>
                            <li>
                                Candidates must always keep a watch on the
                                official NBE website to see the progress of
                                their thesis paper, registration number, roll
                                number, thesis acceptance and their results.
                            </li>
                            <li>
                                It is strongly advised that candidates keep a
                                copy of the above mentioned documents with them
                                for safe-keeping.
                            </li>
                            <li>
                                Trainees must regularly check the website for
                                any changes in the rules & regulations that may
                                apply to them. NBE usually published the
                                bulletins every 6 months and the candidates must
                                consult this document as well.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DNBCourse;
