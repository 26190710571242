import { GET_CALENDAR_SUCCESS, GET_EVENTS_SUCCESS } from "../actions/Events";

const initialState = {
    all_events: [],
    events: []
};

const Event_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CALENDAR_SUCCESS:
            return Object.assign({}, state, { all_events: payload.all_events });

        case GET_EVENTS_SUCCESS:
            return Object.assign({}, state, { events: payload.events });

        default:
            return state;
    }
};

export default Event_reducer;
