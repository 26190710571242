import React from "react";
import "./Courses.css";

const BUMSCourse = () => {
    return (
        <div className="row">
            <div className="col course_container">
                <div className="row description_card p-0">
                    <div className="col">
                        <h3 className="service_header">
                            <b>Bachelor of Unani Medicine and Surgery (BUMS)</b>
                        </h3>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">OVERVIEW</h4>
                        <p className="para">
                            Unani is considered as one of the most ancient
                            medication system in the world, adopted mostly by
                            the South-Asia and Middle Eastern countries. It is
                            believed to have originated to from the Greek
                            cultures by the great physician Hippocrates. This
                            field of medicine is also known by other names such
                            as ‘Hikmat’.
                        </p>
                        <p className="para">
                            B.U.M.S. or the Bachelor of Unani Medicine and
                            Surgery is an undergraduate degree course focused on
                            the field of Unani medicine. Candidates who
                            successfully complete this course become Hakim
                            (doctors) in the field of Unani.
                        </p>
                        <p className="para">
                            Unani is an alternative system of medicine that
                            believes in the natural healing ability of the body.
                            Patients in this system of medicine are treated by
                            letting their body rejuvenate itself, the Hakims
                            only help enhance their natural healing ability.
                        </p>
                        <p className="para">
                            There are several medication systems prevalent in
                            India, Unani’s popularity comes right behind
                            allopathic, ayurvedic and homeopathic. As per Unani,
                            there are four elements within the human body:
                        </p>

                        <ul className="courses_list">
                            <li>Yellow bile</li>
                            <li>Black bile</li>
                            <li>Phlegm</li>
                            <li>Blood</li>
                        </ul>

                        <p className="para">
                            Unani system of medication was introduced to India
                            around the 12th century under the patronage of the
                            Mughal empire. The treatment of any disease using
                            Unani depends entirely on properly diagnosing the
                            problem.{" "}
                        </p>
                        <p className="para">
                            Unani is taught by many colleges, both in India and
                            abroad and lately this system of medication is
                            picking up trend and being adopted quickly by young
                            students. It features various study programs such as
                            diplomas, postgraduate and even dedicated research
                            programs.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Eligibility</h4>
                        <p className="para">
                            The minimum qualifying criteria to be eligible for
                            an admission into the BUMS programme is a 50%
                            aggregate in the Class 12/10+2 examination in the
                            subjects of Physics, Chemistry and Biology.
                            Candidates who have taken up the language ‘Urdu’ in
                            Class 12 or are fluent in the language are given
                            more priority during the admission.
                        </p>
                        <p className="para">
                            The Official statutory body for Unani education is
                            the Central Council of Indian Medicine (CCIM) in
                            India. There are around 35 medical institutions
                            which provide the educational course in Unani
                            Medicine. Certain institutions conduct national and
                            state level examinations for an admission into this
                            course. Aspiring candidates can attempt NEET
                            (National Eligibility cum Entrance Test), to be
                            eligible for an admission into an BUMS course. The
                            minimum age criteria to be eligible is 17 years.{" "}
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Syllabus</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>
                                        Regimental therapy (llaj -bit- Tadbeer)
                                    </li>
                                    <li>Venesection</li>
                                    <li>Cupping</li>
                                    <li>Diaphoresis</li>
                                    <li>Diuresis</li>
                                    <li>Turkish bath</li>
                                    <li>Massage</li>
                                    <li>Cauterization</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Purging</li>
                                    <li>Emesis</li>
                                    <li>Exercise</li>
                                    <li>Leeching</li>
                                    <li>Pharmacotherapy (llaj-bit-Dawa)</li>
                                    <li>Dietotherapy (llaj-bit-Ghiza)</li>
                                    <li>Surgery (Jarahat)</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Duration</h4>
                        <p className="para">
                            Bachelors in Unani Medicine and Surgery or BUMS is a
                            popular undergraduate degree course in the field of
                            Unani. On the completion of 5.5 years of the course
                            the degree will be awarded. The course of 5.5 years
                            is broken down into 4.5 years of academics and a
                            year of internship which will include a live
                            practical. This UG course can be pursued through a
                            distance education program.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">
                            Course under the broad spectrum of Unani system of
                            medicine
                        </h4>
                        <p className="para">
                            Beside B.U.M.S., there are other popular courses
                            under Unani such as:
                        </p>

                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>
                                        Diploma in Unani Medicine and Surgery
                                    </li>
                                    <li>
                                        Diploma in Unani Medicine and Science
                                    </li>
                                    <li>Diploma in Unani and Acupuncture</li>
                                    <li>
                                        Diploma in Unani for Chronic Ailments
                                    </li>
                                    <li>
                                        Diploma in Unani for Kidney Diseases
                                    </li>
                                    <li>Certificate of Basic Unani</li>
                                    <li>Certificate of Advance Unani</li>
                                    <li>
                                        Certificate of Unani Sexual Medicine
                                    </li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Masters Diploma in Unani</li>
                                    <li>Diploma in Unani Pharmacy</li>
                                    <li>Masters Diploma in Unani Medicine</li>
                                    <li>Diploma in Hijama Therapy</li>
                                    <li>
                                        Bachelor of Unani Medicine and Surgery
                                    </li>
                                    <li>
                                        Post Graduate Diploma in Unani Medicine
                                        and Surgery
                                    </li>
                                    <li>Master of Medicine in Unani</li>
                                    <li>Doctor of Medicine (Unani)</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Employment opportunities</h4>
                        <p className="para">
                            Career prospects in the field of BUMS have scope
                            abroad as well as in India. Multiple research
                            institutions and organizations are currently working
                            in the research and manufacturing field abroad as
                            this field demands the presence of professionals.
                        </p>
                        <p className="para">
                            A candidate possessing a degree in BUMS or Bachelors
                            in Unani Medicine is eligible to be addressed as a
                            Hakeem(i.e Doctor) and is also eligible to practice
                            privately. An Individual practicing Unani can look
                            at career prospects as a medical representative or
                            as a doctor in a private or government hospital.
                        </p>
                        <p className="para">
                            The professionals in the field of Unani can also
                            work for companies which deals with the preparations
                            in Unani. The candidate may get the job in an Unani
                            institution as a professor or a researcher.. Since a
                            lot of people aren’t satisfied with the allopathic
                            treatment, the importance is given to an alternative
                            treatment.
                        </p>
                    </div>
                </div>

                <div className="row description_card">
                    <div className="col">
                        <h4 className="header">Job types</h4>
                        <ul className="courses_list">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Hakeem</li>
                                    <li>Consultant</li>
                                    <li>Lecturer</li>
                                    <li>Scientist</li>
                                    <li>Therapist</li>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <li>Private practice</li>
                                    <li>Pharmacist</li>
                                    <li>Medical assistant</li>
                                    <li>Public health specialist</li>
                                    <li>Spa director</li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BUMSCourse;
